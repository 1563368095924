quosal.api.tab = {};

quosal.api.tab.setTaxability = function (idQuoteMain, idQuoteTabs, isTaxable) {
    return quosal.api.call('Tab.SetTaxability', {
        idQuoteMain: idQuoteMain,
        idQuoteTabs: idQuoteTabs,
        isTaxable: isTaxable
    });
};

quosal.api.tab.setIdRecurringRevenue = function (idQuoteMain, idQuoteTabs, recurringLabel) {
    return quosal.api.call('Tab.SetIdRecurringRevenue', {
        idQuoteMain: idQuoteMain,
        idQuoteTabs: idQuoteTabs,
        recurringLabel: recurringLabel
    });
};

quosal.api.tab.getTabTemplates = function (searchTerm) {
    return quosal.api.call('Tab.GetTabTemplates', {
        searchTerm: searchTerm
    });
};

quosal.api.tab.restoreTab = function (idQuoteMain, idQuoteTabs) {
    return quosal.api.call('Tab.RestoreTab', {
        idQuoteMain: idQuoteMain,
        idQuoteTabs: idQuoteTabs
    });
};

quosal.api.tab.deleteTab = function (idQuoteTabs) {
    return quosal.api.call('Tab.DeleteTab', {
        idQuoteTabs: idQuoteTabs
    });
};

quosal.api.tab.deleteTabs = function (idQuoteTabsArray, idQuoteMain) {
    return quosal.api.call('Tab.DeleteTabs', {
        idQuoteTabs: idQuoteTabsArray,
        idQuoteMain
    });
};



import { useState, useEffect, useCallback } from "react";

import useDarkMode from "../WidgetToolbar/widgetToolbarHelpers";

// eslint-disable-next-line import/prefer-default-export
export const useCPQEditorContainer = (contentGrid) => {

	const getStorageItem = (key, defaultValue) => {
		let item = sessionStorage.getItem(key);
		if (item === null || item === "" || isNaN(item)) {
			return defaultValue;
		}
		let number = Number(item);
		return parseFloat(number.toFixed(1));
	};

	const leftContentExpanded = !quosal.util.isNewPreviewContentVisible();
	const [rightContentExpanded, setRightContentExpanded] = useState(false);
	const [leftContentWidth, setLeftContentWidth] = useState(() => getStorageItem("leftContent", 48.5));
	const [rightContentWidth, setRightContentWidth] = useState(() => getStorageItem("rightContent", 48.5));
	const [prevLeftContentWidth, setPrevLeftContentWidth] = useState(leftContentWidth);
	const [prevRightContentWidth, setPrevRightContentWidth] = useState(rightContentWidth);
	const [newWidth, setNewWidth] = useState(leftContentWidth);
	const [isResizingAllowed, setIsResizingAllowed] = useState(true);
	const [panelClasses, setPanelClasses] = useState("");
	const LEFT_HEADER = "Prepare Content";
	const RIGHT_HEADER = "Preview Content";
	const quoteHeaderElem = document.querySelector("#quoteHeader");
	const CONTAINER_WIDTH = 97; // Initial left/right widths are 48.5 (50% of 97, and accounting for 3% padding)
	const DESKTOP_WIDTH_PX = 1000;

	const darkMode = useDarkMode();

	const expandRightContent = () => {
		setRightContentExpanded(!rightContentExpanded);
		
		quoteHeaderElem.style.display =
			leftContentExpanded || rightContentExpanded ? "block" : "none";
	};

	const debounce = useCallback((func, delay) => {
		let debounceTimer;
		return function () {
			const context = this;
			const args = arguments;
			clearTimeout(debounceTimer);
			debounceTimer = setTimeout(() => func.apply(context, args), delay);
		};
	}, []);

	const handleContentResize = useCallback((movementX, minWidth) => {
		const MIN_PANEL_WIDTH = minWidth || 25;
		const dampeningFactor = 0.1;

		let adjustedMovementX = movementX * dampeningFactor;
		let newWidthLeft = newWidth + adjustedMovementX;
		let newWidthRight = CONTAINER_WIDTH - newWidthLeft;

		if (newWidthLeft < MIN_PANEL_WIDTH) {
			newWidthLeft = MIN_PANEL_WIDTH;
			newWidthRight = CONTAINER_WIDTH - MIN_PANEL_WIDTH;
		} 
		else if (newWidthRight < MIN_PANEL_WIDTH) {
			newWidthRight = MIN_PANEL_WIDTH;
			newWidthLeft = CONTAINER_WIDTH - MIN_PANEL_WIDTH;
		}

		setNewWidth(newWidthLeft);

		sessionStorage.setItem("leftContent", newWidthLeft.toString());
		sessionStorage.setItem("rightContent", newWidthRight.toString());
	}, [ newWidth ]);


	const handleWindowResize = useCallback(debounce(() => {
		const currentWindowWidth = window.innerWidth;

		if (currentWindowWidth < DESKTOP_WIDTH_PX) {// how can i show more implicitly that these are pixels?
			if (leftContentWidth !== CONTAINER_WIDTH || rightContentWidth !== CONTAINER_WIDTH) {
				const tempLeftContentWidth = leftContentWidth;
				const tempRightContentWidth = rightContentWidth;
				setPrevLeftContentWidth(tempLeftContentWidth);
				setPrevRightContentWidth(tempRightContentWidth);
				setLeftContentWidth(CONTAINER_WIDTH);
				setRightContentWidth(CONTAINER_WIDTH);
			}
		} 
		else {
			if (rightContentWidth === CONTAINER_WIDTH && leftContentWidth === CONTAINER_WIDTH) {
				setLeftContentWidth(CONTAINER_WIDTH - prevRightContentWidth); // 3/12/24 WF. batch update causes erratic prevLeftContainerWidth value
				setRightContentWidth(prevRightContentWidth);
			}
		}
	}, 250), [ leftContentWidth, rightContentWidth, prevLeftContentWidth, prevRightContentWidth ]);

	const handleAbilityToResizeManually = () => {
		window.innerWidth >= DESKTOP_WIDTH_PX ? setIsResizingAllowed(true) : setIsResizingAllowed(false);
	};

	
	useEffect(() => {
		const newWidthRight = CONTAINER_WIDTH - newWidth;
		setLeftContentWidth(newWidth);
		setRightContentWidth(newWidthRight);
	}, [ newWidth ]);

	useEffect(() => {
		window.addEventListener('resize', handleWindowResize);
		handleWindowResize();
		handleAbilityToResizeManually();
		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, [handleWindowResize]);

	useEffect(() => {
		handleAbilityToResizeManually(), [handleWindowResize];
	});
	useEffect(() => {
		setPanelClasses(darkMode ? "panelContainer darkMode" : "panelContainer");
	}, [darkMode]);

	useEffect(() => {
		contentGrid.handleRightContentExpanded(rightContentExpanded);
	}, [contentGrid, rightContentExpanded]);

	useEffect(() => {
		contentGrid.handleLeftContentExpanded(leftContentExpanded);
	}, [contentGrid, leftContentExpanded]);

	useEffect(
		() => () => {
			quoteHeaderElem.style.display = "block";
		},
		[quoteHeaderElem.style]
	);

	return {
		leftContentExpanded,
		rightContentExpanded,
		leftContentWidth,
		rightContentWidth,
		panelClasses,
		LEFT_HEADER,
		RIGHT_HEADER,
		isResizingAllowed,
		expandRightContent,
		handleContentResize,
	};
};
import React, { useCallback, useEffect, useRef, useState } from "react";

import { Chip } from "@mui/material";
import { PickTabTemplate } from "js/jsx/src/classes/quote/pickTabTemplate";
import { useDrag, useDrop } from "react-dnd";
import { useAppGlobalContext } from "../context/globalContext";
import { getRows, removeZeroQuantityItems } from "../Helpers";
import { getCKEditorTabsIds } from "../helpers/getCKEditorTabsIds";
import useDarkMode from "../WidgetToolbar/widgetToolbarHelpers";
import useDraggableExpandableHelper from "./draggableExpandableHelper";

const useDraggableExpandableContainer = (
	id,
	tabItem,
	onMove,
	open,
	duplicated,
	updateOpenTab,
	setDuplicated,
	setChange,
	setData,
	setLoading
) => {
	const { contentGrid, ckeditor, selectedTabs, setSelectedTabs } = useAppGlobalContext();
	const [disabledAddNewTab, setDisabledAddNewTab] = useState(false);
	const [gridRows, setGridRows] = useState([]);
	const [ids, setIds] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const [sheetAnchorEl, setSheetAnchorEl] = useState(null);
	const [renameTab, setRenameTab] = useState(false);
	const [isSpreadsheetRefreshing, setIsSpreadsheetRefreshing] = useState(false);
	const [tabName, setTabName] = useState((tabItem && tabItem.IdQuoteTabs) ? tabItem.TabName || "" : "Add New Tab");

		const {
			getOption,
			getTabNumber,
			getTabName,
			saveTabName,
			getOptionGroupLabelColor,
			getTabGroupLabelColor,
			getOptionGroupLabelTitle,
			getTabGroupLabelTitle,
			handleDuplicateTab,
		} = useDraggableExpandableHelper(
			tabItem,
			setTabName,
			setChange,
			tabName,
			updateOpenTab,
			setDuplicated,
			setData,
			ckeditor
		);
		const selectedTabIds = selectedTabs.map((tab) => tab.tabId);
		const isTabSelected = selectedTabIds.includes(tabItem?.IdQuoteTabs);
		const [isOptional, setIsOptional] = useState(getOption("IsOptional"));
		const [isPrinted, setIsPrinted] = useState(getOption("IsPrinted"));
		const [isTotalsIncluded, setIsTotalsIncluded] = useState(getOption("IsTotalsIncluded"));
		const [orderPorterRequired, setOrderPorterRequired] = useState(
			getOption("OrderPorterRequired")
		);
		const [listClasses, setListClasses] = useState("");
		const textInput = useRef(null);

		const darkMode = useDarkMode();

		const dropType = "sortableItem";
		const isStandardUser =
			!app.currentUser.IsContentMaintainer &&
			!app.currentUser.IsAdministrator &&
			!app.currentUser.IsStandardPlus;
		const isStandardUserPlus =
			!app.currentUser.IsContentMaintainer &&
			!app.currentUser.IsAdministrator &&
			app.currentUser.IsStandardPlus;
		const showShortMenu = isStandardUser && tabItem?.IsProtectedTab;

		const [{ isOver, canDrop }, drop] = useDrop(
			() => ({
				accept: dropType,
				drop: (item, monitor) => {
					const didDrop = monitor.didDrop();
					if (didDrop || item.id === id) {
						return;
					}
					onMove && onMove(item.id, id);
				},
				collect: (monitor) => ({
					isOver: monitor.isOver(),
					canDrop: monitor.canDrop()
				})
			}),
			[onMove],
			id
		);

		const isActive = isOver && canDrop;

		const [{ isDragging }, drag] = useDrag(() => ({
			type: dropType,
			item: { id, type: dropType },
			collect: (monitor) => ({
				isDragging: monitor.isDragging()
			}),
			canDrag: id !== ""
		}));

		const handleClick = useCallback(() => {
			const openTabId = app.currentQuote.Tabs.find((item) => item.IdQuoteTabs === id)?.IdQuoteTabs;
			id = open === openTabId ? null : openTabId;
			updateOpenTab(id);
		}, [open]);

		const handleMenuClick = (event) => {
			setAnchorEl(event.currentTarget);
		};

		const handleMenuClose = () => {
			setAnchorEl(null);
		};

		const handleSheetMenuClick = (event) => {
			setSheetAnchorEl(event.currentTarget);
		};

		const handleSheetMenuClose = () => {
			setSheetAnchorEl(null);
		};

		const duplicateTab = useCallback(() => {
			const isRadioOption = false;
			handleDuplicateTab(isRadioOption);
			setAnchorEl(null);
			setSheetAnchorEl(null);
		}, [handleDuplicateTab]);

		const duplicateTabAsRadioOption = useCallback(() => {
			const isRadioOption = true;
			handleDuplicateTab(isRadioOption);
			setAnchorEl(null);
			setSheetAnchorEl(null);
		}, [handleDuplicateTab]);

		const optionGroupLabel =
			getOptionGroupLabelTitle() !== "" ? (
				<Chip
					label={getOptionGroupLabelTitle()}
					style={{ maxWidth: "150px", backgroundColor: getOptionGroupLabelColor() }}
				/>
			) : null;

		const tabGroupLabel =
			getTabGroupLabelTitle() !== "" ? (
				<Chip
					label={getTabGroupLabelTitle()}
					style={{ maxWidth: "150px", backgroundColor: getTabGroupLabelColor() }}
				/>
			) : null;

		const handleCheckBoxClick = useCallback(
			(optionName, event) => {
				let currTab = null;
				let newValue = null;
				app.currentQuote.Tabs.forEach((tab) => {
					if (tab.IdQuoteTabs === tabItem?.IdQuoteTabs) {
						currTab = tab;
					}
				});

				if (event) {
					if (optionName === "IsOptional") {
						setIsOptional(false);
					}
					if (optionName === "IsPrinted") {
						setIsPrinted(false);
					}
					if (optionName === "IsTotalsIncluded") {
						setIsTotalsIncluded(false);
					}
					if (optionName === "OrderPorterRequired") {
						setOrderPorterRequired(false);
					}
					newValue = false;
				} else {
					if (optionName === "IsOptional") {
						setIsOptional(true);
					}
					if (optionName === "IsPrinted") {
						setIsPrinted(true);
					}
					if (optionName === "IsTotalsIncluded") {
						setIsTotalsIncluded(true);
					}
					if (optionName === "OrderPorterRequired") {
						setOrderPorterRequired(true);
					}
					newValue = true;
				}

				const updateFields = {};
				updateFields[optionName] = newValue;

				const updateApi = quosal.api.data.update(
					{
						fields: updateFields,
						queries: [
							{
								table: "QuoteTabs",
								where: [
									{
										field: "IdQuoteTabs",
										operator: "Equals",
										value: tabItem?.IdQuoteTabs
									}
								]
							}
						]
					},
					currTab.IdQuoteMain
				);
				updateApi.finished = function (msg) {
					quosal.sell.quote.updateFromApiResponse(msg);
					setTimeout(() => {
						if (ids !== null && ids.includes(tabItem?.IdQuoteTabs)) {
							ckeditor?.execute && ckeditor.execute("updateProduct", tabItem?.IdQuoteTabs);
						}
					}, 50);
				};
				updateApi.call();
			},
			[ckeditor, ids, tabItem?.IdQuoteTabs]
		);

		const isTabDroppedIntoEditor = () => {
			const editorData = app.currentQuote.HTMLContentForAutoSaving;
			const droppedTabIds = getCKEditorTabsIds(editorData);

			return droppedTabIds.includes(tabItem?.IdQuoteTabs);
		};

		const handleSelectedTabIds = (tabIsDeselected) => {
			let copyOfTabs = [...selectedTabs];
			const tabNumber = getTabNumber(tabItem);
			if (tabIsDeselected) {
				const index = selectedTabs.findIndex((tab) => tab.tabId === tabItem?.IdQuoteTabs);
				if (index > -1) {
					copyOfTabs.splice(index, 1);
				}
			} else {
				copyOfTabs.push({ tabId: tabItem?.IdQuoteTabs, tabNumber: tabNumber });


			}
			setSelectedTabs(copyOfTabs.sort((a, b) => a.tabNumber - b.tabNumber));
		};

		const scrollToSelectableTab = (event, tabId) => {
			const isTabExistInEditor = isTabDroppedIntoEditor();
			if (event.detail === 2 && isTabExistInEditor) {
				ckeditor.editing.view.focus();
				ckeditor.model.change((writer) => {
					const range = ckeditor.model.createRangeIn(ckeditor.model.document.getRoot());
					const editorItems = range.getItems();
					let productToSelect = null;
					for (const item of editorItems) {
						if (item.name === "productPreview") {
							const existedTabId = item.getAttribute("id");
							if (existedTabId === tabId) {
								productToSelect = item;
								break;
							}
						}
					}
					writer.setSelection(productToSelect, "in");
				});

				ckeditor.editing.view.scrollToTheSelection();
			}
		};

		const handleRenameTab = useCallback(() => {
			setRenameTab(true);
			updateOpenTab(tabItem?.IdQuoteTabs);
			setAnchorEl(null);
			setSheetAnchorEl(null);

			setTimeout(() => {
				textInput.current?.focus();
			}, 100);
		}, [tabItem?.IdQuoteTabs, updateOpenTab]);

		const handleKeyUp = useCallback(
			(event) => {
				if (event.keyCode === 27) {
					app.currentQuote.Tabs.forEach((tab) => {
						if (tab.IdQuoteTabs === tabItem?.IdQuoteTabs) {
							setTabName(tab.TabName);
						}
					});

					setRenameTab(false);
				}
				if (event.keyCode === 13) {
					setRenameTab(false);
					if (tabItem?.TabName !== tabName) {
						saveTabName();
					}
					setDuplicated(false);
				}
			},
			[saveTabName, setDuplicated, tabItem?.IdQuoteTabs, tabItem?.TabName, tabName]
		);

		const handleOnBlur = useCallback(() => {
			setRenameTab(false);
			if (tabItem?.TabName !== tabName) {
				saveTabName();
			}
			setDuplicated(false);
		}, [saveTabName, setDuplicated, tabItem?.TabName, tabName]);

		const removeZeroQtyItems = (idTab = tabItem?.IdQuoteTabs) => {
			removeZeroQuantityItems(idTab, setChange, setLoading);
			setAnchorEl(null);
			setSheetAnchorEl(null);
		};

		const handleEditTab = () => {
			app.currentQuote.Tabs.forEach((tab) => {
				if (tab.IdQuoteTabs === tabItem?.IdQuoteTabs) {
					app.currentModule.loadSubModule("tab.edit", {
						container: "quoteModule",
						query: `idquotetabs=${tab.IdQuoteTabs}`
					});
				}
			});
			sessionStorage.setItem("cpq_open_tab", open);
		};

		const getTabColor = () => {
			let tabColor = null;
			app.currentQuote.Tabs.forEach((tab) => {
				if (tab.IdQuoteTabs === tabItem?.IdQuoteTabs) {
					tabColor = tab.TabColor;
				}
			});

			return tabColor === "gray" ? "black" : tabColor;
		};

		const updateSpreadsheet = useCallback(() => {
			let currTab = null;
			app.currentQuote.Tabs.forEach((tab) => {
				if (tab.IdQuoteTabs === tabItem?.IdQuoteTabs) {
					currTab = tab;
				}
			});

			setIsSpreadsheetRefreshing(true);
			const updateApi = quosal.api.quote.updateFromSpreadsheets(currTab.IdQuoteMain);

			updateApi.finished = function (msg) {
				quosal.sell.quote.update(msg.quote);
				setIsSpreadsheetRefreshing(false);
				setChange();
				handleSheetMenuClose();
			};
			updateApi.call();
		}, [setChange, tabItem?.IdQuoteTabs]);

		const handleUpdateAllCosts = (tabId) => {
			quosal.navigation.navigate(
				quosal.util.url("updatecosts.quosalweb", `idquotetabs=${tabId}`, "tabonly=true")
			);
		};

		const handleSelectNewTab = useCallback(() => {
			const goToTab = function (nameOfTab) {
				let quoteNavigationModuleIndex = -1;
				let selectedTabId = null;
				if (app && app.currentModule && app.currentModule.Type === "QuoteDashboard") {
					quoteNavigationModuleIndex = quosal.util.findWithAttr(
						app.currentModule.subModules,
						"Type",
						"QuoteNavigation"
					);
				}

				if (nameOfTab) {
					const index = quosal.util.lastIndexOf(app.currentQuote.Tabs, "TabName", nameOfTab);
					const tab = app.currentQuote.Tabs[index];
					selectedTabId = tab.IdQuoteTabs;
				} else if (quoteNavigationModuleIndex > -1) {
					selectedTabId = app.currentQuote.Tabs[app.currentQuote.Tabs.length - 1].IdQuoteTabs;
				}
				setData(app.currentQuote.Tabs);
				updateOpenTab(selectedTabId);

				Dialog.close();
			};

			Dialog.open({
				height: "82%",
				width: "92%",
				title: "Choose Tab Template",
				message: <PickTabTemplate goToTab={goToTab} />,
				closeRequiresButton: true,
				links: [
					{
						title: "Finished",
						callback: goToTab
					}
				]
			});
		}, [setData, updateOpenTab]);

		useEffect(() => {
			setListClasses(`prepareContentTab${darkMode ? " darkMode" : ""} BackgroundTabcolor ${getTabColor()}`);
		}, [darkMode]);

		useEffect(() => {
			if (id === open && duplicated) {
				setTimeout(() => {
					textInput.current?.focus();
				}, 100);
			}
		});

		useEffect(() => {
			app.currentQuote.Tabs.forEach((tab) => {
				if (tab.IdQuoteTabs === tabItem?.IdQuoteTabs) {
					setGridRows(getRows(tab.IdQuoteTabs));
				}
			});
		}, [app.currentQuote.Tabs]);

		useEffect(() => {
			if (id === "") setDisabledAddNewTab(true);
			else setDisabledAddNewTab(false);
		}, []);

		useEffect(() => {
			setIds(getCKEditorTabsIds(app.currentQuote.HTMLContentForAutoSaving));
		}, [app.currentQuote.HTMLContentForAutoSaving]);

		useEffect(() => {
			setTabName(getTabName(tabItem));
		}, [ getTabName, getTabNumber, renameTab ]);

	return {
		contentGrid,
		disabledAddNewTab,
		gridRows,
		anchorEl,
		sheetAnchorEl,
		renameTab,
		tabName,
		setTabName,
		getTabNumber,
		isSpreadsheetRefreshing,
		isOptional,
		isPrinted,
		isTotalsIncluded,
		isTabSelected,
		orderPorterRequired,
		isStandardUser,
		isStandardUserPlus,
		showShortMenu,
		listClasses,
		textInput,
		darkMode,
		drop,
		isActive,
		isDragging,
		drag,
		handleClick,
		handleSelectedTabIds,
		handleMenuClick,
		handleMenuClose,
		handleSheetMenuClick,
		handleSheetMenuClose,
		duplicateTab,
		duplicateTabAsRadioOption,
		optionGroupLabel,
		tabGroupLabel,
		handleCheckBoxClick,
		handleRenameTab,
		handleKeyUp,
		handleOnBlur,
		removeZeroQtyItems,
		handleEditTab,
		getTabColor,
		updateSpreadsheet,
		scrollToSelectableTab,
		handleUpdateAllCosts,
		handleSelectNewTab
	};
};

export default useDraggableExpandableContainer;

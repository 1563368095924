import React, { useState } from "react";
import { Link } from "@mui/material";
import TruncatedText from "../forms/truncatedText";

export function RestoreDeletedTabs({ data: initData }) {
    const [data, setData] = useState(initData);
    const restoreTab = (idQuoteTabs) => {
        const restoreApi = quosal.api.tab.restoreTab(app.currentQuote.IdQuoteMain, idQuoteTabs);

        restoreApi.finished = (msg) => {
            quosal.sell.quote.updateFromApiResponse(msg);
            setData(data.filter((item) => item.IdQuoteTabs !== idQuoteTabs));
            Dialog.setIsWorking(false);
        };

        restoreApi.call();
        Dialog.setIsWorking(true);
    }
    return (
        data.length > 0 ? <>
            <table cellPadding='5' className='datagrid'>
                <thead>
                    <tr>
                        <th width='20' className='title nosort'></th>
                        <th width='250' className='title nosort'>Tab Name</th>
                        <th width='100' className='title nosort'>Tab Total</th>
                        <th width='100' className='title nosort'>Tab Recurring Total</th>
                        <th width='85' className='title nosort'>Quote Version</th>
                        <th width='80' className='title nosort'>Delete Date</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => {
                        return (
                            <tr className='dataGridRow' key={index}>
                                <td className='content center' style={{ cursor: 'pointer', padding: '10px 5px' }}>
                                    <Link
                                        onClick={() => restoreTab(item.IdQuoteTabs)}
                                        href="#"
                                        underline="none"
                                    >
                                        Restore
                                    </Link>
                                </td>
                                <td className='content'>
                                   <TruncatedText maxWidth="250px" text={item.TabName} />
                                </td>
                                <td className='content'>
                                    <TruncatedText maxWidth="100px" text={app.currentQuote.formatCurrency(item.TabTotal)} />
                                </td>
                                <td className='content'>
                                    <TruncatedText maxWidth="100px" text={app.currentQuote.formatCurrency(item.RecurringTotal)} />
                                </td>
                                <td className='content'>
                                    <TruncatedText maxWidth="85px" text={item.DeleteQuoteVersion} />
                                </td>
                                <td className='content'>
                                    <TruncatedText maxWidth="80px" text={item.DeleteDate} />
                                </td>
                            </tr>
                        )
                    }
                    )}
                </tbody>
            </table>
        </> :
            <div>There are no tabs to restore</div>
    )
}



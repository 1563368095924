import * as React from "react";
import { PackageSelector } from "js/jsx/src/classes/product/packages";

export const getCurrentItems = (tabId) => {
	const currentItems = [];
	app.currentQuote.Items.forEach((item) => {
		if (item.IdQuoteTabs === tabId) {
			currentItems.push(item);
		}
	});

	return currentItems;
};

export const getSelectedGridRowsIndexes = (tabId, selectedRows) => {
	const currentItems = getCurrentItems(tabId);
	const selectedGridRowsIndexes = [];
	for (let k = 0; k < currentItems.length; k++) {
		for (let n = 0; n < selectedRows.length; n++) {
			if (currentItems[k].IdQuoteItems === selectedRows[n]) {
				selectedGridRowsIndexes.push(k);
			}
		}
	}

	return selectedGridRowsIndexes;
};

export const getPackageSelector = (contentGrid) => {
	const options = [];
	let initialValue = null;
	const productArray = app.currentQuote.Items;
	for (let i = 0; i < productArray.length; i++) {
		if (productArray[i].IsPackageHeader && !productArray[i].InvoiceGroupingRecid) {
			if (initialValue === null) {
				initialValue = productArray[i].IdQuoteItems;
			}
			options.push(
				<option key={i} value={productArray[i].IdQuoteItems}>
					{productArray[i].ManufacturerPartNumber}
				</option>
			);
		}
	}
	return (
		<PackageSelector
			options={options}
			initialValue={initialValue}
			valueConsumer={contentGrid}
		/>
	);
};

const getInvoiceGroupSelector = (contentGrid) => {
	let initialValue = null;
	const options = [];
	const allProducts = app.currentQuote.Items;
	for (let i = 0; i < allProducts.length; i++) {
		if (allProducts[i].IsPackageHeader && allProducts[i].InvoiceGroupingRecid) {
			if (initialValue === null) {
				initialValue = allProducts[i].IdQuoteItems;
			}
			options.push(
				<option key={i} value={allProducts[i].IdQuoteItems}>
					{allProducts[i].ManufacturerPartNumber}
				</option>
			);
		}
	}
	return (
		<PackageSelector
			options={options}
			initialValue={initialValue}
			valueConsumer={contentGrid}
			isInvoiceGroupSelector="true"
		/>
	);
};

export const unpackage = (
	contentGrid,
	tabId,
	selectedRows,
	setSelectedRows,
	isInvoiceGroup,
	ckeditor,
	ids,
	setChange
) => {
	isInvoiceGroup = isInvoiceGroup === true;
	if (selectedRows.length < 1) {
		Dialog.open({
			title: "No items selected",
			message: "You must select one or more items for this operation.",
			links: [Dialog.links.ok]
		});
		return;
	}

	const unbundleVerbUppercase = isInvoiceGroup ? "Ungroup" : "Unbundle";
	const bundleNounLowercase = isInvoiceGroup ? "invoice group" : "bundle";

	const headerIds = [];

	const currentItems = getCurrentItems(tabId);
	const selectedGridRowsIndexes = getSelectedGridRowsIndexes(tabId, selectedRows);

	for (let i = 0; i < selectedGridRowsIndexes.length; i++) {
		const loopIterationRow = currentItems[selectedGridRowsIndexes[i]];
		if (
			loopIterationRow.IdItemConfigurations == null ||
			loopIterationRow.IdItemConfigurations === ""
		) {
			Dialog.open({
				title: isInvoiceGroup ? "Invalid Invoice Group Header" : "Invalid Bundle Header",
				message: `${unbundleVerbUppercase} operation may only target one or more ${bundleNounLowercase} headers.`,
				links: [Dialog.links.ok]
			});
			return;
		}
		if (loopIterationRow.IsProtectedItem === true) {
			Dialog.open({
				title: `Cannot ${unbundleVerbUppercase}`,
				message: contentGrid.constructor.getProtectedBundleMessage(
					loopIterationRow.ManufacturerPartNumber,
					isInvoiceGroup
				),
				links: [Dialog.links.ok]
			});
			return;
		}
		headerIds.push(loopIterationRow.IdQuoteItems);
	}

	const unpackageApi = (
		isInvoiceGroup ? quosal.api.product.ungroupInvoiceGroup : quosal.api.product.unpackage
	)(app.currentQuote.IdQuoteMain, headerIds);

	unpackageApi.finished = function (msg) {
		if (msg.quote) {
			quosal.sell.quote.update(msg.quote);
			setChange();
			setSelectedRows([]);

			setTimeout(() => {
				if (ids !== null && ids.includes(tabId)) {
					ckeditor?.execute && ckeditor.execute("updateProduct", tabId);
				}
			}, 50);
		}
	};

	unpackageApi.call();
};

export const addToPackage = (
	contentGrid,
	tabId,
	selectedRows,
	setSelectedRows,
	isInvoiceGroup,
	ckeditor,
	ids
) => {
	isInvoiceGroup = isInvoiceGroup === true;
	if (selectedRows.length < 1) {
		Dialog.open({
			title: "No items selected",
			message: "You must select one or more items for this operation.",
			links: [Dialog.links.ok]
		});
		return;
	}

	const currentItems = getCurrentItems(tabId);
	const selectedGridRowsIndexes = getSelectedGridRowsIndexes(tabId, selectedRows);

	const detailIds = [];
	// Collect detail ids. Give a warning and return if any of them are package headers.
	for (let i = 0; i < selectedGridRowsIndexes.length; i++) {
		const row = currentItems[selectedGridRowsIndexes[i]];
		if (row.IsPackageHeader) {
			Dialog.open({
				title: isInvoiceGroup ? "Invalid Invoice Group Item" : "Invalid Bundle Item",
				message: isInvoiceGroup
					? "You can not add a bundle or Invoice Group header to another Invoice Group. You must first unselect any bundles and Invoice Groups before proceeding."
					: "You can not add a bundle header to another bundle. You must first unselect any bundles before proceeding.",
				links: [Dialog.links.ok]
			});
			return;
		}
		detailIds.push(row.IdQuoteItems);
	}

	const packageSelector = isInvoiceGroup
		? getInvoiceGroupSelector(contentGrid)
		: getPackageSelector(contentGrid);

	const confirmPackage = function () {
		const headerId = contentGrid.packageHeaderSelection;
		const headerItem = quosal.util.getItemById(app.currentQuote, headerId);
		if (headerItem.IsProtectedItem === true) {
			Dialog.open({
				title: "Cannot Add Item",
				message: contentGrid.getProtectedBundleMessage(
					headerItem.ManufacturerPartNumber,
					isInvoiceGroup
				),
				links: [
					{
						title: "Ok",
						callback() {
							Dialog.closeAll();
						}
					}
				]
			});
			return;
		}

		const addToPackageApi = (
			isInvoiceGroup ? quosal.api.product.addToInvoiceGroup : quosal.api.product.addToPackage
		)(app.currentQuote.IdQuoteMain, headerId, detailIds);

		addToPackageApi.finished = function (msg) {
			contentGrid.setState({ quote: quosal.sell.quote.update(msg.quote) });
			quosal.sell.quote.update(msg.quote);
			setSelectedRows([]);

			setTimeout(() => {
				if (ids !== null && ids.includes(tabId)) {
					ckeditor?.execute && ckeditor.execute("updateProduct", tabId);
				}
			}, 50);
		};
		Dialog.setIsWorking(true);
		addToPackageApi.call();

		contentGrid.packageHeaderSelection = null;

		Dialog.close();
	};

	contentGrid.packageHeaderSelection = null;
	Dialog.open({
		title: isInvoiceGroup ? "Select an Invoice Group" : "Select a Bundle",
		message: packageSelector,
		links: [{ title: "OK", callback: confirmPackage }, Dialog.links.cancel]
	});
};

export const removeFromPackage = (
	contentGrid,
	tabId,
	selectedRows,
	setSelectedRows,
	isInvoiceGroup,
	ckeditor,
	ids
) => {
	isInvoiceGroup = isInvoiceGroup === true;
	if (selectedRows.length < 1) {
		Dialog.open({
			title: "No items selected",
			message: "You must select one or more items for this operation.",
			links: [Dialog.links.ok]
		});
		return;
	}

	const currentItems = getCurrentItems(tabId);
	const selectedGridRowsIndexes = getSelectedGridRowsIndexes(tabId, selectedRows);

	const detailIds = [];

	for (let i = 0; i < selectedGridRowsIndexes.length; i++) {
		const headerId = currentItems[selectedGridRowsIndexes[i]].ParentQuoteItem;
		const headerItem = quosal.util.getItemById(app.currentQuote, headerId);
		if (headerId == null || headerId === "") {
			Dialog.open({
				title: isInvoiceGroup ? "Invalid Invoice Group Detail" : "Invalid Bundle Detail",
				message: isInvoiceGroup
					? "Remove from invoice group operation may only target one or more invoice group detail"
					: "Remove from bundle operation may only target one or more bundle detail.",
				links: [Dialog.links.ok]
			});
			return;
		}
		if (headerItem && headerItem.IsProtectedItem === true) {
			Dialog.open({
				title: "Cannot Remove Item",
				message: QuoteContentGrid.getProtectedBundleMessage(
					headerItem.ManufacturerPartNumber,
					isInvoiceGroup
				),
				links: [Dialog.links.ok]
			});
			return;
		}
		detailIds.push(currentItems[selectedGridRowsIndexes[i]].IdQuoteItems);
	}

	const removeFromPackageApi = (
		isInvoiceGroup
			? quosal.api.product.removeFromInvoiceGroup
			: quosal.api.product.removeFromPackage
	)(app.currentQuote.IdQuoteMain, detailIds);

	removeFromPackageApi.finished = function (msg) {
		Dialog.setIsWorking(false);
		contentGrid.setState({ quote: quosal.sell.quote.update(msg.quote) });
		setSelectedRows([]);

		setTimeout(() => {
			if (ids !== null && ids.includes(tabId)) {
				ckeditor?.execute && ckeditor.execute("updateProduct", tabId);
			}
		}, 50);
	};
	Dialog.setIsWorking(true);
	removeFromPackageApi.call();
};

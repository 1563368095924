import * as React from "react";
import { useState } from "react";

import { IconButton, Menu, MenuItem, Grid } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { removeZeroQuantityItems } from "../Helpers.jsx";

function BaseContextMenu({ actions, title, quosal, handleChangeAfterAction, isArchivedQuote }) {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const onSelect = (selectedItem) => {
		if (selectedItem.name === "Remove 0 Qty Items ") {
			removeZeroQuantityItems(null, handleChangeAfterAction);
			setAnchorEl(null);
			return;
		}

		if (selectedItem.callback) {
			if (selectedItem.name === "Import Dell Quote" || selectedItem.name === "Import ADI Quote") {
				setAnchorEl(null);				
			}
			selectedItem.callback(selectedItem);
		} else if (selectedItem.url) {
			const state = { rand: quosal.util.generateGuid() };
			if (selectedItem.target) {
				state.target = selectedItem.target;
			}
			if (selectedItem.bypassClientNav) {
				state.bypassClientNav = selectedItem.bypassClientNav;
			}
			quosal.navigation.navigate(selectedItem.url, state);
		}
	};

	const archivedQuoteActions = actions.filter((item) => item.name === "Preview PDF");

	return (
		<Grid item xs="auto">
			<IconButton
				size="small"
				edge="start"
				aria-controls={open ? "basic-menu" : undefined}
				aria-expanded={open ? "true" : undefined}
				onClick={handleClick}
				className="quoteToolbarButton"
				sx={{ color: "#333333" }}
				disableRipple
			>
				{title}
				<ExpandMore />
			</IconButton>
			<Menu
				disableEnforceFocus
				disableScrollLock
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				{actions && actions.length && !isArchivedQuote
					? actions.map((item) => (
							<MenuItem
								key={item.id}
								onClick={() => onSelect(item)}
								sx={{ fontSize: "14px" }}
							>
								{item.name}
							</MenuItem>
					  ))
					: archivedQuoteActions.map((item) => (
							<MenuItem
								key={item.id}
								onClick={() => onSelect(item)}
								sx={{ fontSize: "14px" }}
							>
								{item.name}
							</MenuItem>
					  ))}
			</Menu>
		</Grid>
	);
}

export default BaseContextMenu;

import { Stack, Typography, useTheme } from "@mui/material";
import { text_ellipsis } from "../../styles";

export const CustomerDetailItem = ({ title, value, testId }) => {
	const theme = useTheme();

	const styles = {
		fontWeight: 400,
		lineHeight: "18px",
		letterSpacing: "0.16px",
		textAlign: "left",
		height: "18px"
	};

	return (
		<>
			<Stack direction="column" sx={{ height: "38px", justifyContent: "space-between" }}>
				<Typography sx={{ ...styles, color: theme.palette.text.secondary }}  >
					{title}
				</Typography>
				<Typography data-testid = {testId} sx={{ ...styles, ...text_ellipsis, color: theme.palette.text.primary, width: "100%"}} title={value}>
					{value}
				</Typography>
			</Stack>
		</>
	);
};

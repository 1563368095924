export class PasteExcel extends React.Component {
    constructor(props) {
        super(props);

        this.phase = {
            PASTE: 'paste',
            MAPFIELDS: 'mapfields',
        }

        this.state = { 
            currentPhase: this.phase.PASTE,
            availableFields: {},
            standardFields: {},
            advancedFields: {},
            rawPaste: "",
            optpanel: {},
            mappingSet: {},
            lastselected: "Select a Mapping",
            currentMap: null,
            defaultMap: "",//dis
            TableData: "",
            TableColumnCount: 1,
            VendorSourceNames: null
        }; 
        this.nextAndParse = this.nextAndParse.bind(this);
        this.setupDragging = this.setupDragging.bind(this);
        this.validSettings = this.validSettings.bind(this);
        this.processQuoteText = this.processQuoteText.bind(this);
        this.SaveMapMessageBody = this.SaveMapMessageBody.bind(this);
        this.askForMappingName = this.askForMappingName.bind(this);
        this.saveMappings = this.saveMappings.bind(this);
        this.loadMappingsMain = this.loadMappingsMain.bind(this);
        this.goToPaste = this.goToPaste.bind(this);
        this.cleanupMapping = this.cleanupMapping.bind(this);
        this.reloadMappingSets = this.reloadMappingSets.bind(this);
        this.mappingDropDownChanged = this.mappingDropDownChanged.bind(this);
        this.saveMappingClicked = this.saveMappingClicked.bind(this);
        this.checkForSomeMappedFields = this.checkForSomeMappedFields.bind(this);
        this.editMappingClicked = this.editMappingClicked.bind(this);
        this.deleteMappingClicked = this.deleteMappingClicked.bind(this);
        this.resetMappingClicked = this.resetMappingClicked.bind(this);
        this.resetTheSettings = this.resetTheSettings.bind(this);
        this.changePackaging = this.changePackaging.bind(this);
        this.changeUpdateOnly = this.changeUpdateOnly.bind(this);
        this.changeCreateTabs = this.changeCreateTabs.bind(this);
        this.renderMapNavigation = this.renderMapNavigation.bind(this);
    }

    nextAndParse(){
        var pasted = $("#paste").val();
        if (pasted.length === 0) {
            Dialog.open({
                title: 'No Items Pasted',
                message: 'No item data was found. Please paste your item data into the textbox before continuing.',
                closeRequiresButton: true,
                linkalign: 'right',
                links: [{title: 'OK',callback: Dialog.close}]
            });
            return false;
            }
        var me = this;
        PasteExcelHelper.pastePreviewBlock(pasted, function(msg){
            me.setState({
                rawPaste: pasted,
                TableData: msg.tabledata,
                TableColumnCount: msg.columncount,
                VendorSourceNames: msg.vendorSourceNames
            });
        });

        var mapInitial = this.state.defaultMap; // using cookie
        var cookie = quosal.util.cookie('default_mapping');
        if (cookie && cookie.length > 0)
        {
            mapInitial = cookie;
        }

        var selMap = null;
        for (var i = 0; i < this.state.mappingSet.length; i++) {
            if (this.state.mappingSet[i].name == mapInitial) {
                selMap = this.state.mappingSet[i];
                break;
            }
        }
        selMap = this.cleanupMapping(selMap);
        this.setState({
            currentPhase: this.phase.MAPFIELDS,
            currentMap: selMap,
            lastselected: (selMap != null) ? mapInitial: 'Select a Mapping'},
            () => {
                if (selMap != null) {
                    setTimeout(function(){
                        $('#includeheaders').attr('checked', selMap.options.containsHeaders == 'True');
                        $('#lookupetilize').attr('checked', selMap.options.lookupEtilize == 'True');
                        $('#ignorezeroqty').attr('checked', selMap.options.ignoreZeroqty == 'True');
                        $('#spacepackage').attr('checked', selMap.options.spacePackage == 'True');
                        $('#includeheaders').change(); // at least get setting correct.
                        me.changeSkipHeaders(null);
                    }, 300);
                }
            } );
        
        return true;
    }

    // see: loadMappingsMain,   PasteExcelTable::render
    setupDragging(){
        var me = this;
        var draggableOps = {
            revert: 'invalid',
            helper: 'clone',
            containment: $(document.body),
            appendTo: $(document.body),
            scope: 'fields',
            zIndex: 11000,
            cursorAt: { left: 30, top: 20 },
        }

        $('.availablefield').draggable(draggableOps);
        
        $('#availablefields').droppable({
            scope: 'fields',
            accept: '.availablefield.pasted',
            tolerance: 'pointer',
            drop: function (e, ui) {
                var name = ui.draggable.attr('name');
                var display = ui.draggable.html();
                var iColumn = parseInt(ui.draggable.closest('#previewgrid th.title').find('input[type=hidden]').attr('name').substring(6));
                var map;
                if (me.state.currentMap != null) {
                    map = me.state.currentMap;
                }
                else {
                    map = {
                        name: "<<untitled>>",
                        columns: [],
                        isNew: true
                    };
                }
                map.columns[iColumn] = null;
                me.setState({
                    currentMap: map
                });
                ui.helper.remove();
            },
        });

        $('#previewgrid th.title').droppable({
            scope: 'fields',
            accept: '.availablefield',
            tolerance: 'intersect',
            
            drop: function (e, ui) {
                var header = $(this);
                if (header.find('input[type=hidden]').val() == '') {
                    var target = header.find('input[type=hidden]');
                    var iDest = parseInt(target.attr('name').substring(6));
                    var iOrig = -1;

                    var par = ui.draggable.parent();
                    if (par.attr('class').search('title') >= 0) {
                        var orig = par.find('input[type=hidden]');
                        iOrig = parseInt(orig.attr('name').substring(6));
                    }

                    var map;
                    if (me.state.currentMap != null) {
                        map = me.state.currentMap;
                    }
                    else {
                        map = {
                            name: "<<untitled>>",
                            columns: [],
                            isNew: true
                        };
                    }
                    map.columns[iDest] = {
                        name: ui.draggable.attr('name'),
                        displayName: ui.draggable.html()
                    }
                    if (iOrig >= 0) {
                        map.columns[iOrig] = null;
                    }
                    me.setState({
                        currentMap: map
                    });

                    ui.draggable.remove();
                    ui.helper.remove();
                }
            }
        });
    }

    validSettings() {
        var error = false;

        if ($('#createpackage').prop('checked')) {
            if ($('#PackagePartNumber').val() == '') {
                error = true;
                $.quosal.validation.validateField($('#PackagePartNumber'), 'error', 'Part Number is required');
            }
            else {
                $.quosal.validation.clearField($('#PackagePartNumber'));
            }
            if ($('#PackageDescription').val() == '') {
                error = true;
                $.quosal.validation.validateField($('#PackageDescription'), 'error', 'Description is required');
            }
            else {
                $.quosal.validation.clearField($('#PackageDescription'));
            }
            if (isNaN(parseFloat($('#PackageQuantity').val()))) {
                error = true;
                $.quosal.validation.validateField($('#PackageQuantity'), 'error', 'Quantity must be a valid number');
            }
            else {
                $.quosal.validation.clearField($('#PackageQuantity'));
            }
        }
        return !error;
    }

    processQuoteText(){
        if (!this.checkForSomeMappedFields()){
            return true;
        }
        
        if (this.validSettings()) {
            var me = this;
            Dialog.open({
                //icon: 'spinner',
                title: 'Pasting Quote',
                message: (<span><Spinner style={{marginTop: 5}}/>Pasting quote items.</span>),
                closeRequiresButton: true,
                links: [
                ]
            });
            var quoteinfo = {
                quoteid: this.props.currentQuote, 
                tabs: this.props.currentTab
            };

            if (quosal.util.isNewEditorEnabled()) {
                var quoteEditorCallbacks = {
                    handleChangeAfterAction: this.props.handleChangeAfterAction,
                    addProductInCKEditor: this.props.addProductInCKEditor
                };
            }

            var settings = {
                includeheaders: $('#includeheaders').prop('checked'),
                createtabs: $('#createtabs').prop('checked'),
                lookupetilize: $('#lookupetilize').prop('checked'),
                ignorezeroqty: $('#ignorezeroqty').prop('checked'),
                specialOrder: $('#specialOrder').prop('checked'),

                spacepackage: $('#spacepackage').prop('checked'),
                createpackage: $('#createpackage').prop('checked'),
                updateonly: $('#updateonly').prop('checked'),
                
                staticSource: (!$('#Source').length || $('#Source').attr('value') == 'EnterSource') ?
                              $('#SourceText').attr('value') : $('#Source').attr('value'),

                staticPriceMod: $('#PriceModifier').attr('value'),
                staticCostMod: $('#CostModifier').attr('value'),
                externalQuoteNumber: $('#externalQuoteNumber').attr('value'),
                externalQuoteExpiration: $('#externalQuoteExpiration').attr('value'),
                };
            var updatesettings = {
                updatecost: $('#updatecost').prop('checked'),
                updatesuggested: $('#updatesuggested').prop('checked'),
                updatequantity: $('#updatequantity').prop('checked'),
                createnew: $('#createnew').prop('checked')
                };
            var packagesettings = {
                PackagePartNumber: $('#PackagePartNumber').attr('value'),
                PackageDescription: $('#PackageDescription').attr('value'),
                PackageQuantity: parseFloat($('#PackageQuantity').val())
                };
            var aMappings = [];

            if (this.state.currentMap != null) {
                for (var index=0; index < this.state.currentMap.columns.length; index++){
                    if (this.state.currentMap.columns[index] != null) {
                        aMappings[index] = this.state.currentMap.columns[index].name;
                    }
                    else {
                        aMappings[index] = '';
                    }
                }
            }
            else {
                aMappings[0] = '';
            }

            /*$('#previewgrid th.title input[type=hidden]').each(function () {
                aMappings[index] = $(this).val();
                index++;
            });*/
            if (!this.isSpecial(this.state.lastselected)) {
                quosal.util.cookie('default_mapping', this.state.lastselected, {
                    expires: 365,
                    path: '/'
                });
            }

            PasteExcelHelper.applyPasteDataToQuote(this.state.rawPaste, quoteinfo, settings, updatesettings, packagesettings, aMappings, quoteEditorCallbacks,
                function (msg) {
                    quosal.sell.quote.update(msg.Quote);
                    if (msg.externalExpirationWarning) {
                        var url = quosal.util.url('quote.dashboard', 'submodules=quote.home', 'idquotemain=' + msg.Quote.IdQuoteMain);
                        me.showExternalExpirationWarning('External Quote Expiration Warning', 'The External Quote Expiration date is before the quote expiration.' +
                            'There is a risk your customer will approve after the external quote is no longer valid.', url);
                    }
                    else if (msg.error) {
                        me.showError('Error Pasting', 'Unable to Paste the Quote.  ' + msg.error);
                    }
                    else {
                        Dialog.closeAll();
                    }
                }); 
        }
    }

    SaveMapMessageBody(lastname, wasdefault){
        return (<div id="savemappingfields" className="formcolumn" style={{ marginTop: '10px'}}>
            <div className="newformlabelwrapper"><span htmlFor="formfieldselect" className="newformlabel">Field Mapping Name</span></div>
            <div className="formfield"><input title="Mapping Name" maxLength={255} defaultValue={lastname} type="text" name="mappingname" autoFocus /></div>
            <div className="newformlabelwrapper" style={{marginTop: '18px'}}><span htmlFor="formfieldselect" className="newformlabel">Description (Optional)</span></div>
            <div className="formfield"><textarea name="mappingdesc" title="Mapping Description" maxLength={255} defaultValue={""} /></div>
            <div className="formfield" style={{ marginTop: '16px', display: 'none'}}>
                <input type="checkbox" name="makedefault" id="makedefault" defaultChecked={wasdefault} style={{verticalAlign:"middle", fontSize: 'large'}} />
                <label style={{"verticalAlign":"middle","paddingLeft":"4px"}} htmlFor="makedefault" className="newformlabel" title="Use as default mapping">Use as default mapping</label>
            </div>
        </div>);
    }

    askForMappingName(lastname, queryCallBack){
        var me = this;
        var wasDefault = (lastname == this.state.defaultMap);
        var title = 'Save Mapping';
        if (lastname.length > 0)
            title = 'Edit Mapping';
        Dialog.open({
            title: title,
            message: me.SaveMapMessageBody(lastname, wasDefault),
            closeRequiresButton: true,
            linkalign: 'right',
            links: [{
                title: 'SAVE',
                callback: function() {
                    var infoPanel = $('#savemappingfields');
                    var mappingName = infoPanel.find('input[type=text]').val();
                    var description = infoPanel.find('textarea').val();
                    var isDefault = false;
                    //if (infoPanel.find('#makedefault').val() == 'on') // global.default = disabled
                    //   isDefault = true; 
                    if (mappingName == '') {
                        $.quosal.validation.validateField(infoPanel.find('input[type=text]'), 'error', 'The Mapping Name is required.');
                        return true;
                    }
                    else if (me.isSpecial(mappingName)) {
                        $.quosal.validation.validateField(infoPanel.find('input[type=text]'), 'error', 'The Mapping Name is invalid.');
                        return true;
                    }
                    else {
                        Dialog.close({skipAnimation: true});
                        var whenDone = function() {
                            me.setState({lastselected: mappingName});
                            if (isDefault)
                                me.setState({defaultMap: mappingName});
                            if (queryCallBack != null) {
                                queryCallBack(mappingName);
                            }
                        }
                        me.saveMappings(mappingName, description, isDefault, lastname, false, whenDone);
                    }
                 }
            }, 
            {
                title: 'CANCEL',
                callback: function() { 
                    Dialog.close();
                    if (queryCallBack != null) {
                        queryCallBack("");
                    }
                }
            }]
        });
    }

    saveMappings(mappingName, description, isDefault, oldname, forceit, completionFn) {
        var me = this;

        var map = {
            mappingname: mappingName,
            description: description,
            overwrite: forceit,

            includeheaders: $('#includeheaders').prop('checked'),
            lookupetilize: $('#lookupetilize').prop('checked'),
            ignorezeroqty: $('#ignorezeroqty').prop('checked'),
            spacepackage: $('#spacepackage').prop('checked'),

            mappedcolumns: []
        };

        if (this.state.currentMap != null) {
            for (var index=0; index < this.state.currentMap.columns.length; index++){
                if (this.state.currentMap.columns[index] != null) {
                    map.mappedcolumns[index] = this.state.currentMap.columns[index].name;
                }
                else {
                    map.mappedcolumns[index] = '';
                }
            }
        }
        else {
            map.mappedcolumns[0] = '';
        }
        PasteExcelHelper.saveFieldMappings(mappingName, map, isDefault, oldname, function(isOver, errorinfo) {
            if (errorinfo){
                me.showError('Error Saving Mapping', 'Unable to save the mapping.  ' + errorinfo);
                return;
            }
            if (isOver) {
                var overwriteConfirmMsg = (<span>A field mapping with this name already exists, do you want to overwrite it?</span>);
                Dialog.open({
                    title: 'Confirm Overwrite',
                    message: overwriteConfirmMsg,
                    closeRequiresButton: true,
                    linkalign: 'right',
                    links: [
                        {title: 'OVERWRITE', callback: function () {
                                Dialog.close();
                                me.saveMappings(mappingName, description, isDefault, oldname, true, completionFn);
                            }
                        }, 
                        {title: 'CANCEL', callback: function(){ Dialog.close(); } }
                    ]
                });
            }
            else {// we're good
            }
            if (completionFn) {
                completionFn();
            }
        }); 
    }

    showExternalExpirationWarning(title, message, url) {
        Dialog.open({
            title: title,
            message: message,
            closeRequiresButton: true,
            links: [{
                title: 'Change Quote Expiration', url                  
            },
            {
                title: 'Continue Without Changing', callback: Dialog.closeAll
            }]
        });
    }

    showError(title, message) {
        Dialog.open({
            title: title,
            message: message,
            closeRequiresButton: true,
            linkalign: 'right',
            links: [{title: 'OK', callback: Dialog.close }]
        });
    }

    queryDeleteMapping(mapName, completion) {
        var confirmMsg = function(mapName) { return (<span>Are you sure you want to delete the <b>{mapName}</b> mapping?</span>);}
        Dialog.open({
                title: 'Confirm Delete',
                message: confirmMsg(mapName),
                closeRequiresButton: true,
                linkalign: 'right',
                links: [
                    {title: 'DELETE', callback: function () {
                            PasteExcelHelper.deleteFieldMappings(mapName, function(errorinfo){
                                if (errorinfo) {
                                    Dialog.close({skipAnimation: true});
                                    me.showError('Error Deleting', 'Unable to delete the mapping.   ' + errorinfo);
                                    if (completion != null) completion(false);
                                }
                                else {
                                    Dialog.close({skipAnimation: true}); 
                                    if (completion != null) completion(true);
                                }
                            } );
                        }
                    }, 
                    {
                        title: 'CANCEL', callback: function () { 
                        Dialog.close();
                        if (completion != null) completion(false);
                     }
                    }
                ]
            });
    }
    
    loadMappingsMain(selected) {
        selected = this.cleanupMapping(selected);
        var me = this;

        this.setState({currentMap: selected},
            ()=> {
                $('#includeheaders').attr('checked', selected.options.containsHeaders == 'True');
                $('#lookupetilize').attr('checked', selected.options.lookupEtilize == 'True');
                $('#ignorezeroqty').attr('checked', selected.options.ignoreZeroqty == 'True');
                $('#spacepackage').attr('checked', selected.options.spacePackage == 'True');
                $('#includeheaders').change();
                me.changeSkipHeaders(null);
            });
    }

    goToPaste(){
        this.setState({
            currentPhase: this.phase.PASTE,
            TableData: "",
            TableColumnCount: 1
        });
    }

    componentDidMount() {
        var me = this;
        PasteExcelHelper.availableFields(function(fields, errorinfo){
            if (errorinfo){
                me.showError('Error Loading fields', 'Unable to load the available fields.  ' + errorinfo);
                return;
            }
            var standard = fields.filter(function(field){
                return !field.IsAdvanced;
              });
            var advanced = fields.filter(function(field){
                return field.IsAdvanced;
              });

            standard.sort(PasteExcelHelper.compareAvailable);
            advanced.sort(PasteExcelHelper.compareAvailable);

            me.setState({availableFields: fields,
                    standardFields: standard,
                    advancedFields: advanced});
        });
        
        this.setupDragging();
        this.reloadMappingSets(null);
    }

    cleanupMapping(aMap) {
        if (aMap == null) return aMap;
        if (aMap.columns == null) return aMap;

        aMap.columns.forEach(function(c) {
            if (c != null && c.name == "PriceModifier") {
                c.name = "OverridePriceModifier";
                c.displayName = "Override Price Modifier";
            }
            if (c != null && c.name == "OverridePriceModifier" && c.displayName == "Price Modifier") {
                c.displayName = "Override Price Modifier";
            }
        });
        return aMap;
    }
    
    reloadMappingSets(completion) {
        var me = this;
        PasteExcelHelper.loadFieldMappings(function(maps, defmap, errorinfo){
            if (maps == null) {
                maps = {};
            }
            if (defmap == null) {
                defmap = "";
            }
            me.setState({
                mappingSet: maps, 
                defaultMap: defmap});
            if (completion != null) {
                completion(maps, defmap);
                }
            }
        );
    }

    componentDidUpdate() {
        this.setupDragging();
    }

    isSpecial(name) {
        name = name.toLowerCase();
        if (name == null || ((name == 'select a mapping' || name == 'save mapping...' || name == 'delete mapping...')))
            return true;
        else
            return false;
    }

    mappingDropDownChanged(evtInfo) {
        var sel = this.refs.mappingdrop.value;
        var me = this;
        if (sel == 'Select a Mapping') {
            this.resetMappingClicked();
        }
        else {
            var selMap = null;
            for (var i = 0; i < this.state.mappingSet.length; i++) {
                if (this.state.mappingSet[i].name == sel) {
                    selMap = this.state.mappingSet[i];
                    break;
                }
            }
            if (selMap != null) {
                this.loadMappingsMain(selMap);
                this.setState({lastselected: selMap.name});
            }
        } 
    }

    saveMappingClicked() {
        if (!this.checkForSomeMappedFields()){
            return true;
        }
        var me = this;
        if (!this.isSpecial(this.state.lastselected)) {
            var isDefault = (this.state.defaultMap == this.state.currentMap.name) ? true: false;
            isDefault = false;// disable global
            var whenDone = function() {
                me.reloadMappingSets(function(maps, defaultMap) {
                    // better as a "notification panel."
                    Dialog.open({
                        title: 'Mapping Saved',
                        message: 'The current mapping has been saved.',
                        closeRequiresButton: false,
                        linkalign: 'right',
                        links: [{title: 'OK', callback: Dialog.close }]
                    });
                });
            }
            me.saveMappings(this.state.lastselected, this.state.currentMap.description, isDefault, this.state.currentMap.name, true, whenDone);
        }
        else {
            this.askForMappingName('', function(name) {
                if (name.length > 0) {
                    me.reloadMappingSets(function(maps, defaultMap) {
                        me.refs.mappingdrop.value = name;
                        
                    });
                }
            });
        }
    }

    checkForSomeMappedFields() {
        var count = 0;
        if (this.state.currentMap != null && this.state.currentMap.columns != null) {
            this.state.currentMap.columns.forEach(function(c) {
                if (c != null && c.name.length > 0) {
                    count++;
                }
            });
        }
        if (count < 1) {
            var err = (<div><strong>No available fields have been mapped.</strong><br />
                            Select a Mapping or drag and drop available fields<br />
                            onto preview area to create a new mapping.</div>);
            this.showError('No Mappings', err);
            return false;
        }
        else {
            return true;
        }
    }

    editMappingClicked() {
        if (!this.checkForSomeMappedFields()){
            return true;
        }
        var me = this;
        if (!this.isSpecial(this.state.lastselected)) {
            this.askForMappingName(this.state.lastselected, function(name) {
                if (name.length > 0) {
                    me.reloadMappingSets(function(maps, defaultMap) {
                        me.refs.mappingdrop.value = name;
                    });
                }
            });
        }
    }
    
    deleteMappingClicked() {
        var me = this;
        if (this.isSpecial(this.state.lastselected))
            return;
        this.queryDeleteMapping(this.state.lastselected,function(complete) {
            if (complete) {
                me.reloadMappingSets(function() {
                    me.resetMappingClicked();
                });
            }
            else {
                me.refs.mappingdrop.value = me.state.lastselected;
            }
        });
    }

    resetMappingClicked() {
        this.refs.mappingdrop.value = "Select a Mapping";
        this.setState({
            lastselected: "Select a Mapping",
            currentMap: null
        });

        this.resetTheSettings();
        return true;
    }

    resetTheSettings() {
        $('#includeheaders').attr('checked', false);
        $('#createtabs').attr('checked', false);
        $('#lookupetilize').attr('checked', false);
        $('#ignorezeroqty').attr('checked', false);
        $('#specialOrder').attr('checked', false);

        $('#externalQuoteNumber').attr('value', '');
        $('#externalQuoteExpiration').attr('value', '');

        $('#Source').attr('value', '');
        $('#SourceText').attr('value', '');
        $('#PriceModifier').attr('value', '');
        $('#CostModifier').attr('value', '');

        $('#spacepackage').attr('checked', false);
        $('#createpackage').attr('checked', false);
        $('#updateonly').attr('checked', false);
        $('#createnew').attr('checked', false);

        this.changePackaging({});
        this.changeUpdateOnly({});
        this.changeCreateTabs({});
        this.changeSkipHeaders({});
    }

    changePackaging(evt){
        var setOn = $('#createpackage').prop('checked') ? true: false;

        var setDisabled = function(setDisabled) {
            return this.each(function() {
                if (typeof this.disabled != "undefined") this.disabled = setDisabled;
            });
        }

        $('#packagedetails').find('label').each(function () {
            $(this).toggleClass('disabled', !setOn);
        });

        $('#PackagePartNumber').attr('disabled', !setOn);
        $('#PackageDescription').attr('disabled', !setOn);
        $('#PackageQuantity').attr('disabled', !setOn);
     }

    changeUpdateOnly(evt){
        var setOn = $('#updateonly').prop('checked') ? true: false;
        
        var setDisabled = function(setDisabled) {
            return this.each(function() {
                if (typeof this.disabled != "undefined") this.disabled = setDisabled;
            });
        }

        $('#updatedetails').find('label').each(function () {
            $(this).toggleClass('disabled', !setOn);
        });

        $('#updatecost').attr('disabled', !setOn);
        $('#updatesuggested').attr('disabled', !setOn);
        $('#updatequantity').attr('disabled', !setOn);
        $('#createnew').attr('disabled', !setOn);
    }

    changeCreateTabs(evt) {
        var createTabs = $('#createtabs').prop('checked');
        
        $('#previewgrid tr').each(function () {
            var contentCount = 0;
            $(this).find('td').each(function () {
                if ($(this).text() != '')
                    contentCount++;
            });

            if (contentCount == 1 && createTabs)
                $(this).hide({duration: 200});
            else if (contentCount == 1 && !createTabs)
                $(this).show({duration: 200});
        });
    }

    changeSkipHeaders(evt){
        if ($('#includeheaders').prop('checked')) {
            $('#previewgrid tbody').find('tr').first().hide();
        } else {
            $('#previewgrid tbody').find('tr').first().show();
        }
    }

    renderMapNavigation(){
        if (this.state.currentPhase === this.phase.PASTE){ 
           return (<div id="wizard_nav1" className="wizard_nav" style={{textAlign:"right", position: 'absolute', bottom: '-31px', right: '-18px' }}>
            <div id="cancelbutton1" className="cwbtn right" onClick={Dialog.close.bind(null, null)}>Cancel</div>
            <div id="nextbutton1" className="cwbtn right submit"  onClick={this.nextAndParse}>Next</div>
            <div id="backbutton1" className="cwbtn right submit disabled" onClick={this.goToPaste}>Back</div>
        </div>);
        }
        else {
            return (<div id="wizard_nav2" className="wizard_nav" style={{textAlign:"right", position: 'absolute', bottom: '16px', right: '7px' }}>
            <div id="cancelbutton2" className="cwbtn right" onClick={Dialog.close.bind(null, null)}>Cancel</div>
            <div id="nextbutton2" className="cwbtn right submit"  onClick={this.processQuoteText}>Paste</div>
            <div id="backbutton2" className="cwbtn right submit" onClick={this.goToPaste}>Back</div>
        </div>);
        }
     }
    
    render() {
        if (this.state.currentPhase === this.phase.PASTE){
            var moduleStyle = {overflow: "auto", flex: 1, display: 'flex', flexGrow: '1', flexDirection: 'column'}
            moduleStyle.border = "1px solid #cccccc";

            var pasteStyle = {border: "1px solid orange", flex: 1, position: 'relative', display: 'flex'};

            var textstyle = {resize: "none", flex: 1, border: 0};
            // style={{display: flex, flex:1}}
            return (
                <div className="nodrop" style={{ display: "flex", position: 'relative', flex: 1 }}>
                    {/*<div className='subheader'>Provide the delimited text that will be interpreted and pasted into the active quote.</div>*/}

                    <div style={moduleStyle}>
                        <input type="hidden" id="helpRoot" defaultValue="quote-home/prepare-content/paste-quote" />
                    
                        <div id="holderdiv1" style={pasteStyle}>
                            <textarea id="paste" name="paste" defaultValue={this.state.rawPaste} style={textstyle}></textarea>
                        </div>
                    </div>
                    {this.renderMapNavigation()}
                </div>);
        }
        else if (this.state.currentPhase === this.phase.MAPFIELDS){
            var toSelect = this.state.lastselected;
            var isNew = (toSelect === 'Select a Mapping') ? true: false;
            var availableMaps = null;
            if (this.state.mappingSet != null && this.state.mappingSet.length > 0) {
                availableMaps = this.state.mappingSet.map(function(map, index) {
                    return <option name={map.name} key={map.name}>{map.name}</option>;
                });
            }
            var deleteIfAdmin = null;
            if (app.currentUser.IsAdministrator) {
                deleteIfAdmin = <CwWidgets.CwButton ref="deletetempl" toolTip="Delete selected mapping" onClick={this.deleteMappingClicked} icon="img/svgs/v1.0/Action_Delete_New.svg" disabled={isNew} style={{marginTop:'-4px'}} />
            }
            var parts = (<div id="loadmappingfields" className="formcolumnX" style={{ marginRight: '10px', marginBottom: '6px'}}>
            
                            <div className="formselectfieldwrapperX" style={{ marginBottom: '6px'}}>
                            <span style={{marginTop: '-10px', marginBottom: '12px'}}>
                                <CwWidgets.CwButton ref="savetempl" toolTip="Save the current mapping settings" onClick={this.saveMappingClicked} icon="img/svgs/v1.0/Action_Save.svg" style={{marginTop:'-4px'}} />
                                <CwWidgets.CwButton ref="edittempl" toolTip="Edit selected mapping" onClick={this.editMappingClicked} icon="img/svgs/v1.0/Action_Edit.svg" disabled={isNew} style={{marginTop:'-4px'}} />
                                {deleteIfAdmin}
                                <CwWidgets.CwButton ref="reset" toolTip="Reset all mapping settings" onClick={this.resetMappingClicked} icon="img/svgs/v1.0/Action_Restart.svg" style={{marginTop:'-4px'}}  />
                            </span>

                            <span id="label" className="title">Mapping: </span>
                                <select ref="mappingdrop" className="formselectfieldX" name="formfieldselect2" title="Select a Mapping" onChange={this.mappingDropDownChanged} value={toSelect}>
                                    <option name="clearmap" key="clearmap">Select a Mapping</option>
                                    <option disabled className="separator">————————————————————</option>
                                    {availableMaps}
                                </select>
                            </div>
                    </div>);
            

            return (
                <div id="master_div2" className="master_div" style={{overflow: 'auto'}}>
                    <input type="hidden" id="helpRoot" defaultValue="quote-home/prepare-content/paste-quote" />
                    
                    <div id="body_div2" className="body_div">
                        <div id="topfluid" className="fluidpanels">
                            <div className="fluid">

                                <PasteExcelFields ref="fieldList" 
                                        fieldsStandard={this.state.standardFields} 
                                        fieldsAdvanced={this.state.advancedFields}
                                        currentMapped={this.state.currentMap} />

                                <div id="options_and_preview" className="grid col4x3">
                                    
                                    <div className="panel" data-help="paste-quote-preview">
                                        <div className="title"><span style={{"float":"left"}}>Preview and Mapping</span>
                                            <div style={{"float":"right", marginBottom: '6px', marginTop: '-4px'}}>
                                                {parts} 
                                            </div>
                                        </div>
                                        <div className='subheader'>Select a Mapping or drag and drop available fields onto preview area to create a new mapping.</div>
                                        <PasteExcelTable numColumns={this.state.TableColumnCount} 
                                                        tabledata={this.state.TableData} 
                                                        themap={this.state.currentMap}/>

                                    <div style={{height: '16px', width:'100%'}} id='spacer002' />
                                    <div className="panelx" data-help="paste-quote-options">
                                        <div className="title" style={{border: 'none'}}><span style={{"float":"left"}}>Options</span>
                                        </div>
                                            <PasteExcelOptions packCallback={this.changePackaging}
                                                updateCallback={this.changeUpdateOnly}
                                                tabsCallback={this.changeCreateTabs}
                                                headersCallback={this.changeSkipHeaders}
                                                vendorSources={this.state.VendorSourceNames}
                                            />
                                    </div>

                                    </div>
                                    

                                </div>
                            </div>
                        </div>
                    </div>

                    {this.renderMapNavigation()}
                    
                </div>);
        }
    }
}

class PasteExcelTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {                       
        }; 
    }
    /* props we'd like
        numColumns: 0,
        tabledata: "",
        themap: null
    */
    render() {
        function dangerousMarkup(str) {
            return {__html: str};
        }
        
        var hWidth = Math.round(940 / (this.props.numColumns));
        var headertxt = "";
        for (var i = 0; i < this.props.numColumns; i++)
        {
            if (this.props.themap != null && this.props.themap.columns.length > i && this.props.themap.columns[i] != null) {
                var col = this.props.themap.columns[i];
                headertxt += "<th class='title' width='" + hWidth 
                + "' style='height:30px;'><input type='hidden' name='column" + i 
                    + "' value='" + col.name + "' />" 
                    + "<div class='availablefield pasted ui-draggable ui-draggable-handle' name='" 
                        + col.name + "' >" + col.displayName +"</div>"
                    + "</th>";
            }
            else {
                headertxt += "<th class='title' width='" + hWidth + "' style='height:30px;'><input type='hidden' name='column" + i + "' /></th>";                
            }
        }

        return (
            <div id="paste_table" className="content" >
                <table id='previewgrid' cellPadding='0' cellSpacing='0' className='datagrid nosort' width='100%'>
                    <thead dangerouslySetInnerHTML={dangerousMarkup(headertxt)} />
                    <tbody dangerouslySetInnerHTML={dangerousMarkup(this.props.tabledata)} />
                </table>
            </div>);
    }
}


// ####################################################################################################

// ####################################################################################################
class PasteExcelHelper {
    static availableFields(callback){
        var makecall = quosal.api.quote.availableFields();
        makecall.finished = function(msg) {
            PasteExcelHelper.handleAvailableFields(msg, callback);
        };
        makecall.call();
    }

    static handleAvailableFields(msg, callback){
        callback(msg.fieldData, msg.error);
    }

    static compareAvailable(a,b) {
        if((a.DisplayName+'').toLowerCase() > (b.DisplayName+'').toLowerCase()) return 1;
        if((a.DisplayName+'').toLowerCase() < (b.DisplayName+'').toLowerCase()) return -1;
        return 0;
    }

    static pastePreviewBlock(pasted, callback){
        var makecall = quosal.api.quote.pastePreviewBlock(pasted);
        makecall.finished = function(msg) {
            PasteExcelHelper.handlePastePreviewBlock(msg, callback);
        };
        makecall.call();
    }
    static handlePastePreviewBlock(msg, callback){
        callback(msg);
    }

    static loadFieldMappings(callback){
        var makecall = quosal.api.quote.pasteLoadFieldMappings();
        makecall.finished = function(msg) {
            PasteExcelHelper.handleLoadFieldMappings(msg, callback);
        };
        makecall.call();
    }
    static handleLoadFieldMappings(msg, callback){
        callback(msg.mappings, msg.defaultmapname, msg.error);
    }
    
    static saveFieldMappings(name, mapping, isDefault, oldname, callback){
        var makecall = quosal.api.quote.pasteSaveFieldMappings(name, mapping, isDefault, oldname);
        makecall.finished = function(msg) {
            PasteExcelHelper.handleSaveFieldMappings(msg, callback);
        };
        makecall.call();
    }
    static handleSaveFieldMappings(msg, callback){
        callback(msg.isOverwrite, msg.error);
    }

    static applyPasteDataToQuote(rawPaste, quoteinfo, settings, updatesettings, packagesettings, mappings, quoteEditorCallbacks, callback){
        var makecall = quosal.api.quote.pasteSaveQuoteBlock(rawPaste, quoteinfo, settings, updatesettings, packagesettings, mappings);
        makecall.finished = function(msg) {
            PasteExcelHelper.handleapplyPasteDataToQuote(msg, callback);
            if (quosal.util.isNewEditorEnabled()) {
                quoteEditorCallbacks.handleChangeAfterAction();
                quoteEditorCallbacks.addProductInCKEditor();
            }
        };
        makecall.call();
    }
    static handleapplyPasteDataToQuote(msg, callback){
        callback(msg);
    }

    static deleteFieldMappings(name, callback){
        var makecall = quosal.api.quote.pasteDeleteFieldMappings(name);
        makecall.finished = function(msg) {
            PasteExcelHelper.handleDeleteFieldMappings(msg, callback);
        };
        makecall.call();
    }
    static handleDeleteFieldMappings(msg, callback){
        callback(msg.error);
    }
    
}

// ####################################################################################################

// ####################################################################################################
class PasteExcelFields extends React.Component {
    constructor(props) {
        super(props);
        this.state = {                       
        }; 
        this.filterFields = this.filterFields.bind(this); 
    }
    
    filterFields(e) {
        $('#availablefields .availablefield').each(function () {
            if ($(this).text().toLowerCase().indexOf($('#fieldfilter').val().toLowerCase()) >= 0) {
                $(this).show();
            } else {
                $(this).hide();
            }
        });
    }

    render() {
        var mapped = this.props.currentMapped;
        var isInMap = function(map, name) {
            var res='no';
            if (map == null) {
                return res;
            }
            map.columns.forEach(function(c) {
                if (c != null && c.name == name) {
                    res = 'yes';
                }
            });

            return res;
        };
        var std = this.props.fieldsStandard.map(function(f) {
            var res = isInMap(mapped, f.FieldName);
            if (res == 'yes') {
                return null;
            }
            else {
                return <div className="availablefield" key={f.FieldName} name={f.FieldName}>{f.DisplayName}</div>;
            }
         });
         var adv = this.props.fieldsAdvanced.map(function(f, index) {
            var res = isInMap(mapped, f.FieldName);
            if (res == 'yes') {
                return null;
            }
            else {
                return <div className="availablefield" key={f.FieldName} name={f.FieldName}>{f.DisplayName}</div>;
            }
        });

        return (
            <div className="grid col4x1">
                <div className="panel" data-help="paste-quote-available-fields">
                    <div className="title">Available Fields</div>
                    <div className="content">
                        <div>
                            <label htmlFor="fieldfilter" style={{margin: '5px 8px 3px 0', display: 'none'}}>Find</label>
                            <input id="fieldfilter" style={{width: 145}} type="text" onChange={this.filterFields} placeholder="Search"/>
                        </div>
                        <div id="availablefields">
                            <div className="fieldgroupheader" id="groupheadstandard" key="groupheadstandard">Common Fields</div>
                            {std}

                            <div className="fieldgroupheader" id="groupheadadvanced" key="groupheadadvanced">Additional Fields</div>
                            {adv}
                        </div>
                    </div>
                </div>
            </div>);
    }
}


// #########################################################################################################

// #########################################################################################################
class PasteExcelOptions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
            showSourceText: false,   
            showSourceDropdown: true
        };
        this.sourceChanged = this.sourceChanged.bind(this);
        this.updateSourceFields = this.updateSourceFields.bind(this);
        this.sourceOptions = [];
       
    }

    sourceChanged(e) {
        var source = e.target.value;
        if (source == 'EnterSource')
        {
            this.setState({
                showSourceText : true,
            });
        }
        else
        {
            if (this.state.showSourceText) {
                this.setState({
                    showSourceText: false,
                });
            }
        }
    }

    componentDidMount() {
        this.updateSourceFields();
    }

    componentDidUpdate(prevProps) {

        if (JSON.stringify(prevProps.vendorSources) !== JSON.stringify(this.props.vendorSources)) {
            this.updateSourceFields();
        }
    }
    updateSourceFields() {
        if (this.props.vendorSources && this.props.vendorSources.length > 0) {
            var initialOptions = [];
            initialOptions.push(<option key={-1} value={''}></option>);
            var params = {
                options: this.props.vendorSources,
                initialOptions: initialOptions
            }
            this.sourceOptions = quosal.ui.getOptionsFromListOfString(params);
            this.sourceOptions.push(<option key="EnterSource" value="EnterSource">Enter Source Name</option>);
            this.setState({
                showSourceText: false,
                showSourceDropdown: true
            });
        }
        else {
            this.setState({
                showSourceText: true,
                showSourceDropdown: false
            });
        }
    }
    render() {
        $('#externalQuoteExpiration').datepicker();
        $('#externalQuoteExpiration').parent().find('img.fieldicon').click(function () {
            $('#externalQuoteExpiration').focus();
        });
        var sourceOptions;
        return (
         
                <div className="optionwrap">
                    <div id="optionset">
                        <div className="formcolumn extrabottom">
                            <div className="formfield shortform">
                                <input type="checkbox" name="includeheaders" id="includeheaders" style={{"verticalAlign":"middle"}} onClick={this.props.headersCallback} />
                                <label style={{"verticalAlign":"middle","paddingLeft":"2px"}} htmlFor="includeheaders" className="formlabel">First Row Contains Headers</label></div>
    
                            <div className="formfield shortform">
                                <input type="checkbox" name="createtabs" id="createtabs" style={{"verticalAlign":"middle"}} onClick={this.props.tabsCallback} />
                                <label style={{"verticalAlign":"middle","paddingLeft":"2px"}} htmlFor="createtabs" className="formlabel">Create Tabs From Single-Cell Rows</label></div>
                            
                            <div className="formfield shortform">
                                <input type="checkbox" name="lookupetilize" id="lookupetilize" style={{"verticalAlign":"middle"}} />
                                <label style={{"verticalAlign":"middle","paddingLeft":"2px"}} htmlFor="lookupetilize" className="formlabel">Lookup Etilize Information</label>
                            </div>
    
                            <div className="formfield shortform">
                                <input type="checkbox" name="ignorezeroqty" id="ignorezeroqty" style={{"verticalAlign":"middle"}} />
                                <label style={{"verticalAlign":"middle","paddingLeft":"2px"}} htmlFor="ignorezeroqty" className="formlabel">Don't Import 0 Quantity Items</label>
                            </div>

                            <div className="formfield shortform">
                                <input type="checkbox" name="spacepackage" id="spacepackage" style={{"verticalAlign":"middle"}} />
                                <label style={{"verticalAlign":"middle","paddingLeft":"2px"}} htmlFor="spacepackage" className="formlabel">Import Bundles by Empty Lines</label>
                            </div>

                           <div className="formfield shortform">
                                <input type="checkbox" name="specialOrder" id="specialOrder" style={{"verticalAlign":"middle"}} />
                                <label style={{"verticalAlign":"middle","paddingLeft":"2px"}} htmlFor="specialOrder" className="formlabel">Special Order</label>
                           </div>
                        </div>
                        <div className="formcolumn extrabottom">
                        {this.state.showSourceDropdown &&
                            <div>
                                <div className="formfieldlabel"><label htmlFor="Source" className="formlabel">Source</label></div>
                                <div className="formselectfieldwrapper" >
                                    <select className="formselectfield" id="Source" onChange={this.sourceChanged}>{this.sourceOptions}</select>
                                </div>
                          </div>}
                        {this.state.showSourceText &&
                            <div>
                                <div className="formfieldlabel"><label htmlFor="SourceText" className="formlabel">Source Name</label></div>
                                <div className="formfield">
                                    <input type="text" name="SourceText" id="SourceText" title="SourceText" maxLength="100" defaultValue="" />
                                </div>
                            </div>}
                            <div className="formfieldlabel"><label htmlFor="externalQuoteNumber" className="formlabel">External Quote Number</label></div>
                            <div className="formfield"><input type="text" name="externalQuoteNumber" id="externalQuoteNumber" title="External Quote Number" maxLength="100" defaultValue="" /></div>

                            <div className="formfieldlabel"><label htmlFor="externalQuoteExpiration" className="formlabel">External Quote Expiration</label></div>
                            <div className="formfield"><input type="text" className="datepicker" name="externalQuoteExpiration" id="externalQuoteExpiration" title="External Quote Expiration" defaultValue="" /><img className="fieldicon" src="img/svgs/v1.0/Action_expandDown.svg" border="0" title="Edit Date" /></div>

                            <div className="formfieldlabel"><label htmlFor="PriceModifier" className="formlabel">Price Modifier</label></div>
                            <div className="formfield"><input type="text" name="PriceModifier" id="PriceModifier" title="Price Modifier" maxLength="12" defaultValue="" /></div>
    
                            <div className="formfieldlabel"><label htmlFor="CostModifier" className="formlabel">Cost Modifier</label></div>
                            <div className="formfield"><input type="text" name="CostModifier" id="CostModifier" title="Cost Modifier" maxLength="12" defaultValue="" /></div>
                        </div>
    
                        <div className="formcolumn extrabottom">
                            <div className="formfield shortform">
                            <input type="checkbox" name="createpackage" id="createpackage" style={{"verticalAlign":"middle"}} onClick={this.props.packCallback}/>
                            <label style={{"verticalAlign":"middle","paddingLeft":"2px"}} htmlFor="createpackage" className="formlabel">Create Bundle from Import</label></div>
                            
                            <div  id="packagedetails" style={{"display":"block", marginLeft: '20px'}}>
                                <div className="formfieldlabel"><label htmlFor="PackagePartNumber" className="formlabel disabled">Bundle Part #</label></div>
                                <div className="formfield"><input type="text" disabled name="PackagePartNumber" id="PackagePartNumber" title="Bundle Part #" maxLength="255" defaultValue="" /></div>
    
                                <div className="formfieldlabel"><label htmlFor="PackageDescription" className="formlabel disabled">Bundle Description</label></div>
                                <div className="formfield"><input type="text" disabled name="PackageDescription" id="PackageDescription" title="Bundle Description" maxLength="255" defaultValue="" /></div>
    
                                <div className="formfieldlabel"><label htmlFor="PackageQuantity" className="formlabel disabled">Bundle Quantity</label></div>
                                <div className="formfield"><input type="text" disabled name="PackageQuantity" id="PackageQuantity" title="Bundle Quantity" maxLength="8" defaultValue="1" /></div>
                            </div>
                        </div>
    
                        <div className="formcolumn extrabottom">
                            <div className="formfield shortform">
                                <input type="checkbox" name="updateonly" id="updateonly" style={{"verticalAlign":"middle"}} onClick={this.props.updateCallback}/>
                                <label style={{"verticalAlign":"middle","paddingLeft":"2px"}} htmlFor="updateonly" className="formlabel">Update from Import</label></div>
                            
                            <div id="updatedetails" style={{"display":"block"}}>
                                <div className="formfield shortform" style={{marginLeft: '20px'}}>
                                    <input type="checkbox" disabled name="updatecost" id="updatecost" style={{"verticalAlign":"middle"}} />
                                    <label style={{"verticalAlign":"middle","paddingLeft":"2px"}} htmlFor="updatecost" className="formlabel disabled">Update Cost</label>
                                </div>
                                <div className="formfield shortform" style={{marginLeft: '20px'}}>
                                    <input type="checkbox" disabled name="updatesuggested" id="updatesuggested" style={{"verticalAlign":"middle"}} />
                                    <label style={{"verticalAlign":"middle","paddingLeft":"2px"}} htmlFor="updatesuggested" className="formlabel disabled">Update Suggested</label>
                                </div>
                                <div className="formfield shortform" style={{marginLeft: '20px'}}>
                                    <input type="checkbox" disabled name="updatequantity" id="updatequantity" style={{"verticalAlign":"middle"}} />
                                    <label style={{"verticalAlign":"middle","paddingLeft":"2px"}} htmlFor="updatequantity" className="formlabel disabled">Update Quantity</label>
                                </div>
                                <div className="formfield shortform" style={{marginLeft: '20px'}}>
                                    <input type="checkbox" disabled name="createnew" id="createnew" style={{"verticalAlign":"middle"}} />
                                    <label style={{"verticalAlign":"middle","paddingLeft":"2px"}} htmlFor="createnew" className="formlabel disabled">Create Products That Don't Exist</label>
                                </div>
                            </div>
    
                        </div>
                        
                    </div>
                    </div>);
    }
}

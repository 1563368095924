import React, { useCallback, useEffect, useMemo, useState } from "react";

import { MenuItem } from "@mui/material";
import useDarkMode from "../../WidgetToolbar/widgetToolbarHelpers";

const usePriceSorceModalContainer = () => {
	const [completedSearches, setCompletedSearches] = useState([]);
	const [completedSources, setCompletedSources] = useState([]);
	const [searchingSources, setSearchingSources] = useState([]);
	const [row, setRow] = useState("");
	const [sources, setSources] = useState([]);
	const [selectedGroup, setSelectedGroup] = useState("");
	const [searching, setSearching] = useState(false);
	const [apiCall, setApiCall] = useState(null);
	const [selectedResult, setSelectedResult] = useState("");
	const [selectedWarehouses, setSelectedWarehouses] = useState([]);
	const [updating, setUpdating] = useState(false);
	const [selectedCustomSources, setSelectedCustomSources] = useState([]);
	const [customSources, setCustomSources] = useState([]);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [itemInRow, setItemInRow] = useState(5);
	const [customSourceList, setCustomSourceList] = useState("No sources selected");
	const [dialogClasses, setDialogClasses] = useState("");
	const [isOpen, setIsOpen] = useState(false);
	const [modalData, setModalData] = useState({});


	const darkMode = useDarkMode();
	const { params, type, updateGridRows } = modalData;

	const resetModal = () => {
		setCompletedSearches([]);
		setCompletedSources([]);
		setSearchingSources([]);
		setRow("");
		setSources([]);
		setSelectedGroup("");
		setSearching(false);
		setApiCall(null);
		setSelectedResult("");
		setSelectedWarehouses([]);
		setUpdating(false);
		setSelectedCustomSources([]);
		setDialogOpen(false);
		setCustomSources([]);
		setItemInRow(5);
		setCustomSourceList("No sources selected");
	};

	const handleClose = () => {
		setIsOpen(false);
		resetModal();
		abortPricingLookup();
	};

	const getSelectedSources = () => {
		let sources = null;
		let selGroup = "";

		const selectedGroupCookie = quosal.util.cookie(`${type}_sourcegroup`);

		if (selectedGroupCookie) {
			selGroup = selectedGroupCookie;
		} else {
			selGroup = "all";
		}

		if (selGroup === "all") {
			if (quosal.sell.product.sources) {
				sources = [...quosal.sell.product.sources];
			} else {
				sources = [];
			}
		} else if (selGroup === "custom") {
			sources = JSON.parse(quosal.util.cookie(`${type}_customsourcegroup`));

			if (sources) {
				setSelectedCustomSources(sources);
			}

			const customList = quosal.util.cookie(`${type}_customsourcelist`);
			setCustomSourceList(customList || "No sources selected");
		} else {
			// saved group selected
			const group = quosal.sell.product.sourceGroups.firstOrNull(
				(s) => s.IdProductSourceGroup === selGroup
			);
			if (group && group.Sources) {
				sources = [...group.Sources];
			} else {
				selGroup = "all";
				sources = [...quosal.sell.product.sources]; // fallback to all sources
			}
		}

		setSelectedGroup(selGroup);

		return sources || [];
	};

	const beginPricingLookup = () => {
		const mfp = row.ManufacturerPartNumber;
		const mfpEtilizeIdPairs = {};

		mfpEtilizeIdPairs[row.ManufacturerPartNumber] = row.EtilizeProductId;

		if (String.isNullOrEmpty(mfp) || mfp.toUpperCase() === "NEW") {
			return;
		}

		const completePricingApiCall = function () {
			const pricingApi = quosal.api.pricing.lookupPricing(
				[mfp],
				sources,
				app.currentQuote ? app.currentQuote.IdQuoteMain : null,
				true,
				false,
				mfpEtilizeIdPairs,
				true
			);
			pricingApi.stateChanged = function (msg) {
				if (msg.action === "PricingResults") {
					let priceSources = [];
					if (msg.sources) {
						priceSources = msg.sources;
					} else if (msg.source) {
						priceSources = [msg.source];
					}

					for (let i = 0; i < priceSources.length; i++) {
						setSearchingSources(
							searchingSources.removeAll(
								(s) => s.SourceId === priceSources[i].SourceId
							)
						);
					}

					if (msg.products) {
						for (let i = 0; i < msg.products.length; i++) {
							let productId = 0; // 0 - indicating this item did not come from Etilize
							if (row.EtilizeProductId) {
								// Quote item
								productId = row.EtilizeProductId;
							} else if (row.Productid) {
								// Etilize product
								productId = row.Productid;
							}
							// if msg.products[i] does not have a product ID -> the result came from some place other than Etilize/RTP

							if (
								productId == 0 ||
								!msg.products[i].productId ||
								productId == msg.products[i].productId
							) {
								if (!msg.products[i].error) {
									setCompletedSearches((completedSearches) => [
										...completedSearches,
										msg.products[i]
									]);
								}
							}
						}
					}
					// Look like no need to update grid just for getting price source data
					// grid.forceUpdate();
				}
			};

			pricingApi.finished = function (msg) {
				setSearching(false);
				setSearchingSources([]);
				setApiCall(null);
			};

			setSearching(true);
			setSearchingSources(sources);
			setApiCall(pricingApi);
			setSelectedResult("");
			setCompletedSearches([]);
			setCompletedSources([]);
			setSelectedWarehouses([]);

			pricingApi.call();
		};

		if (apiCall) {
			abortPricingLookup(completePricingApiCall);
		} else {
			completePricingApiCall();
		}
	};

	const abortPricingLookup = useCallback(
		(callback) => {
			if (apiCall) {
				apiCall.abort();
				setSearchingSources([]);
				setApiCall(null);
				setSearching(false);

				if (callback) {
					callback();
				}
			}
		},
		[apiCall]
	);

	const getSelectedWarehouse = (array, sourceId) => {
		let value = "";
		array.map((obj) => {
			if (obj.sourceId == sourceId) {
				value = obj;
			}
		});
		return value;
	};

	const handleCompletedSources = () => {
		const completedSearchResult = [];
		const defaultWarehouses = [];

		for (let i = 0; i < completedSearches.length; i++) {
			const completedSource = completedSearches[i];
			if (!completedSource.error && completedSource.source) {
				let isSelected = false;
				if (selectedResult) {
					isSelected = selectedResult.source === completedSource.source;
				} else if (row) {
					isSelected =
						row.source === completedSource.source.EtilizeName ||
						row.source === completedSource.source.DisplayName;
					if (
						isSelected &&
						(completedSource.sourceSelectionDetails || row.SourceSelectionDetails)
					) {
						isSelected =
							completedSource.sourceSelectionDetails === row.SourceSelectionDetails;
					}
				}

				let warehouse;
				let isChecked;
				const warehouses = [];

				const warehouseExists = getSelectedWarehouse(
					selectedWarehouses,
					completedSource.source.SourceId
				);
				const bestWarehouse = getBestWarehouse(completedSource.warehouses);

				if (!warehouseExists && bestWarehouse) {
					defaultWarehouses.push({
						sourceId: completedSource.source.SourceId,
						selectedWarehouse: bestWarehouse.name
					});
				}

				if (completedSource.warehouses) {
					for (let i = 0; i < completedSource.warehouses.length; i++) {
						warehouse = completedSource.warehouses[i];

						if (warehouseExists) {
							isChecked =
								isSelected &&
								selectedWarehouses.find(
									(war) => war.selectedWarehouse === warehouse.name
								);
						} else {
							isChecked = isSelected && bestWarehouse.name === warehouse.name;
						}

						const house = {
							warehouse,
							isChecked
						};

						warehouses.push(house);
					}
				}

				let sourceName;
				if (
					completedSource.source.SourceName === "salesforce" &&
					completedSource &&
					completedSource.pricebook
				) {
					sourceName = `SF: ${completedSource.pricebook}`;
				} else if (completedSource && completedSource.sourceName) {
					sourceName = completedSource.sourceName;
				} else {
					sourceName = completedSource.source.FormattedDisplayName;
				}

				const completeSource = {
					source: completedSource.source,
					pricing: completedSource,
					isSelected,
					warehouses,
					sourceName
				};

				completedSearchResult.push(completeSource);
			}
		}

		if (defaultWarehouses && defaultWarehouses.length > 0) {
			setSelectedWarehouses((selWar) => [...selWar, ...defaultWarehouses]);
		}

		setCompletedSources(completedSearchResult);
	};

	const getBestWarehouse = (warehouses) => {
		let bestWarehouse = null;
		if (warehouses) {
			for (let i = 0; i < warehouses.length; i++) {
				const warehouse = warehouses[i];
				if (!bestWarehouse || warehouse.qty > bestWarehouse.qty) {
					bestWarehouse = warehouse;
				}
			}
		}

		return bestWarehouse;
	};

	const menuItems = (() => {
		const sourceOptions = [];

		if (!quosal.sell.product.sourceGroups) {
			return null;
		}

		sourceOptions.push(
			<MenuItem key="all" value="all" selected>
				All Sources
			</MenuItem>
		);

		for (let i = 0; i < quosal.sell.product.sourceGroups.length; i++) {
			sourceOptions.push(
				<MenuItem
					key={quosal.sell.product.sourceGroups[i].IdProductSourceGroup}
					value={quosal.sell.product.sourceGroups[i].IdProductSourceGroup}
				>
					{quosal.sell.product.sourceGroups[i].GroupName}
				</MenuItem>
			);
		}

		sourceOptions.push(
			<MenuItem key="custom" value="custom">
				Custom
			</MenuItem>
		);

		return sourceOptions;
	})();

	const customSourceItems = useMemo(() => {
		if (customSources) {
			const rows = [];

			let validItems = customSources.filter(
				(src) => src.SourceType !== "RTP" && src.SourceType !== "Retail"
			);

			const remainder = validItems.length % itemInRow;

			if (remainder !== 0) {
				const emptyItems = Array(itemInRow - remainder).fill({});
				validItems = [...validItems, ...emptyItems];
			}

			for (let i = 0; i < validItems.length; i += itemInRow) {
				rows.push(validItems.slice(i, i + itemInRow));
			}

			return rows;
		}

		return null;
	}, [customSources]);

	const customSourcesSelected = (e) => {
		const sourceId = e.target.value;

		if (e.target.checked) {
			const src = quosal.sell.product.sources.firstOrNull((s) => s.SourceId === sourceId);

			if (src) {
				setSelectedCustomSources((customSources) => [...customSources, src]);
			}
		} else {
			setSelectedCustomSources(
				selectedCustomSources.removeAll((s) => s.SourceId !== sourceId)
			);
		}
	};

	const handleDropdownChange = useCallback(
		(event) => {
			quosal.util.cookie(`${type}_sourcegroup`, event.target.value);
			setSelectedGroup(event.target.value);

			if (event.target.value === "custom") {
				handleDialogOpen();
			}

			if (searching) {
				abortPricingLookup();
			}
		},
		[searching]
	);

	function priceResultSelected(result) {
		if (selectedResult !== result) {
			setSelectedResult(result);
		}
	}

	function warehouseChanged(sourceId, event) {
		const copy = selectedWarehouses;
		const newWarehouses = copy.map((war) => {
			if (war.sourceId === sourceId) {
				return { ...war, selectedWarehouse: event.target.value };
			}

			return war;
		});

		setSelectedWarehouses(newWarehouses);
	}

	const updateSource = () => {
		if (!selectedResult || updating) {
			return;
		}

		setUpdating(true);

		const mfp = row.ManufacturerPartNumber;

		const doUpdate = function (item) {
			const selWarehouse = getSelectedWarehouse(
				selectedWarehouses,
				selectedResult.source.SourceId
			);

			const updateSourceApi = quosal.api.product.updateSource(
				item.idQuoteMain,
				item.idQuoteItems,
				mfp,
				selectedResult.pricing.vp,
				selectedResult.pricing,
				selectedResult.pricing.cost,
				selectedResult.pricing.msrp,
				selWarehouse.selectedWarehouse,
				selectedResult.pricing.sourceSelectionDetails,
				selectedResult.pricing.pricebook
			);
			updateSourceApi.finished = function (msg) {
				if (msg.quote) {
					quosal.sell.quote.update(msg.quote);
				}

				setUpdating(false);

				if (msg.error && msg.error.length > 0) {
					$.quosal.dialog.show({
						title: "Update Source",
						message: msg.error,
						links: [$.quosal.dialog.links.ok]
					});
				}

				updateGridRows();
				handleClose();
			};
			updateSourceApi.call();
		};

		doUpdate(row);
	};

	const handleDialogClose = () => {
		setDialogOpen(false);
	};

	const handleDialogOpen = () => {
		setDialogOpen(true);
	};

	const updateCustomGroup = () => {
		const sourceGroupsCookie = JSON.stringify(selectedCustomSources);
		let sourceListCookie = "";

		for (let i = 0; i < selectedCustomSources.length; i++) {
			sourceListCookie +=
				(sourceListCookie === "" ? "" : ", ") + selectedCustomSources[i].FormattedName;
		}

		quosal.util.cookie(`${type}_customsourcegroup`, sourceGroupsCookie);
		quosal.util.cookie(`${type}_customsourcelist`, sourceListCookie);

		setSelectedGroup("custom");
		setCustomSourceList(sourceListCookie || "No sources selected");

		if (!sourceListCookie) {
			abortPricingLookup();
		}

		handleDialogClose();
	};

	const resetCustomGroup = () => {
		setSelectedCustomSources([]);
	};

	useEffect(() => {
		if (isOpen) {
			setRow(params.row);
			setCustomSources(quosal.sell.product.sources);
			setSources(getSelectedSources());
		}
	}, [isOpen]);

	useEffect(() => {
		if (selectedGroup) {
			setSources(getSelectedSources());
		}
	}, [selectedGroup, customSourceList]);

	useEffect(() => {
		if (row && sources && sources.length > 0 && !searching) {
			beginPricingLookup();
		}
	}, [row, sources]);

	useEffect(() => {
		if (
			completedSources &&
			completedSources.length &&
			completedSources.length === 1 &&
			!selectedResult
		) {
			setSelectedResult(completedSources[0]);
		}
	}, [completedSources]);

	useEffect(() => {
		if (
			(completedSearches && completedSearches.length > 0) ||
			selectedResult ||
			(selectedWarehouses && selectedWarehouses.length > 0)
		) {
			handleCompletedSources();
		}
	}, [completedSearches, selectedResult, selectedWarehouses]);


	useEffect(() => {
		setDialogClasses(darkMode ? "darkMode" : "");
	}, [darkMode]);

	return {
		darkMode,
		completedSources,
		searchingSources,
		selectedGroup,
		menuItems,
		dialogClasses,
		customSourceList,
		selectedResult,
		updating,
		dialogOpen,
		customSourceItems,
		selectedCustomSources,
		isOpen,
		modalData,
		setIsOpen,
		setModalData,
		handleClose,
		priceResultSelected,
		warehouseChanged,
		handleDropdownChange,
		handleDialogOpen,
		updateSource,
		handleDialogClose,
		customSourcesSelected,
		resetCustomGroup,
		updateCustomGroup
	};
};
export default usePriceSorceModalContainer;

import {FormFieldInput} from "js/jsx/src/classes/forms.jsx";

export class MassUpdateFieldSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {      
            targetRows: [],
            skipping: 0,
            fieldUpdates: [],
            fieldFilter: '',
            dirtyindex: 0,
            allInitialRows: [],
        }; 
        this.skippedRowsDueToLockedPricing = [];
        this.initialSkippedRowIds = [];
        this.handleFieldListClick = this.handleFieldListClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleBooleanChange = this.handleBooleanChange.bind(this);
        this.handleKey = this.handleKey.bind(this);
        this.handleUpdateButton = this.handleUpdateButton.bind(this);
        this.validate = this.validate.bind(this);
        this.submitFieldUpdate = this.submitFieldUpdate.bind(this);
        this.canUpdateItems = this.canUpdateItems.bind(this);
        this.filterChanged = this.filterChanged.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.removeUpdateSet = this.removeUpdateSet.bind(this);
        this.renderFieldList = this.renderFieldList.bind(this);
    }
    
    componentDidMount() {
        $(this.filter).focus();

        var grid = this.props.grid;
        var rowIds = [];
        var skippedRows = 0;
        var selectedRows = quosal.util.isNewEditorEnabled() ? grid.selectedShowingRows : grid.selectedShowingRows();
        var userCanModifyProtectedItem= quosal.util.userCanModifyProtectedItem();
        for(var i = 0; i < selectedRows.length; i++) {
            if(String.isNullOrEmpty(grid.props.rows[selectedRows[i]].LineType) //exclude comments, sections, spacers, etc
                && String.isNullOrEmpty(grid.props.rows[selectedRows[i]].IdItemConfigurations)  // pack headers
                && (userCanModifyProtectedItem || !grid.props.rows[selectedRows[i]].IsProtectedItem)) { // protected

                quosal.util.isNewEditorEnabled() ? rowIds.push(grid.props.rows[selectedRows[i]].idQuoteItems) : rowIds.push(grid.props.rows[selectedRows[i]].IdQuoteItems);
            } else{
                quosal.util.isNewEditorEnabled() ? this.initialSkippedRowIds.push(grid.props.rows[selectedRows[i]].idQuoteItems) : this.initialSkippedRowIds.push(grid.props.rows[selectedRows[i]].IdQuoteItems);
                skippedRows++;
            }
        }
        this.setState({
            targetRows: rowIds,
            skipping: skippedRows,
            allInitialRows: rowIds
        });
    }
    removeUpdateSet(e) {
        let item = $(e.target);
        let index = item.attr('data-fieldindex');
        if (index < 0 || index > this.state.fieldUpdates.length) {
            return;
        }
        let name = this.state.fieldUpdates[index].name;

        let newones = this.state.fieldUpdates.filter(o => o.name !== name);
        this.setState({fieldUpdates: newones});
    }
 
    handleFieldListClick(e) {
        let item = $(e.target);
        let name = item.attr('name');
        let type = item.attr('type');
        let displayName = item.attr('data-display-name');
        let cancustom = item.attr('data-customizable');
        let outputValue = (type === 'Boolean') ? 'false' : '';
        let entry = {
            name: name,
            type: type,
            display: displayName,
            value: outputValue,
            customizable: cancustom
        }
        let obj = this.state.fieldUpdates.find(o => o.name === name);
        let newones = null;
        var updateObj = {
            skipping: this.state.skipping,
            targetRows: this.state.targetRows.slice(0)
        };
        if (obj) {
            newones = this.state.fieldUpdates.filter(o => o.name !== name);
            if(this.state.allInitialRows.length != this.state.targetRows.length){
                var targetRows = [];
                var me = this;
                this.state.allInitialRows.forEach(function(itemId){
                    var item = quosal.util.getItemById(app.currentQuote, itemId);
                    if(item.IsProtectedPrice && !newones.map(function(field){return field.name}).some(function(field){return quosal.util.isProtectedPriceItem(field)})){
                        targetRows.push(itemId);
                        updateObj['skipping'] -= 1;
                        me.skippedRowsDueToLockedPricing = me.skippedRowsDueToLockedPricing.filter(function(item){return itemId != item});
                    } else if(!item.IsProtectedPrice) {
                        targetRows.push(itemId);
                    }
                })
                updateObj['targetRows'] = targetRows;
            }
        }
        else {
            if (e.getModifierState('Control')) {
                newones = [];
            }
            else {
                newones = this.state.fieldUpdates.splice(0);
            }
            newones.push(entry);
        }
        updateObj["fieldUpdates"] = newones;
        var me = this;
        var userCanModifyProtectedPrice = quosal.util.userCanModifyProtectedPrice();       
        this.state.targetRows.forEach(function(itemId){
            if(!userCanModifyProtectedPrice && quosal.util.getItemById(app.currentQuote, itemId).IsProtectedPrice && quosal.util.isProtectedPriceItem(name)){
                updateObj['targetRows'] = updateObj['targetRows'].filter(function(item){return item != itemId});
                updateObj['skipping'] += 1;
                me.skippedRowsDueToLockedPricing.push(itemId);
            }
        })
        this.setState(updateObj);
    }
    handleChange(e) {
        let index = e.target.dataset.itemIndex;
        let item = this.state.fieldUpdates[index];
        if (!item){
            return;
        }
        if (item.type == 'Int32') {
            item.value = e.target.value.replace(/(?![0-9])./gmi,'');
        }
        else if (item.type == 'Double') {
            item.value = e.target.value.replace(/(?![0-9,.])./gmi,'');
        }
        else {
            item.value = e.target.value;
        }
        this.validateRow(item);

        this.setState({
            dirtyindex: this.state.dirtyindex+1
        });
    }
    handleBooleanChange(e) {
        let index = e.target.dataset.itemIndex;
        let item = this.state.fieldUpdates[index];
        item.value = e.target.value;
        this.setState({
            dirtyindex: this.state.dirtyindex+1
        });
    }
    handleKey(e) {
        if (e.which === 13 && this.canUpdateItems()) {
            this.submitFieldUpdate();
        }
    }
    handleUpdateButton(e) {
        if (this.canUpdateItems()) {
            this.submitFieldUpdate();
        }
    }
    canUpdateItems() {
        if (this.state.targetRows.length == 0) {
            return false;
        }
        if (this.state.fieldUpdates.length == 0) {
            return false;
        }
        for (let i=0; i < this.state.fieldUpdates.length;i++) {
            let up = this.state.fieldUpdates[i];
            if (up.error) {
                return false;
            }
        }
        return true;
    }

    validateRow(elem) {
        elem.error = null;
        let testVal = null;
        if (elem.type === 'Int32') {
            testVal = parseInt(elem.value);
            if (isNaN(testVal) || (elem.value && elem.value.indexOf('.') >= 0)) {
                elem.error = 'Invalid integer';
            }
        } else if (elem.type === 'Double') {
            testVal = parseFloat(elem.value);
            if (isNaN(testVal)) {
                elem.error = 'Invalid decimal';
            }
        } else if (elem.type == 'DateTime') {
            // FSP 5/19/16: TODO: it would be good to have some real DateTime validation on the front end;
            // this would require processing the date format that the user's company has set. For now I'm using the same level of validation as in oldGen.
            if (elem.value == null || elem.value == '') {
                elem.error = 'Invalid date';
            }
        } else if (Object.keys(quosal.priceModifier.modifierMatches).includes(elem.name)){
            var modifierObj = quosal.priceModifier.modifierMatches[elem.name];
            var matchRegex = modifierObj.regexMatch;
            if(elem.value.match(matchRegex) == null){
                elem.error = modifierObj.errorMessage;
                return;
            } 
        }
        if (elem.custom && !elem.value) {
            elem.error = 'Invalid selection';
        }
    }

    validate() {
        let allgood = true;
        for (let i=0; i < this.state.fieldUpdates.length;i++) {
            this.validateRow(this.state.fieldUpdates[i]);
            if (this.state.fieldUpdates[i].error) {
                allgood = false;
            }
        }
        return allgood;
    }

    submitFieldUpdate() {
        var grid = this.props.grid;

        if (!this.validate()) {
            this.forceUpdate();
            return;
        }

        var rowIds = []; // same as state.targetRows
        var skippedRows = 0;
        var selectedRows = quosal.util.isNewEditorEnabled() ? grid.selectedShowingRows : grid.selectedShowingRows();
        var userCanModifyProtectedItem = quosal.util.userCanModifyProtectedItem();
        var userCanModifyProtectedPrice = quosal.util.userCanModifyProtectedPrice();
        
        var fields = {};
        for (var f=0;f < this.state.fieldUpdates.length;f++) {
            var entry = this.state.fieldUpdates[f];
            fields[entry.name] = entry.value;
        }
       

        for(var i = 0; i < selectedRows.length; i++) {
            if(String.isNullOrEmpty(grid.props.rows[selectedRows[i]].LineType) //exclude comments, sections, spacers, etc
                && String.isNullOrEmpty(grid.props.rows[selectedRows[i]].IdItemConfigurations)  // pack headers
                && (userCanModifyProtectedItem || !grid.props.rows[selectedRows[i]].IsProtectedItem) // protected item
                && (userCanModifyProtectedPrice || 
                    !grid.props.rows[selectedRows[i]].IsProtectedPrice || 
                    !Object.keys(fields).some(function(field){return quosal.util.isProtectedPriceItem(field)}) //protected price fields
                      )
                ) { 
                    for (var f=0;f < this.state.fieldUpdates.length;f++) {
                        var entry = this.state.fieldUpdates[f];
                        grid.props.rows[selectedRows[i]][entry.name] = entry.value;
                    }
                quosal.util.isNewEditorEnabled() ? rowIds.push(grid.props.rows[selectedRows[i]].idQuoteItems) : rowIds.push(grid.props.rows[selectedRows[i]].IdQuoteItems);
            } else
                skippedRows++;
        }

       
        var updateApi = quosal.api.data.update({
            fields: fields,
            queries: [{
                table: 'QuoteItems',
                where: [{
                    field: 'IdQuoteItems',
                    operator: 'In',
                    value: rowIds
                }]
            }]
        }, this.props.idQuoteMain);
        updateApi.finished = function(skippedRows, msg) {
            Dialog.close({
                skipAnimation: true
            });

            quosal.sell.quote.updateFromApiResponse(msg);
            this.props.handleChangeAfterAction();
            if (quosal.util.isNewEditorEnabled()) {
                this.props.updateProductInCKEditor();
            }
            var skippedMfpsDueToLineType = this.initialSkippedRowIds
            .filter(function(itemId){
                var item = quosal.util.getItemById(app.currentQuote, itemId);
                return item.LineType != "";
            });

            var skippedMfpsDueToProtectedItem = this.initialSkippedRowIds
            .map(function(itemId){
                return quosal.util.getItemById(app.currentQuote, itemId).ManufacturerPartNumber;
            })
            .filter(function(item){
                return item != "";
            });

            if(skippedMfpsDueToProtectedItem.length > 0 || this.skippedRowsDueToLockedPricing.length > 0 || skippedMfpsDueToLineType > 0){
                var message = "";
                if(skippedMfpsDueToLineType.length > 0){
                    message = (<div>{message}<div>Some of the items selected were comment lines, spacers, section headers or footers and could not be updated</div><br/><br/></div>);
                }
                if(skippedMfpsDueToProtectedItem.length > 0) {
                    var skippedMpfList = skippedMfpsDueToProtectedItem.length > 1 
                    ? skippedMfpsDueToProtectedItem.slice(0, -1).join(', ') + ', and ' + skippedMfpsDueToProtectedItem.slice(-1) 
                    : skippedMfpsDueToProtectedItem;

                    message = (<div>{message}<div>The following {(skippedMfpsDueToProtectedItem.length > 1 ? 'items could ' : 'item could ')}
                    not be updated because the item is either protected or a package header:</div>
                    <br/><div>{skippedMpfList}</div><br/><br/></div>)
                }
                if(this.skippedRowsDueToLockedPricing.length > 0){
                    var mfps = this.skippedRowsDueToLockedPricing.map(function(itemId){
                        return quosal.util.getItemById(app.currentQuote, itemId).ManufacturerPartNumber;
                    }) 
                    var displayNameProtectFields = this.state.fieldUpdates
                        .filter(function(item){
                            return(quosal.util.isProtectedPriceItem(item.name))})
                        .map(function(item){
                                return item.display;
                    })
                    var displayNameProtectFieldsList = displayNameProtectFields.length > 1 
                        ? displayNameProtectFields.slice(0, -1).join(', ') + ', and ' + displayNameProtectFields.slice(-1) 
                        : displayNameProtectFields;

                    var mfpsList = mfps.length > 1 
                        ? mfps.slice(0, -1).join(', ') + ', and ' + mfps.slice(-1) 
                        : mfps;

                    message = (<div>{message} The following {(mfps.length > 1 ? 'items ' : 'item ')} could 
                    not be updated because {(mfps.length > 1 ? 'their ' : 'its ')} price is protected: <br/><br/> 
                    {mfpsList} <br/><br/>If there are non-price related fields you need to update, 
                    please try again without making changes to: <br/><br/> {displayNameProtectFieldsList} </div>);
                }
                Dialog.close({
                    callback: function(skippedRows, rowIds) {
                        Dialog.open({
                            message: message,

                            links: [Dialog.links.ok]
                        });
                    }.bind(this, skippedRows, rowIds)
                });
            } else {
                Dialog.close();
            }
        }.bind(this, skippedRows);

        Dialog.open({
            message: <FormPlaceholder message='Updating Fields...' />,
            closeRequiresButton: true
        });

        updateApi.call();
    }

    createUpdateBlock(parent, id, entry) {
        var input = null;
        let label = MassUpdateFieldSelector.fieldTypeToLabel[entry.type];
        if (!label) {
            label = '';
            if (entry.type == 'DateTime') {
                label = 'Date';
            }
            if (entry.type == 'Boolean') {
                label = 'Yes/No';
            }
        }
        let stdEditStyle = {color:'#212121', height:'reset', fontSize:'14px'};
        let labelStyle = {fontSize:'14px', color:'#7d7d7d', height:'auto'};
        let custom = null;
       
        var fieldInfos = quosal.customization.fields["BusinessObject"]["QuoteItems"].allFields

        var fieldInfo = fieldInfos.where(function (x) {
                        return x.FieldName == entry.name;
        })[0];

        if(fieldInfo.IsEnum){
            entry.custom = quosal.metadata.enums[fieldInfo.EnumType];
        }
        else if (entry.customizable) {
            var gridConfig = quosal.customization.grids[this.props.grid.props.configuration.gridName];

            if(!entry.custom){
                var fieldConfig = quosal.customization.fields.getFieldConfiguration(gridConfig.ObjectType, gridConfig.ObjectName, entry.name);
                entry.custom = fieldConfig && fieldConfig.CustomDropdownContents;
            }
        }

        if (entry.custom) {
            label = 'List';
            var options = FormFieldInput.getOptionListFromEnumConfiguration(entry.custom, entry.value);
            input = (<div className='formselectfieldwrapper'>
                            <select className='formselectfield' id={'e'+id} data-item-index={id} title={entry.display} value={entry.value} onChange={this.handleChange} onKeyPress={this.handleKey} >
                                {options}
                            </select>
                        </div>);
        } else if (entry.type === 'String') {
            input = (<div className='formfield'>
                        <input id={'e'+id} data-item-index={id} type='text' title={entry.display} maxLength='255' value={entry.value} style={stdEditStyle}
                            onChange={this.handleChange} onKeyPress={this.handleKey} />
                    </div>);
        } else if (entry.type === 'Int32') {
            input = (<div className='formfield'>
                        <input id={'e'+id} data-item-index={id} type='text' title={entry.display} maxLength='12' value={entry.value} style={stdEditStyle}
                            onChange={this.handleChange} onKeyPress={this.handleKey} />
                    </div>);
        } else if (entry.type === 'Double') {
            input = (<div className='formfield'>
                        <input id={'e'+id} data-item-index={id} type='text' title={entry.display} maxLength='12' value={entry.value} style={stdEditStyle}
                            onChange={this.handleChange} onKeyPress={this.handleKey} />
                    </div>);
        } else if (entry.type === 'Boolean') {
            input = (<div className='formfield'>
                        <span className='formcheckboxwrapper'>
                            <input id={'e'+id+'y'} data-item-index={id} type='radio' checked={entry.value=='true'} value='true' title={entry.display}
                                onChange={this.handleChange} style={{height:'auto'}} />
                            <label htmlFor={'e'+id+'y'} className='formlabel' style={{width:'60px', color:'#212121',fontSize:'14px'}}>Yes</label>
                        </span>
                        <span className='formcheckboxwrapper'>
                            <input id={'e'+id+'n'} data-item-index={id} type='radio' checked={entry.value!='true'} value='false' title={entry.display} 
                                onChange={this.handleChange} style={{height:'auto'}} />
                            <label htmlFor={'e'+id+'n'} className='formlabel' style={{width:'60px', color:'#212121', fontSize:'14px'}}>No</label>
                        </span>
                    </div>);
        } else if (entry.type === 'DateTime') {
            input = <MassUpdateFieldSelectorDatePicker parent={parent} value={entry.value} id={'e'+id} itemIndex={id} onChange={this.handleChange}
                        handleKey={this.handleKey} />;
        }
        let error = null;
        if (entry.error) {
            error = <div className='formfieldlabel' style={{paddingTop:'0px', marginTop:'-4px', height:'auto'}}>
                        <label className='formlabel' style={{fontSize:'14px', color:'#ca001a', height:'auto', whiteSpace:"normal"}}>{entry.error}</label>
                    </div>;
            labelStyle.color = '#ca001a';
        }
        let groupStyle={
            paddingTop:'18px', height:'auto', position:'relative', left:'-12px'
        };
        if (id==0) {
            groupStyle.paddingTop = '0px';
        }
        return (<div>
            <div id='removeupdateset' className='icon close disabled' onClick={this.removeUpdateSet} title='Remove Field' data-fieldindex={id} />
            <div className='formfieldlabel' style={groupStyle}>
                <label className='formlabel' style={labelStyle}>{entry.display} ({label})</label>
            </div>
            {input}
            {error}
        </div>);
    }

    filterChanged() {
        this.setState({fieldFilter: this.filter.value});
    }
    clearFilter() {
        this.filter.value = '';
        this.setState({fieldFilter: ''});
    }

    renderFieldList() {
        var quoteItemFieldData = quosal.customization.fields[quosal.customization.fields.types.businessObject]['QuoteItems'];
        var fieldConfigs = quoteItemFieldData.fieldConfigurations;
        var createFieldListsCount = 0;
        var createFieldLists = function (inputFields) {
            inputFields.sort(quosal.customization.fields.sortByDisplayName.bind(null, fieldConfigs));

            let outputButtons = [];
            for (let i = 0; i < inputFields.length; i++) {
                if(this.props.quote.QuoteStatus == 'Won' && inputFields[i].FieldName != 'Cost' && inputFields[i].FieldName != 'RecurringCost') {
                    continue;
                }
                if (MassUpdateFieldSelector.fieldBlacklist[inputFields[i].FieldName]) {
                    continue;
                }

                let field = inputFields[i];
                let fieldConfig = fieldConfigs[field.FieldName];
                let displayName = fieldConfig && fieldConfig.FieldRename || field.DisplayName;
                
                let fieldStyle = {
                    fontSize:'12px',
                    cursor: 'pointer',
                    fontWeight:'normal',
                    backgroundColor:'white',
                    color:'#212121',
                    margin: '3px 2px 3px 6px',
                    padding: '3px 4px 3px 4px',
                    border: '2px #e6e6e6 dashed'
                };
                let className = 'availablefield';
                let obj = this.state.fieldUpdates.find(o => o.name === field.FieldName);
                if (obj) {
                    className += ' selected';
                    fieldStyle.border='2px #026CCF dashed';
                    fieldStyle.color='#212121';
                    fieldStyle.backgroundColor = 'white';
                }
                if (field.DataType == 'Byte[]') {
                    className += ' hidden';
                } else if (this.state.fieldFilter != '*' && displayName.toLowerCase().indexOf(this.state.fieldFilter.toLowerCase()) < 0 || (this.state.fieldFilter == '*' && !obj)) {
                    className += ' hidden';
                }
                else {
                    createFieldListsCount++;
                }
                outputButtons.push(<div className={className} type={field.DataType} name={field.FieldName} data-customizable={field.CanCustomizeDropdown}
                                        data-display-name={displayName} key={field.FieldName} style={fieldStyle}
                                        onClick={this.handleFieldListClick}>{displayName}</div>);
            }
            return outputButtons;
        }.bind(this);
        createFieldListsCount = 0;
        let standardFieldPickButtons = createFieldLists(quoteItemFieldData.standardFields.where(function (f) { return !f.IsPrivateField && !f.ReadOnly; }));
        let visStandard = createFieldListsCount;
        createFieldListsCount = 0;
        let additionalFieldPickButtons = createFieldLists(quoteItemFieldData.additionalFields.where(function (f) { return !f.IsPrivateField && !f.ReadOnly; }));
        let visAdditional = createFieldListsCount;
        if(!(app.currentUser.IsContentMaintainer || app.currentUser.IsAdministrator || app.currentUser.IsStandardPlus)){ //Standard User
            var prepareContentFields = this.props.grid.props.headers.map(function(field){return field.FieldName});
            var itemEditFields = quosal.customization.forms.ProductEdit.Configurations[quosal.settings.getValue('ProductEdit_FormConfiguration') || "Default"].Fields.map(function(field){return field.FieldName})
            var allStandardUserFields = prepareContentFields.concat(itemEditFields.filter(function (item) {
                return prepareContentFields.indexOf(item) < 0;
            }));
            standardFieldPickButtons = standardFieldPickButtons.where(function(f){return allStandardUserFields.indexOf(f.key) > -1});
            additionalFieldPickButtons = additionalFieldPickButtons.where(function(f){return allStandardUserFields.indexOf(f.key) > -1});;
        }

        let fieldListStyle = { maxHeight: '336px' };
        return (<div className='panel' style={{ boxShadow: 'none', border: '1px solid #a8a8a8' }}>
                    <div className='formcolumn' style={{ minHeight: 'initial', marginTop: '8px' }}>
                        <input ref={(r) => {this.filter = r;}} type='text' id='fieldfilter' placeholder='Search' onChange={this.filterChanged} />
                        <div id='resetfilter' className='icon close disabled' onClick={this.clearFilter} title='Reset filter' style={{marginLeft:'-12px', width:'12px', height:'12px', cursor:'pointer'}} />
                    </div>
                    <div className='fieldlist formcolumn newfieldlist' style={fieldListStyle}>
                        {visStandard == 0 && visAdditional == 0 ? <div className='newfieldGroupStyleNone' >No fields found</div>: null}
                        {visStandard > 0 ? 
                            <div id='massupdatefields_standard' className='newFieldGroupheader' >Common Fields</div> 
                            : null}
                        {standardFieldPickButtons}
                        {visAdditional > 0 ?
                            <div id='massupdatefields_additional' className='newFieldGroupheader' >Additional Fields</div>
                            : null}
                        {additionalFieldPickButtons}
                    </div>
                </div>);
    }

    renderInfoLine() {
        let infoline ='';
        let extrainfo = null;
        
        if (this.state.skipping > 0) {
            let totalcount = this.state.targetRows.length + this.state.skipping;
            let plural2 = totalcount != 1 ? 's' : '';
            infoline = this.state.targetRows.length + ' of ' + totalcount + ' line item' + plural2 + ' will be updated.';
            extrainfo = this.state.skipping + ' item(s) will be skipped because they are protected, comments, spacers, or section or bundle headers';
        }
        else {
            let plural1 = (this.state.targetRows.length) != 1 ? 's' : '';
            infoline = this.state.targetRows.length + ' line item' + plural1 + ' will be updated.';
        }

        let boxstyle = { 
            color:'#282828',
            border: '1px solid #eb7b18',
            backgroundColor: '#ffebdd',
            marginTop: '8px',
            padding: '2px',
            width: '100%'
        };
        let iconstyle = { 
            top:'-2px', 
            marginRight: '6px',
            filter: 'invert(48%) sepia(96%) saturate(1013%) hue-rotate(354deg) brightness(97%) contrast(89%)'
            //fill: '#eb7b18'
        };
        let textstyle = {
            top: '2px',
            position: 'relative'
        }
        return  <div style={boxstyle}>
                    <span className='icon xwarning' style={iconstyle}><img src='img/svgs/v1.0/Status_Warning.svg' /></span>
                    <span style={textstyle} title={extrainfo}>{infoline}</span>
                </div>;
    }

    renderButtons() {
        let submitClass = 'cwbtn right submit';
        if (!this.canUpdateItems()) {
            submitClass += ' disabled';
        }
        let cancelname = (this.state.targetRows.length == 0) ? 'Close' : 'Cancel';
        return (<div id='actionbuttons' style={{ textAlign: 'right', position: 'absolute', bottom: '-41px', right: '-18px' }}>
            <div id='cancelbutton' className='cwbtn right' onClick={Dialog.close.bind(null, null)}>{cancelname}</div>
            <div id='updatebutton' className={submitClass} onClick={this.handleUpdateButton}>Update</div>
        </div>);
    }

    renderUpdateValues() {
        if (this.state.fieldUpdates.length == 0) {
            return (<div className='formcolumn'>
                        <div className='zpanel' style={{ boxShadow: 'none', border: '1px solid #a8a8a8', minWidth:'288px', minHeight:'378px', textAlign:'center' }}>
                            <div style={{marginTop: '150px',color:'#7d7d7d'}}>
                            Select one or more<br />
                            fields to begin
                            </div>
                        </div>
                    </div>);
        }
        else {
            let blocks = [];
            for (let i=0; i < this.state.fieldUpdates.length;i++) {
                let one = this.createUpdateBlock(this, i, this.state.fieldUpdates[i]);
                blocks.push(one);
            }

            return (<div className='formcolumn' style={{width:'286px'}}>
                        <div className='listupdatevalues'>  
                            {blocks}
                        </div>
                        {this.state.fieldUpdates.length > 0 ? this.renderInfoLine() : null }
                    </div>);
        }
    }

    render() {
        return (
            <div style={{ display: 'flex', position: 'relative', flex: 1 }}>
                <div id='fieldselector' style={{ marginTop: '10px' }}>
                    {this.renderFieldList()}
                    {this.renderUpdateValues()}
                </div>
                {this.renderButtons()}
            </div>
        );
    }
}
MassUpdateFieldSelector.fieldBlacklist = {
    IsPhaseItem: true
};
global.MassUpdateFieldSelector = MassUpdateFieldSelector;

MassUpdateFieldSelector.fieldTypeToLabel = {
    String: 'Text',
    Int32: 'Integer',
    Double: 'Decimal',
    Boolean: 'Yes/No',
    DateTime: 'Date'
};

class MassUpdateFieldSelectorDatePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };       
        this.handleImageClick = this.handleImageClick.bind(this); 
        this.handleChange = this.handleChange.bind(this);
        
    }
    componentDidMount() {
        var that = this;
        $(this.input).datepicker({
            onSelect:function (datevalue, datepickerInstance) {
                var e = {};
                e.target = {};
                e.target.value = datevalue;
                e.target.dataset = {itemIndex: that.props.itemIndex};
                that.handleChange(e);
            }
        });
    }
    componentWillUnmount() {
        $(this.input).datepicker('destroy');
    }
    handleImageClick (e) {
        if (this.props.value && this.props.value.length > 0) {
            this.input.value = '';
            var e = {};
            e.target = {};
            e.target.value = '';
            e.target.dataset = {itemIndex: this.props.itemIndex};
            this.handleChange(e);
        }
        else {
            $(this.input).datepicker('show');
        }
    }
    handleChange(e) {
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    }

    render() {
        let icon = (this.props.value && this.props.value.length > 0) ? 'img/svgs/v1.0/Action_Close.svg': 'img/svgs/v1.0/Action_ExpandDown.svg';
        let imgstyle = (this.props.value && this.props.value.length > 0) ?
            {
                marginLeft:'-18px',
                width:'14px',
                height:'14px'
            }
            : {
                marginLeft:'-20px',
            };
        let inputStyle = {fontSize: '14px'}
        return (
            <div className='formfield' style={{maxWidth: '220px'}}>
                <input ref={(r) => {this.input = r;}} id={this.props.id} type='text' title='Date Value' maxLength='255' 
                    onChange={this.handleChange} onKeyPress={this.props.handleKey} value={this.props.value} 
                    data-item-index={this.props.itemIndex} style={inputStyle} />
                <img onClick={this.handleImageClick} className='fieldicon' src={icon} border='0' title={app.currentQuote.IsLocked || app.currentUser.IsReadOnly ? 'Date' : 'Edit Date'} 
                        style={imgstyle} />
            </div>
        );
    }
}
import { useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react"

export const QuoteHeaderNavigation = ({ quote, steps, isEoNav }) => {
    const navigationRef = useRef();
    const theme = useTheme();
    const [stepArray, setStepArray] = useState([]);

    useEffect(() => {
        quosal.navigation.parse(navigationRef.current);
    }, [])

    const calculateColor = (step, nextStep, isActive, subModuleActive, subnav) => {
        const nextStepActive = nextStep && nextStep.isLoaded?.() || (subnav?.activeStep == nextStep?.Name && nextStep?.Module == null);
        const nextStepSubModuleActive = nextStepActive && !nextStep?.isActive?.();
        const sx = {
            fontColor: step?.IsLocked ? theme.palette.quote_step.lockedFontColor : theme.palette.quote_step.fontColor,
            bgColor: step.IsLocked ? theme.palette.quote_step.lockedColor : theme.palette.quote_step.bgColor,
            endColor: theme.palette.quote_step.bgColor,
            lockedColor: theme.palette.quote_step.lockedColor,
            activeStepColor: isEoNav ? theme.palette.quote_step.isEoNav : theme.palette.quote_step.activeStepColor,
        };

        if (!nextStep || nextStep.IsLocked) {
            sx.endColor = theme.palette.quote_step.lockedColor;
        } else if (nextStepActive && !nextStepSubModuleActive) {
            sx.endColor = sx.activeStepColor;
        }
        else if ((subnav?.activeStep == nextStep?.Name) && (nextStep?.Name == "Publish Settings" || nextStep?.Name == "Terms and Recurring") && quote?.IsTemplate && !subnav?.subModuleName) { // Setting active color for next step's svg if quote is a template
            sx.endColor = sx.activeStepColor;
        }

        if (step?.IsLocked) {
            sx.bgColor = theme.palette.quote_step.lockedColor;
            sx.fontColor = theme.palette.quote_step.lockedFontColor;
        } else if (isActive && !subModuleActive && step?.Module != "quote.customer") {
            sx.bgColor = sx.activeStepColor;
            sx.fontColor = theme.palette.quote_step.bgColor
        }
        else if ((subnav?.activeStep == step?.Name) && (step?.Name == "Publish Settings" || step?.Name == "Terms and Recurring") && quote?.IsTemplate && !subnav?.subModuleName) {
            sx.bgColor = sx.activeStepColor;
            sx.fontColor = theme.palette.quote_step.bgColor
        }
        return sx;
    }

    const pickNav = (navString, copyTabs, copyItems, copyTerms) => {
        const quoteHome = quote.IsTemplate ? "Template Setup" : "quote.home";
        const quoteContent = quote.IsTemplate ? "Prepare Content" : "quote.content";
        switch (navString) {
            //  Quote Home navigation options
            case "quotehome":
                return { activeStep: quoteHome, subModuleName: "Quote Home" };
            case "quote.home":
                return { activeStep: quoteHome, subModuleName: "" };
            case "product.salestracksforms":
                return { activeStep: quoteHome, subModuleName: "Sales Track Forms" };
            case "publishsettings":
                return quote.IsTemplate ? { activeStep: "Publish Settings", subModuleName: "" } : { activeStep: "quote.home", subModuleName: "Publish Settings" };
            case "terms":
                return quote.IsTemplate ? { activeStep: "Terms and Recurring", subModuleName: "" } : { activeStep: "quote.home", subModuleName: "Terms and Recurring" };
            case "cwagreement.quosalweb":
                return { activeStep: quoteHome, subModuleName: "Agreements" };
            case "versionlist.quosalweb":
                return { activeStep: quoteHome, subModuleName: "Version List" };
            case "quotecomments.quosalweb":
                return { activeStep: quoteHome, subModuleName: "Comments" };
            case "cwconfiguration.quosalweb":
                return { activeStep: quoteHome, subModuleName: "Configurations" };
            case "quoteaudit.quosalweb":
                return { activeStep: quoteHome, subModuleName: "Audit Trail" };
            case "cwticket.quosalweb":
                return { activeStep: quoteHome, subModuleName: "Tickets" };
            //  Quote Customer navigation options
            case "customer":
                return { activeStep: "quote.customer", subModuleName: "Customer Setup" };
            case "quote.customerdetails":
                return { activeStep: "quote.customer", subModuleName: "Customer Details" };
            case "quote.customersearch":
                return { activeStep: "quote.customer", subModuleName: "Customer Search" };
            case "quote.attachopportunity":
                return { activeStep: "quote.customer", subModuleName: "Attach Opportunity" };
            case "crm.opportunity":
                return { activeStep: "quote.customer", subModuleName: "Opportunity Details" };
            //  Quote Content navigation options
            case "content":
                return { activeStep: quoteContent, subModuleName: "Prepare Content" };
            case "tab.edit":
                return { activeStep: quoteContent, subModuleName: "Tab Edit" };
            case "quote.copyitem":
                {

                    if (copyItems) return { activeStep: quoteContent, subModuleName: "Copy Items" };
                    else if (copyTabs) return { activeStep: quoteContent, subModuleName: "Copy Tabs" };

                }
            case "cloud.etilize":
                return { activeStep: quoteContent, subModuleName: "Etilize Search" };
            case "ingram.ingramQuoteSearch":
                return { activeStep: quoteContent, subModuleName: "Ingram Quote Search" };
            case "quote.picktemplate":
                return { activeStep: quoteContent, subModuleName: "Copy Template as New Version" };
            case "updatecosts.quosalweb":
                return { activeStep: quoteContent, subModuleName: "Update Costs" };
            case "previewpdf.quosalweb":
                return { activeStep: quoteContent, subModuleName: "Preview Quote" };
            case "pickcsv.quosalweb":
                return { activeStep: quoteContent, subModuleName: "Pick CSV Layout" };
            case "downloadexcel.quosalweb":
                return { activeStep: quoteContent, subModuleName: "Export Quote" };
            case "downloadcsv.quosalweb":
                return { activeStep: quoteContent, subModuleName: "CSV Exported" };
            case "arrow.arrowQuoteSearch":
                return { activeStep: quoteContent, subModuleName: "Arrow Quote Search" };
            case "importtechdataquote.quosalweb":
                return { activeStep: quoteContent, subModuleName: "Import Tech Data Quote" };
            case "importarrowquote.quosalweb":
                return { activeStep: quoteContent, subModuleName: "Import Arrow Quote" };
            case "arrow.arrowTermImport":
                return { activeStep: quoteContent, subModuleName: "Import Finanicing offers" };
            case "importgreatamerica.quosalweb":
            case "pickgreatamericacard.quosalweb":
            case "cardgreatamerica.quosalweb":
            case "previewgreatamerica.quosalweb":
                return { activeStep: quoteContent, subModuleName: "GreatAmerica" };
            //  Quote Review, approval, publish and win options navigation options
            case "review":
                return { activeStep: "quote.review", subModuleName: "Review" };
            case "approval":
                return { activeStep: "quote.approval", subModuleName: "Approval" };
            case "publish":
                return { activeStep: "quote.publish", subModuleName: quote?.IsTemplate ? "Publish Overview" : "Publish and Deliver" };
            case "quote.deliver":
                return { activeStep: "quote.publish", subModuleName: "Prepare Email" };
            case "win":
                return { activeStep: "Win!", subModuleName: "Win!" };
            case "openquote.quosalweb":
                if (copyTerms) return { activeStep: quoteContent, subModuleName: "Copy Terms" };
                else if (copyItems) return { activeStep: quoteContent, subModuleName: "Copy Items" };
                else if (copyTabs) return { activeStep: quoteContent, subModuleName: "Copy Tabs" };
                else return { activeStep: quoteHome, subModuleName: "" };
            case "copyterms.quosalweb":
                return { activeStep: quoteHome, subModuleName: "Copy Terms" };
            case "copytabs.quosalweb":
                return { activeStep: quoteContent, subModuleName: "Copy Tabs" }; //cross verify
            case "copyitems.quosalweb":
                return { activeStep: quoteContent, subModuleName: "Copy Items" };
            case "selectdellpdf.quosalweb":
            case "selectdellxml.quosalweb":
            case "selectdell.quosalweb":
                return { activeStep: quoteHome, subModuleName: "Paste Dell" };
            case "reviewdell.quosalweb":
            case "reviewdellxml.quosalweb":
            case "reviewdellpdf.quosalweb":
                return { activeStep: quoteHome, subModuleName: "Review Dell" };
            case "cwopportunitysearch.quosalweb":
                return { activeStep: quoteHome, subModuleName: "Attach Opportunity" };
            case "usersearch.quosalweb":
                return { activeStep: quoteHome, subModuleName: "User Search" };
            case "checkmanagerapproval.quosalweb":
                return { activeStep: quoteHome, subModuleName: "Check Manager Approval" };
            case "checkorderporter.quosalweb":
                return { activeStep: quoteHome, subModuleName: "Check Order Porter" };
            case "addtab.quosalweb":
                return { activeStep: quoteHome, subModuleName: "Add Tab" };
            case "sfopportunity.quosalweb":
                if (quote.IsTemplate)
                    return { activeStep: quoteHome, subModuleName: "Opportunity" };
                else return { activeStep: "quote.review", subModuleName: "Review" };
            case "sfaddoppitemsdecision.quosalweb":
                return { activeStep: quoteHome, subModuleName: "Add Opportunity Items" };
            case "sfopportunitysearch.quosalweb":
                return { activeStep: quoteContent, subModuleName: "Atatch Opportunity" };
            case "avatax.quosalweb":
                return { activeStep: quoteContent, subModuleName: "AvaTax" };
            case "importtdsynnexquote.quosalweb":
                return { activeStep: quoteContent, subModuleName: "TD Synnex Quote Search" };
            case "autotaskattachquote.quosalweb":
                return { activeStep: quoteHome, subModuleName: "Attach Quote" };
            case "autotaskopportunitysearch.quosalweb":
                return { activeStep: quoteHome, subModuleName: "Attach Opportunity" };
            case "autotaskopportunity.quosalweb":
                return { activeStep: quoteHome, subModuleName: "Opportunity" };
            case "importnotes.quosalweb":
                return { activeStep: quoteHome, subModuleName: "Import Notes" };
            case "importiquote.quosalweb":
                return { activeStep: quoteContent, subModuleName: "Import iQuote" };
            case "previewiquote.quosalweb":
                return { activeStep: quoteHome, subModuleName: "Preview iQuote" };
            case "netsuiteopportunity.quosalweb":
                return { activeStep: quoteHome, subModuleName: "Opportunity" };
            case "netsuiteopportunitysearch.quosalweb":
                return { activeStep: quoteHome, subModuleName: "Attach Opportunity" };
            case "nsaddoppitemsdecision.quosalweb":
                return { activeStep: quoteHome, subModuleName: "Add Opportunity Items" };
            case "customexportpreview.quosalweb":
                return { activeStep: quoteHome, subModuleName: "Custom Export Preview" };
            case "customexportresults.quosalweb":
                return { activeStep: quoteHome, subModuleName: "Custom Export Results" };
            case "cwmultisite.quosalweb":
                return { activeStep: quoteHome, subModuleName: "Connectwise Sites" };
            case "previewpunchoutimport.quosalweb":
                return { activeStep: quoteHome, subModuleName: "Review Quote" };
            case "dynamicsopportunitysearch.quosalweb":
                return { activeStep: quoteHome, subModuleName: "Attach Opportunity" };
            case "dynamicsopportunity2.quosalweb":
                return { activeStep: quoteHome, subModuleName: "Opportunity" };
            case "dynamicsopportunity.quosalweb":
                return { activeStep: quoteHome, subModuleName: "Opportunity" };
            case "dynamicsaddoppitemsdecision.quosalweb":
                return { activeStep: quoteHome, subModuleName: "Add Opportunity Items" };
            case "product.salestracks":
                return { activeStep: quoteContent, subModuleName: "Sales Tracks Search" };
            case "product.search":
                return { activeStep: quoteContent, subModuleName: "Product Search" };
            case "product.edit":
                return { activeStep: quoteContent, subModuleName: "Product Details" };
            case "tdsynnexquotelist.quosalweb":
                return { activeStep: quoteContent, subModuleName: "TD Synnex Quote List Preview" };
            case "convertopportunity":
                return { activeStep: "Convert Opportunity", subModuleName: "" };
            case "sourcing":
                return { activeStep: "Sourcing", subModuleName: "" };
            case "electronicorder":
                return { activeStep: "Electronic Order", subModuleName: "" };
            case "email":
                return { activeStep: "Electronic Order", subModuleName: "Prepare Email" }; 
            case "pdf":
                    return { activeStep: "Electronic Order", subModuleName: "" };            
            case "quicksourcing":
                return { activeStep: "Order and Source", subModuleName: "" };
            case "quickconvertopportunity":
                return { activeStep: "Quick Convert", subModuleName: "" };
            case "returntoquote":
                return { activeStep: "Return To Quote", subModuleName: "" };
            default:
                return { activeStep: quoteHome, subModuleName: "" };

        }
    }

    const createSteps = () => {
        var stepArr = []
        for (var i = 0; i < steps.length; i++) {
            const step = steps[i];
            const nextStep = i < steps.length - 1 ? steps[i + 1] : null;
            var activeSubModule = null;
            const subnav_param = new URLSearchParams(window.location.search).get('subnav') || new URLSearchParams(window.location.search).get('nav') || app?.currentModule?.getActiveSubModule?.()?.Url || app.currentModule.Url;
            const copyTabs = new URLSearchParams(window.location.search).get('copytabs');
            const copyItems = new URLSearchParams(window.location.search).get('copyitems');
            const copyTerms = new URLSearchParams(window.location.search).get('copyterms');
            const subnav = subnav_param ? pickNav?.(subnav_param, copyTabs, copyItems, copyTerms) : null;
            const isActive = step.isLoaded?.() || (subnav?.activeStep == step?.Name && step.Module == null);
            const subModuleActive = !step.isActive?.();

            if (isActive && subModuleActive) {
                activeSubModule = step.getActiveSubModule?.();
            }
            const sx = calculateColor(step, nextStep, isActive, subModuleActive, subnav);
            stepArr.push(<QuoteNavigationStep quote={quote} key={step.Name} step={step}
                isEoNav = {isEoNav}
                isEoOrderEmail = {quote?.IsTemplate ? subnav.activeStep == step.Name : subnav.activeStep == step.Name && subnav.activeStep == "Electronic Order" && subnav.subModuleName == "Prepare Email"}
                isParentModule={quote?.IsTemplate ? subnav.activeStep == step.Name : subnav.activeStep == step.Url}
                sx={sx}
                isActive={isActive && !subModuleActive}
                subnav={subnav?.subModuleName} subModuleName={activeSubModule ? activeSubModule.Name : null} />);
        }
        setStepArray(stepArr);
    }

    useEffect(() => {
        createSteps();
    }, [isEoNav, steps, quote, theme])

    return (
        <div ref={navigationRef} id="quotenav" style={{
            backgroundColor: theme.palette.quote_step.lockedColor,
        }}>
            <div style={{ display: 'inline-block' }}>
                {stepArray}
            </div>
        </div>
    );
}

const QuoteNavigationStep = ({ ...props }) => {

    const navigateToStep = () => {
        if (!props.step.IsLocked) {
            if (props.step.Url.indexOf('.quosalweb') >= 0) {
                quosal.navigation.navigate(props.step.Url);
            } else {
                if (app.currentModule.Type !== 'QuoteDashboard') {
                    const href = quosal.util.url('quote.dashboard', `submodules=${props.step.Url}`, 'idquotemain=' + props.quote.IdQuoteMain)
                    const a = document.createElement('a');
                    a.href = href
                    document.body.appendChild(a);
                    a.click();
                } else {
                    app.currentModule.loadSubModule(props.step.Url, {
                        container: 'quoteModule',
                        unloadSubModules: true,
                        query: 'idquotemain=' + props.quote.IdQuoteMain + `&submodules=${props.step.Url}`
                    });
                }
            }
        }
    }

    return (
        <div data-cy={props.step.IsLocked ? "locked" : "unlocked"} style={{ display: 'inline-block' }} data-testid="test-step" >
            <div className="quotenav_item" style={{ backgroundColor: props.sx.bgColor }}>
                <div className="quotenav_name">
                    <a style={{ verticalAlign: 'middle' }} className="link" onClick={navigateToStep} data-testid="test-navigate">
                        <font data-testid="test-font-step-name" style={{ fontSize: '16px', fontWeight: props.isActive ? 'bold' : 'normal', color: props.sx.fontColor }}>&nbsp;{props.step.Name}</font>
                    </a>
                </div>
                {props.subnav && (props?.isParentModule || props?.isEoOrderEmail) ?
                    <div className="subnav">
                        <div className={props?.isEoNav ? "icon sub-nav-eo" : "icon sub-nav"}></div>
                        <div data-testid="subnav-test" className={props?.isEoNav ?"subnavitem current eo" : "subnavitem current"}>{props.subnav}</div>
                    </div> : null}
            </div>
            <div className="quotenav_cap" style={{ position: 'relative', backgroundColor: props.sx.endColor }}>
                <svg width="20" height="40">
                    <g>
                        <path data-testid="test-path" id="svg_6" d="m19.89329,19.89329l-19.89329,19.89328l-0.00001,-39.78657l19.89329,19.89329z" stroke="#CCC" strokeWidth="1" fill={props.sx.bgColor} />
                        <line  data-testid="test-line" fill={props.sx.bgColor} stroke={props.sx.bgColor} strokeWidth="2" strokeLinejoin="null" x1="0" y1="1" x2="0" y2="39" id="svg_3" />
                    </g>
                </svg>
            </div>
        </div>
    )
}


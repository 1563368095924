import { Form } from '@formio/react';
import { api } from "../common/guidedSelling/api/api.js";
import { CommonBreadCrumbs } from '../common/guidedSelling/components/commonBreadCrumbs.jsx';
import { CommonButton } from '../common/guidedSelling/styledComponents/commonButton.js';
import moment from 'moment/moment.js';

const SELECT = "select";
const RADIO = "radio";
const NUMERIC = "number";
const TEXT = "text";
const DATE = "date";
const CHECKBOX = "checkbox";
const ID_SEPARATOR = "--";
const DATE_FORMAT = "MM-DD-yyyy";
const DISPLAY_DATE_FORMAT = "MM-dd-yyyy";

export class SalesTrackFormPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            salesTrackSelected: this.props.salesTrackSelected,
            attachProductParams: {
                idQuoteItems: "",
                idQuoteMain: "",
                idQuoteTabs: ""
            },
            isDirty: false,
            isLoading: false,
            form: null,
        };
        const mappedSalesTrackForm = this.mapFromToHideDynamicQuestions(this.props.salesTrackForm);
        this.questionaryData = this.handleDataFormatter(mappedSalesTrackForm);
        //Bind this
        this.onSubmitHandler = this.onSubmitHandler.bind(this);
        this.handleDataFormatter = this.handleDataFormatter.bind(this);
        this.attachSalesTrackMappedProducts = this.attachSalesTrackMappedProducts.bind(this);
        this.mapFromToHideDynamicQuestions = this.mapFromToHideDynamicQuestions.bind(this);
        this.computeConditionalJsonLogic = this.computeConditionalJsonLogic.bind(this);
    }
    
    componentDidMount() {
        this.componentIsMounted = true;
    }

    componentWillUnmount() {
        this.componentIsMounted = false;
    }

    /**
    * Format the data to be used by form.io to create the form.
    *
    * @param {Array} dataToFormat - The data to format, containing questions with options and its structure must be: 
    * 
    * [{
    *        question: 'Question label',
    *        answers: [
    *            {
    *                answer: "value of the option"
    *            },
    *        ],
    *  },]
    * 
    * @returns {Object} The formatted data object for the form.
    */
    handleDataFormatter = function (dataToFormat) {
       if (!dataToFormat || dataToFormat.length === 0) {
         return;
       }
       
       const { readOnly } = this.props;
       const mapFormItem = (formItem) => {
           switch (formItem.answerDataType) {
               case SELECT: return mapFormSelect(formItem);
               case RADIO: return mapFormRadio(formItem);
               case NUMERIC: return mapFormNumeric(formItem);
               case TEXT: return mapFormText(formItem);
               case DATE: return mapFormDate(formItem);
               case CHECKBOX: return mapFormCheckbox(formItem);
            }
        };
        const mapAnswers = ({ answerId, answer }) => ({
            value: answerId,
            label: answer,
        });
        const getTooltip = ({ tooltip }) => tooltip ? `<div class="label-info"><span class="tooltiptext">${tooltip}</span></div>` : '';
        const getLabel = ({ question, tooltip }) => `<div class="field-label"><div class="label-text">${question}</div>${getTooltip({ tooltip })}</div>`;
        const getCustomClass = ({ isActive, isRequired, isHidden }) => `${isRequired && isActive && 'salestrackform-control-required' || ''} ${!isActive && 'salestrackform-control-disabled' || ''} ${isHidden && 'salestrackform-control-hidden' || ''}`;
        const getSelectedValues = (answers) => answers.filter(({ isSelected }) => isSelected);
        const getSingleDefaultValue = (answers) => getSelectedValues(answers)[0]?.answerId;
        const getMultipleDefaultValues = (answers) => getSelectedValues(answers).reduce((accum, { answerId, isSelected }) =>({ ...accum,  [answerId]: isSelected }), {});
        const mapFormSelect = ({ questionId, question, isActive, isRequired, tooltip, answers, conditional }) => ({
            label: getLabel({ question, tooltip }),
            widget: 'html5',
            customClass: `salestrackform-select ${getCustomClass({ isActive, isRequired })}`,
            tabindex: '',
            tableView: false,
            placeholder: 'Select an option',
            data: {
                values: answers.map(mapAnswers),
            },
            key: questionId,
            type: 'select',
            disabled: readOnly || !isActive,
            validate: {
                required: isRequired && isActive,
            },
            input: true,
            defaultValue: getSingleDefaultValue(answers) || "",
            conditional: conditional,
        });
        const mapFormRadio = ({ questionId, question, isActive, isRequired, tooltip, answers, conditional}) => ({
            label: getLabel({ question, tooltip }),
            widget: 'html5',
            customClass: `salestrackform-radio ${getCustomClass({ isActive, isRequired})}`,
            tabindex: '',
            tableView: false,
            values: answers.map(mapAnswers),
            inline: true,
            optionsLabelPosition: 'right',
            key: questionId,
            type: 'radio',
            validate: {
                required: isRequired && isActive,
            },
            disabled: readOnly || !isActive,
            input: true,
            defaultValue: getSingleDefaultValue(answers) || "",
            conditional: conditional
        });
        const mapFormNumeric = ({ questionId, question, isActive, isRequired, tooltip, minValue, maxValue, answer, conditional }) => ({
            label: getLabel({ question, tooltip }),
            widget: 'html5',
            customClass: `salestrackform-numeric ${getCustomClass({ isActive, isRequired })}`,
            tabindex: '',
            tableView: false,
            key: questionId,
            type: 'number',
            mask: false,
            delimiter: false,
            requireDecimal: false,
            attributes: {
                min: minValue,
                max: maxValue,
            },
            disabled: readOnly || !isActive,
            validate: {
                required: isRequired && isActive,
                min: minValue,
                max: maxValue,
                integer: true,
            },
            input: true,
            tableView: true,
            defaultValue: answer || "",
            conditional: conditional
        });
        const mapFormText = ({ questionId, question, isActive, isRequired, tooltip, answer, conditional }) => ({
            label: getLabel({ question, tooltip }),
            widget: 'html5',
            customClass: `salestrackform-textfield ${getCustomClass({ isActive, isRequired })}`,
            tabindex: '',
            tableView: false,
            key: questionId,
            type: 'textarea',
            autoExpand: true,
            rows: 1,
            validate: {
                required: isRequired && isActive,
            },
            attributes: {
                ...((readOnly || !isActive) && {disabled: true}),
            },
            input: true,
            defaultValue: answer || "",
            conditional: conditional
        });
        const mapFormDate = ({ questionId, question, isActive, isRequired, tooltip, fromDate, toDate, answer, conditional }) => ({
            label: getLabel({ question, tooltip }),
            customClass: `salestrackform-date ${getCustomClass({ isActive, isRequired })}`,
            tableView: false,
            key: questionId,
            allowInput: false,
            enableMinDateInput: false,
            enableMaxDateInput: false,
            enableTime: false,
            format: DISPLAY_DATE_FORMAT,
            "datePicker": {
                minDate: fromDate,
                maxDate: toDate,
            },
            type: "datetime",
            input: true,
            attributes: {
                ...((readOnly || !isActive) && {disabled: true}),
                "data-datefrom": fromDate && moment(fromDate).format(DATE_FORMAT),
                "data-dateto": toDate && moment(toDate).format(DATE_FORMAT),
            },
            validate: {
                required: isRequired && isActive,
            },
            defaultValue: answer || "",
            conditional: conditional
        });
        const mapFormCheckbox = ({ questionId, question, isActive, isRequired, tooltip, answers, minOptions, conditional }) => ({
            label: getLabel({ question, tooltip }),
            widget: 'html5',
            customClass: `salestrackform-checkbox ${getCustomClass({ isActive, isRequired })}`,
            tabindex: '',
            tableView: false,
            values: answers.map(mapAnswers),
            inline: true,
            optionsLabelPosition: 'right',
            key: questionId,
            type: 'selectboxes',
            validate: {
                required: isRequired && isActive,
                minSelectedCount: isRequired ? minOptions : 0,
            },
            attributes: {
                "data-minoptions": minOptions,
            },
            minSelectedCountMessage: `In order to submit the form please select at least ${minOptions} option${minOptions > 1 ? "s" : ""}`,
            disabled: readOnly || !isActive,
            input: true,
            defaultValue: getMultipleDefaultValues(answers),
            conditional: conditional
        });
        const mapFormSections = (({ name, questions }, sectionIndex) => ({
            type: 'fieldset',
            legend: name,
            input: false,
            tableView: false,
            components: questions.map((item, index) => mapFormItem(item, index, sectionIndex)),
        }));
        const sections = dataToFormat.map(mapFormSections);
        const dataFormatted = {
            display: 'form',
            type: 'form',
            showValidations: true,
            components: sections,
        };

        return dataFormatted;
    }

    attachSalesTrackMappedProducts = function (attachProductParams) {
        const { onError } = this.props;
        api.attachSalesTrackMappedProducts(attachProductParams, (msg) => {
            quosal.sell.quote.update(msg.quote);
        },(msg) => {
            onError('Error', msg.error);
        });
    }

    computeConditionalJsonLogic = function (conditions_array) {
        let jsonLogic = { "json": {
            "or": []
        } };
        conditions_array.forEach((condition, index) => {
            jsonLogic.json.or.push({ "==": [{ "var": `data.${condition.parentQuestionId}` }, condition.parentAnswerId] });
        });
        return jsonLogic;
    }

    mapFromToHideDynamicQuestions = function(form_response) {
        let filterDynamicQuestions = {};
        form_response?.forEach((section) => {
             section?.questions?.forEach((question) => {
                if (question?.isDynamicQuestion && question?.dynamicQuesConditions?.length) {
                    question?.dynamicQuesConditions?.forEach((condition) => {
                        const answerId = question?.answers?.find(answer => answer?.answer === condition?.answer)?.answerId;
                        let current_dynamic_question = {...condition, parentAnswerId: answerId, parentQuestionId: question?.questionId};
                        if(filterDynamicQuestions[condition?.questionId]) {
                            filterDynamicQuestions[condition?.questionId].push(current_dynamic_question);
                        }
                        else{
                            filterDynamicQuestions[condition?.questionId] = [current_dynamic_question];
                        }
                    });
                }
            })
        });
        let updatedSalesTrackForm = form_response?.map((section) => {
            const section_mapped = section?.questions?.map((question) => {
                if (filterDynamicQuestions[question.questionId]) {
                    const conditional = this.computeConditionalJsonLogic(filterDynamicQuestions[question.questionId]);
                    return { ...question, conditional };
                } else {
                    return question;
                }
            })
            return { ...section, questions: section_mapped }
        })
        return updatedSalesTrackForm;
    }

    onSubmitHandler = function ({ data }) {
        const { salesTrackForm, salesTrackSelected } = this.props;
        const idSalesTracks = salesTrackSelected.id;
        const getAnswerFromSelect = (questionId) => {
            const select = document.querySelector(`select[name*="${questionId}"]`);
            const option = select ? select.options[select.selectedIndex] : null;
            return option && !!option.value ? option.text : "";
        };
        const getAnswerFromRadio = (questionId) => {
            const selectedRadio = document.querySelector(`input[name*="${questionId}"]:checked`);
            return selectedRadio ? selectedRadio.nextSibling.nextSibling.innerText : '';
        };
        const getAnswersFromCheckbox = (questionId) => {
            const selectedCheckboxes = document.querySelectorAll(`input[name*="${questionId}"]:checked`);
            return [...selectedCheckboxes].map(({ id, nextSibling }) => ({
                answerId: id && id.includes(ID_SEPARATOR) && id.split(ID_SEPARATOR)[1] || null,
                answer: nextSibling.nextSibling.innerText,
            }));
        }
        const getAnswerFromInput = (questionId) => (data[questionId] || "") + "";
        const getAnswerFromDate = (questionId) => {
            const date = getAnswerFromInput(questionId);
            return date ? new Date(date).toISOString() : "";
        };
        const answerGetter = (answerDataType, questionId) => {
            switch(answerDataType) {
                case SELECT: return getAnswerFromSelect(questionId);
                case RADIO: return getAnswerFromRadio(questionId);
                case DATE: return getAnswerFromDate(questionId);
                case NUMERIC:
                case TEXT: return getAnswerFromInput(questionId);
            }
        };
        const getQuestionData = (({ questionId, answerDataType }) => ({
            questionId: questionId,
            answers: answerDataType === CHECKBOX ? getAnswersFromCheckbox(questionId) : [{
                answerId: answerDataType !== NUMERIC && answerDataType !== TEXT && answerDataType !== DATE ? data[questionId] : "",
                answer: answerGetter(answerDataType, questionId),
            }],
        }))
        const questions = salesTrackForm.flatMap((section) => section.questions.map(getQuestionData));
        if (this.state.isLoading) {
            return;
        }

        this.setState({ ...this.state, isLoading: true });
        const params = (new URL(document.location)).searchParams;
        const idQuote = params.get("idquotemain") || "0";
        const getCurrentTab = () => {
            const idQuoteTabs = quosal.util.queryString('idquotetabs');
            return idQuoteTabs ? app.currentQuote.Tabs.where(t => t.id == idQuoteTabs).firstOrNull() : null;
        }
        const currentTab = getCurrentTab();
        const idQuoteTabs = currentTab ? currentTab.IdQuoteTabs : "";
        const submitData = {
            idSalesTracks,
            questions,
            idQuote,
            idQuoteTabs,
        };
        const openQuote = (idQuote) => {
            const openQuoteApi = quosal.api.quote.openQuote(idQuote);
            openQuoteApi.finished = function (msg) {
                quosal.sell.quote.update(msg.quote);
            };
            openQuoteApi.call();
        }
        api.postSalesTrackCompletedForm(submitData, () => {
            const snackbar = document.getElementById("snackbar");

            snackbar.className = "show";
            this.setState({ ...this.state, isDirty: false });
            setTimeout(() => { 
                snackbar.className = snackbar.className.replace("show", "");                
                app.currentModule.loadSubModule('quote.content', {
                    container: 'quoteModule',
                    unloadSubModules: true
                });
                openQuote(idQuote);
            }, 2000);
        }, (msg) => {
            const { onError } = this.props;
            onError && onError('Error', msg.error);
            this.setState({ ...this.state, isLoading: false });
        });
    }

    render() {
        const { salesTrackSelected, salesTrackForm, onBack, readOnly } = this.props;
        const { isDirty } = this.state;
        const breadCrumbsData = {
            actualBreadCrumbName: salesTrackSelected.name,
            breadCrumbs: [
                {
                    name: 'Sales Tracks',
                    navigate: onBack,
                }
            ],
        }
        const discardChanges = () => {
            const { form } = this.state;
            form && form.setSubmission({}) && form.redraw();
        }
        const onCancel = () => Dialog.open({
            title:'Discard Changes?',
            closeButton: true,
            message: 'Are you sure you want to discard changes made to the form?',
            closeRequiresButton: true,
            links: [{
                title: <span className='salestrack-btn-cancel'>Cancel</span>,
                callback: () => Dialog.closeAll()
            }, {
                title: <span className='salestrack-btn-discard'>Discard</span>,
                callback: () => {
                    discardChanges();
                    Dialog.closeAll();
                }
            }]
        });
        const onChange = (changed, flags, modified) => {

            modified && !this.state.isDirty && this.setState({ ...this.state, isDirty: true });
        }
        const onReady = (form) => !this.state.form && this.setState({ ...this.state, form });
        const onSave = () => {
            const { form } = this.state;
            if (form && form.checkValidity(null, true, null, false)) {
                form.submit();
            }
        };
        const onRender = () => {
            const numberDivs = document.querySelectorAll('.salestrackform-numeric div[ref="element"]');
            const dateDivs = document.querySelectorAll('.salestrackform-date div[ref="element"]');
            const checkboxDivs = document.querySelectorAll('.salestrackform-checkbox div[ref="radioGroup"]');

            numberDivs.forEach((div) => {
                const input = div.childNodes[1];
                const parent = div.parentNode;
                const container = document.createElement("div");
                const btnUp = document.createElement("button");
                const btnDn = document.createElement("button");
                const bottomLabel = document.createElement("label");
                const [a, questionId] = input.id.split('-');
                btnUp.id = 'btnUp';
                btnUp.disabled = true;
                btnUp.onclick = () => {
                    const value = `${(parseInt(input.value) || 0) + 1}`;
                    this.state.form.getComponent(questionId).setValue(value);
                };
                btnDn.id = 'btnDn';
                btnDn.disabled = true;
                btnDn.onclick = () => {
                    const value = `${(parseInt(input.value) || 0) - 1}`;
                    this.state.form.getComponent(questionId).setValue(value);
                };
                input.onchange = () => {
                    btnUp.disabled = false;
                    btnDn.disabled = false;    
                }
                container.classList.add('salestrackform-numeric-stepper-container');
                container.appendChild(btnUp);
                container.appendChild(btnDn);
                div.appendChild(container);
                // Add custom label at the bottom.
                bottomLabel.classList.add('salestrackform-numeric-bottom-label');
                bottomLabel.appendChild(document.createTextNode(`Please enter a value between ${input.min} and ${input.max}`));
                parent.appendChild(bottomLabel);
            })
            dateDivs.forEach((div) => {
                const parent = div.parentNode;
                const input = div.querySelector("input.form-control.flatpickr-input");
                const bottomLabel = document.createElement("label");
                const dateFrom = input.dataset["datefrom"];
                const dateTo = input.dataset["dateto"];
                // Add custom label at the bottom.
                bottomLabel.classList.add('salestrackform-date-bottom-label');
                bottomLabel.appendChild(document.createTextNode(`Please select a date ${(() => {
                    if (dateFrom && dateTo) {
                        return `starting from ${dateFrom} to ${dateTo}`;
                    } else if (dateFrom && !dateTo) {
                        return `starting from ${dateFrom}`;
                    } else if (!dateFrom && dateTo) {
                        return `until ${dateTo}`;
                    } else return "";
                })()}`));
                parent.appendChild(bottomLabel);
            });
            checkboxDivs.forEach((div) => {
                const parent = div.parentNode;
                const input = div.querySelector("input.form-check-input");
                const minOptions = input.dataset["minoptions"];
                
                if (minOptions > 0) {
                    const bottomLabel = document.createElement("label");
                    // Add custom label at the bottom.
                    bottomLabel.classList.add('salestrackform-checkbox-bottom-label');
                    bottomLabel.appendChild(document.createTextNode(`Please select minimum ${minOptions} option${minOptions > 1 ? "s" : ""}`));
                    parent.appendChild(bottomLabel);
                }
            });
        }

        return (
            <div className="salestracksearch-form-content">
                {readOnly ? <div>
                    <div className="salestracksearch-form-buttons">
                        <CommonButton variant="outlined" onClick={onBack}>Back</CommonButton>
                    </div>
                </div> : <div>
                    <CommonBreadCrumbs {...breadCrumbsData} />
                    <div className="salestracksearch-form-buttons">
                        <CommonButton variant="outlined" disabled={!isDirty} onClick={onCancel}>Cancel</CommonButton>
                        <CommonButton variant="contained" disabled={!isDirty} onClick={onSave} >Submit</CommonButton>
                    </div>
                </div>}
                <div id="formio" className="salestrackserach-form-main-panel">
                    <h2 data-testid={salesTrackSelected?.state ? "test-track-name-active" : "test-track-name-inactive"} >{salesTrackSelected.name} {salesTrackSelected?.state ? "" : "(Inactive)"}</h2>
                    {this.questionaryData ? 
                        <Form form={this.questionaryData} onSubmit={this.onSubmitHandler} onChange={onChange} formReady={onReady} onRender={onRender} options={{
                            readOnly:!salesTrackSelected.state,
                            i18n: {
                                en: {
                                    required: "In order to submit the form please fill the required field",
                                    min: "Please enter a value not less than {{min}}",
                                    max: "Please enter a value not greater than {{max}}",
                                }
                            },
                        }} /> : 
                        <span className="salestracksearch-no-form-to-display"/>
                    }
                </div>
                <div id="snackbar">
                    <div className="icon-success"></div>
                    <div className="content">
                        <div className="header">Success</div>
                        <div className="message">Form submitted successfully</div>
                    </div>
                </div>
            </div>
        );
    }
}
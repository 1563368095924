import { useGridApiContext } from "@mui/x-data-grid-pro";
import { EditSelectStyled } from "../../styledComponents/mapTableStyled";
import { commomSelectStyles } from "./common";
import { MenuItem } from "@mui/material";

export const EditOperationCell = ({ id, value, field }) => {
	const apiRef = useGridApiContext();
	const handleChange = async (event) => {
		await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
		apiRef.current.stopCellEditMode({ id, field });
	};
	return (
		<EditSelectStyled
			value={value}
			onChange={handleChange}
			size="small"
			sx={{ ...commomSelectStyles }}
			id={"operation"}
			MenuProps={{ disableScrollLock: true }}
			data-testid="select-operation"
		>
			<MenuItem key={`option-${field}-${value}-${id}`} value={"equals"}>
				Equals
			</MenuItem>
		</EditSelectStyled>
	);
};

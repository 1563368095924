import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledTypographyBold = styled(Typography)(({ theme,sx }) => ({
    color: theme.palette.text.showMore,
    fontWeight:600,
	lineHeight: "16px",
    letterSpacing: ".32px",
    fontSize:"12px",
    ...sx
}));

export { StyledTypographyBold };

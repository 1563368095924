import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { getCKEditorTabsIds } from "./quote/newUI/helpers/getCKEditorTabsIds.js";

export class EditableImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };

        // This binding is necessary to make `this` work in the callback
        this.showEditor = this.showEditor.bind(this);
    }
    showEditor() {
        var editor = <ImageEditor {...this.props} parent={this} />;
        Dialog.open({
            title:'Edit Image', height:'620px', width:'760px', resizable: true, draggable: true, onClose: Dialog.close,
            links:[{title: 'Cancel', callback: Dialog.close}],
            message: editor
        });
    }
    componentDidMount() {
        $(this.refs.imgWrapper).hover(
            function () {
                if (!this.props.disabled)
                {
                    $(this.refs.imgOverlay).show();
                }
            }.bind(this),
            function () {
                $(this.refs.imgOverlay).hide();
            }.bind(this)
        );
    }
    render() {
        var additionalStyle = {};
        var hardWidthAndHeight = {};
        if(this.props.sizeInPixels) {
            additionalStyle.maxWidth = additionalStyle.maxHeight = this.props.sizeInPixels;
            hardWidthAndHeight.width = hardWidthAndHeight.height = this.props.sizeInPixels;
        }

        return (
            <div ref="imgWrapper" className={'imgWrapper' + (this.props.disabled ? ' disabled' : '')} onClick={this.props.disabled ? null : this.showEditor} style={additionalStyle}>
                <div className={'thumbnail ' + this.props.size + (this.props.disabled ? '' : ' editable' + ' norescale')} style={hardWidthAndHeight}>
                    <img src={this.props.src} style={additionalStyle} />
                    <img ref="imgOverlay" className="imgOverlay" src="img/svgs/v1.0/Action_Edit.svg" />
                </div>
            </div>
        );
    }
}

export class ImageEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imageUrl:this.props.src,
            isWorking: false,
            x: 0,
            y: 0,
            x2: 0,
            y2: 0,
            w: 0,
            h: 0,
            isLoading: false
        };

        // This binding is necessary to make `this` work in the callback
        this.initializeCropper = this.initializeCropper.bind(this);
        this.fullCropperReload = this.fullCropperReload.bind(this);
        this.selectWholeImage = this.selectWholeImage.bind(this);
        this.updateCoordinates = this.updateCoordinates.bind(this);
        this.saveCroppedImage = this.saveCroppedImage.bind(this);
        this.deleteImage = this.deleteImage.bind(this);
        this.clearImage = this.clearImage.bind(this);
        this.uploadImage = this.uploadImage.bind(this);
        this.imageUrlFromInternetKeyUp = this.imageUrlFromInternetKeyUp.bind(this);
        this.imageUrlFromInternetPaste = this.imageUrlFromInternetPaste.bind(this);
        this.imageUrlFromInternetChange = this.imageUrlFromInternetChange.bind(this);
        this.useInternetImage = this.useInternetImage.bind(this);
        this.imageSrcError = this.imageSrcError.bind(this);
        this.useOriginalImage = this.useOriginalImage.bind(this);
        this.useOriginalImageHard = this.useOriginalImageHard.bind(this);
        this.fixAspectRatioChange = this.fixAspectRatioChange.bind(this);
        this.insertCKEditor = this.insertCKEditor.bind(this);
        this.ids = [];
    }
    componentDidMount() {
        this.initializeCropper();
        if (quosal.util.isNewEditorEnabled()) {
            this.updateIds();
        }
    }
    componentDidUpdate(prevProps) {
        if (quosal.util.isNewEditorEnabled()) {
            if (prevProps.contentGrid && this.props.contentGrid) {
                if (prevProps.contentGrid.props.quote.HTMLContentForAutoSaving !== this.props.contentGrid.props.quote.HTMLContentForAutoSaving) {
                    this.updateIds();
                }
            }
        }
    }
    updateIds() {
        if (this.props.contentGrid) {
            const ids = getCKEditorTabsIds(this.props.contentGrid.props.quote.HTMLContentForAutoSaving);
            this.setState({ ids: ids });
        }
    }
    initializeCropper() {
        var imageEditor = this;
        var aspectRatio = this.state.overrideAspectRatio || null;

        $(this.refs.resizer).Jcrop(
            {
                onChange: this.updateCoordinates,
                onSelect: this.updateCoordinates,
                onRelease: this.selectWholeImage,
                aspectRatio: aspectRatio,
                setSelect: [this.state.x, this.state.y, this.state.x2, this.state.y2]
            },
            // ready callback; only gets called once
            function () {
                var bounds = this.getBounds();
                imageEditor.setState(
                    {
                        wOriginal: bounds[0],
                        hOriginal: bounds[1],
                        scale: bounds[0] / imageEditor.refs.resizer?.naturalWidth,
                        jCropApi: this
                    }
                );
                this.setSelect([0, 0, bounds[0], bounds[1]]);
            }
        );
    }
    fullCropperReload() {
        if (this.state.jCropApi) {
            this.state.jCropApi.destroy();
            this.initializeCropper();
        }
    }
    selectWholeImage() {
        this.setState({
            x: 0,
            x2: this.state.wOriginal,
            y: 0,
            y2: this.state.hOriginal,
            w: this.state.wOriginal,
            h: this.state.hOriginal
        });
    }
    updateCoordinates(c) {
        this.setState({
            x: c.x,
            x2: c.x2,
            y: c.y,
            y2: c.y2,
            w: c.w,
            h: c.h
        });
    }
    insertCKEditor() {
        const me = this;
        setTimeout(() => {
            if (me.state.ids !== null && me.state.ids.includes(me.props.IdQuoteTabs)) {
                me.props.ckeditor?.execute?.('updateProduct', me.props.IdQuoteTabs);
            }
        }, 50);
    }
    saveCroppedImage() {
        var saveImageParams = {
            'x1': this.state.x,
            'x2': this.state.x2,
            'y1': this.state.y,
            'y2': this.state.y2,
            'w': this.state.w,
            'h': this.state.h,
            'scale': this.state.scale,
            'wOriginal': this.state.wOriginal,
            'hOriginal': this.state.hOriginal,
            'norescale': this.props.norescale,
            'imageId': this.props.imageId,
            'originalSource': this.props.src,
            'newlyUploadedImage': this.state.newlyUploadedImage,
            'imageUrlFromInternet': this.state.imageUrlFromInternet,
            //Need of these is conditional; they may be undefined
            'IdQuoteMain': this.props.IdQuoteMain,
            'IdQuoteTabs': this.props.IdQuoteTabs,
            'IdQuoteItems': this.props.IdQuoteItems
        };
        var setChange = this.props.setChange;
        var me = this;
        var imageSaveApi = quosal.api.image.saveImage(saveImageParams);
        this.setState({...this.state, isLoading: true });
        imageSaveApi.finished = function (msg) {
            if (msg.quote) {
                quosal.sell.quote.update(msg.quote);
            }
            if (msg.error) {
                me.setState({isWorking: false});
                alert(msg.error);                                
            }
            if (!msg.error && me.props.afterSaveCallback) {
                me.props.afterSaveCallback(msg.imageUrl);
            }
            if (!(msg.error && (msg.error.includes('Unable to import image from provided URL') || msg.error.includes('The file needs to be less than')))) {            
                Dialog.close();
                if (quosal.util.isNewEditorEnabled() && typeof setChange === 'function') {
                    setChange();
                    this.insertCKEditor();
                }
            }
            if (quosal.util.isNewEditorEnabled() && typeof setChange === 'function') {
                setChange();
                this.insertCKEditor();
            }
            this.setState({...this.state, isLoading: false });
        }.bind(this);

        this.setState({isWorking: true});
        imageSaveApi.call();
    }
    deleteImage() {
        var me = this;
        var deleteImageParams = {
            'imageId': this.props.imageId,
            'originalSource': this.props.src,
            //Need of these is conditional; they may be undefined
            'IdQuoteMain': this.props.IdQuoteMain,
            'IdQuoteTabs': this.props.IdQuoteTabs,
            'IdQuoteItems': this.props.IdQuoteItems
        };

        var setChange = this.props.setChange;

        var deleteSaveApi = quosal.api.image.deleteImage(deleteImageParams);
        deleteSaveApi.finished = function (msg) {
            Dialog.closeAll();
            if (msg.quote) {
                quosal.sell.quote.update(msg.quote);
            }
            if(me.props.afterDeleteCallback){
                me.props.afterDeleteCallback();
            }
            if (quosal.util.isNewEditorEnabled() && typeof setChange === 'function') {
                setChange();
                this.insertCKEditor();
            }
        }.bind(this);

        Dialog.confirmDelete({
            callback: function () {
                deleteSaveApi.call();
            },
            message: 'Are you sure you want to delete this image?'
        });
    }
    clearImage() {
        this.setState({
            imageUrl: null,
            newlyUploadedImage: null,
            imageUrlFromInternet: null
        });
    }
    uploadImage() {
        var formData = new FormData();
        formData.append('file', this.refs.fileUploader.files[0]);
        this.setState({isWorking: true});

        if (!this.refs.fileUploader.files[0].type.includes('image')) {
            this.refs.fileUploader.value = '';
            this.setState({isWorking: false});
            alert('The file provided was not a valid image file.');
        }
        else if ((this.refs.fileUploader.files[0].size / 1048576) > app.settings.global.MaxMBSizeForImageUpload) {
            this.refs.fileUploader.value = '';
            this.setState({isWorking: false});
            alert('The file needs to be less than ' + app.settings.global.MaxMBSizeForImageUpload + 'MB.');
        }
        else {
            $.ajax('uploadtemporaryimage.quosalweb' + location.search + '&noheader=yes', {
                type: 'post',
                data: formData,
                dataType: 'json',
                processData: false,
                contentType: 'multipart/form-data',
                mimeType: 'multipart/form-data',
                success: function (data) {
                    if (data.tempImage)
                    {
                        this.setState(
                            {
                                imageUrl: data.tempImage,
                                newlyUploadedImage: data.tempImage,
                                imageUrlFromInternet: null,
                                isWorking: false
                            },
                            function () {
                                this.initializeCropper()
                            }
                        );
                    } else if (data.error) {
                        alert(data.error);

                        this.setState({ isWorking: false });
                        //$.quosal.dialog.error(data.error);
                    }
                }.bind(this)
            });
        }
    }
    imageUrlFromInternetKeyUp(e) {
        if (e.which == 13) {
            this.useInternetImage();
        }
    }
    imageUrlFromInternetPaste(e) {        
        if (e.clipboardData) {
            this.setState(
                {imageUrlFromInternet: e.clipboardData.getData("text")},
                this.useInternetImage
            );
        }
    }
    imageUrlFromInternetChange() {
        this.setState({imageUrlFromInternet: this.refs.imageUrlFromInternet.value});
    }
    useInternetImage() {
        if (!this.refs.imageUrlFromInternet.value) {
            return;
        }        
        this.setState(
            {
                imageUrl: this.refs.imageUrlFromInternet.value,
                newlyUploadedImage: null,
                imageUrlFromInternet: this.refs.imageUrlFromInternet.value
            },
            this.initializeCropper
        );        
    }
    imageSrcError() {
        this.setState(
            {imageUrl: null},
            function () {
                alert('The URL provided did not point to a valid image file.');
            }
        );
    }
    useOriginalImage() {
        this.setState(
            {
                imageUrl: this.props.src,
                newlyUploadedImage: null,
                imageUrlFromInternet: null
            },
            this.initializeCropper
        );
    }
    useOriginalImageHard() {
        // When the cropper loads based on the img tag provided (this.refs.resizer), it creates some non-react DOM elements. These
        // retain some style that cause an inaccurate presentation when the backing image is switched (for instance, they
        // keep the width and height of the earlier image). I couldn't seem to get the image resizer to reload correctly
        // without totally unloading the "cropMode" content and reloading it via switching to "supplyImageMode" and back.
        // React seems to not truly do this switch without the use of setTimeout below.
        this.clearImage();
        window.setTimeout(this.useOriginalImage, 0);
    }
    fixAspectRatioChange(e) {
        var aspectRatio = e.target.checked ? this.state.w / this.state.h : 0;
        this.setState({overrideAspectRatio: aspectRatio}, this.initializeCropper);
    }
    render() {
        var maxSize = '500px';

        var isCropMode = this.state.imageUrl && this.state.imageUrl != 'images/empty.png' && this.state.imageUrl != 'images/default-image.png'; // as opposed to supplyImageMode
        var isSrcNotEmpty = this.props.src && this.props.src != 'images/empty.png' && this.state.imageUrl != 'images/default-image.png';
        var isNewImageDifferentFromSrc = isSrcNotEmpty && this.props.src != this.state.imageUrl;

        const button = quosal.util.isNewEditorEnabled()
                ? <Button id="imageEditor_save" className={'save'} onClick={this.saveCroppedImage} disabled={this.state.isWorking} >
                        <span style={{ paddingRight: '5px' }}>
                            Save Image
                        </span>
                        {this.state.isLoading && <CircularProgress sx={{ color: "#0B9CD4" }} size={20} /> }
                    </Button>
                : <Button id="imageEditor_save" className={'save'} onClick={this.saveCroppedImage} disabled={this.state.isWorking} >Save Image</Button>
            
        return (
            <div id="imageEditor">
                <link type="text/css" href="css/imageEditor.css#CacheExpireTag" rel="stylesheet" />
                {
                    isCropMode
                    ?
                    <div key="cropMode" >
                        <i>Drag the little squares to crop the image, and click the save button when done.</i>
                        <br/>
                        <br/>
                        <div className="formcolumn">
                            <Button onClick={this.clearImage} disabled={this.state.isWorking} >New Image</Button>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            Aspect ratio
                            <br/>
                            <AspectRatioPicker imageEditor={this} value={this.state.overrideAspectRatio || this.state.w / this.state.h} disabled={this.state.isWorking}/>
                            <br/>
                            <input id="imageEditor_fixAspectRatio" ref="fixAspectRatio" type="checkbox" checked={!!this.state.overrideAspectRatio} onChange={this.fixAspectRatioChange} disabled={this.state.isWorking && this.state.w && this.state.h} />
                            <label htmlFor="imageEditor_fixAspectRatio">Fix aspect ratio</label>
                            <br/>

                            <br/>
                        </div>
                        <div id="imageEditor_resizerColumn" className="formcolumn">
                            <img ref="resizer" id="imageEditor_resizer" src={this.state.imageUrl} onError={this.imageSrcError} style={{maxWidth: maxSize, maxHeight: maxSize, backgroundColor: 'White'}} />

                            { button }

                            { isNewImageDifferentFromSrc ? <Button id="imageEditor_useOriginalImage" onClick={this.useOriginalImageHard} disabled={this.state.isWorking}>Use Original Image</Button>
                                : isSrcNotEmpty ? <Button id="imageEditor_delete" onClick={this.deleteImage} disabled={this.state.isWorking} >Delete Image</Button>
                                : null }
                        </div>
                    </div>
                    :
                    <div key="supplyImageMode" >
                        <i>Supply an image...</i>
                        <br/>
                        <br/>
                        <div className="formcolumn">
                            <i>From your computer</i>
                            <br/>
                            <input style={{ boxSizing: "border-box", width:"100%"}} ref="fileUploader" type="file" accept="image/*" onChange={this.uploadImage} disabled={this.state.isWorking} />
                        </div>

                        <div className="formcolumn">
                            <i>From the Internet</i>
                            <br/>
                            <input ref="imageUrlFromInternet" type="text" value={this.state.imageUrlFromInternet}
                                   onChange={this.imageUrlFromInternetChange}
                                   onKeyUp={this.imageUrlFromInternetKeyUp}
                                   onBlur={this.useInternetImage}
                                   onPaste={this.imageUrlFromInternetPaste}
                                   disabled={this.state.isWorking} />
                        </div>
                        {
                            this.props.src && this.props.src != 'images/empty.png' && this.props.src != 'images/default-image.png'
                                ?
                                <div className="formcolumn">
                                    <br/>
                                    <Button onClick={this.useOriginalImage} disabled={this.state.isWorking} style={{marginTop: "-4px"}} >Use Original Image</Button>
                                </div>
                                : null
                        }
                    </div>
                }
            </div>
        );
    }
}

class AspectRatioPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            textValue: this.printedAspectRatio(this.props.value),
        };

        // This binding is necessary to make `this` work in the callback
        this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
        this.setNewValue = this.setNewValue.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleEnter = this.handleEnter.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleTextBlur = this.handleTextBlur.bind(this);
    }
    static ratios() {
            var ratios = [
                [1, 1],
                [1, 2],
                [1, 3], [2, 3],
                [1, 4], [3, 4],
                [1, 5], [2, 5], [3, 5], [4, 5],
                [1, 6], [5, 6],
                [1, 7], [2, 7], [3, 7], [4, 7], [5, 7], [6, 7],
                [1, 8], [3, 8], [5, 8], [7, 8],
                [1, 9], [2, 9], [4, 9], [5, 9], [7, 9], [8, 9],
                [1, 10], [3, 10], [7, 10], [9, 10],
                [1, 11], [2, 11], [3, 11], [4, 11], [5, 11], [6, 11], [7, 11], [8, 11], [9, 11], [10, 11],
                [1, 12], [5, 12], [7, 12], [11, 12],
                [1, 13], [2, 13], [3, 13], [4, 13], [5, 13], [6, 13], [7, 13], [8, 13], [9, 13], [10, 13], [11, 13], [12, 13],
                [1, 14], [3, 14], [5, 14], [9, 14], [11, 14], [13, 14],
                [1, 15], [2, 15], [4, 15], [7, 15], [8, 15], [11, 15], [13, 15], [14, 15],
                [1, 16], [3, 16], [5, 16], [7, 16], [9, 16], [11, 16], [13, 16], [15, 16],
                [4, 17], [8, 17], [16, 17],
                [4, 19], [8, 19], [16, 19],
                [4, 21], [8, 21], [16, 21],
                [4, 23], [8, 23], [16, 23],
                [4, 25], [8, 25], [16, 25],
                [27, 64]
            ];
            ratios.forEach(function (element) {
                element.numericValue=(element[0]/element[1]);
            });
            ratios.sort(function (a, b) {
                return a.numericValue - b.numericValue;
            });
            return ratios;
        }
        // Returns the index at which value should be inserted to array in order to insert the value and maintain sorted order.
        // This means that the array elements that value might be close to are (if we call the return value "index") at index and index-1
     static sortedIndex(array, value) {
            var low = 0,
                high = array.length;

            while (low < high) {
                var mid = low + high >>> 1;
                if (array[mid].numericValue < value) low = mid + 1;
                else high = mid;
            }
            return low;
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            textValue: this.printedAspectRatio(nextProps.value)
        });
    }
    printedAspectRatio(rawAspectRatio) {
        var ratios = AspectRatioPicker.ratios;
        var aspectRatio = rawAspectRatio;
        var flipped = aspectRatio > 1;
        if (flipped) {
            aspectRatio = 1/aspectRatio;
        }
        var index = AspectRatioPicker.sortedIndex(ratios, aspectRatio);

        // If the raw aspect ratio is close enough to ("within epsilon of") the actual value of one of the recognized ratios, display it as that ratio.
        var epsilon = 1e-6;
        index = (ratios[index] && Math.abs(ratios[index].numericValue - aspectRatio) < epsilon) ? index :
            (ratios[index-1] && Math.abs(ratios[index-1].numericValue - aspectRatio) < epsilon) ? index - 1 : null;
        var printedAspectRatio = (index === null) ? rawAspectRatio :
            flipped ? '' + ratios[index][1] + ':' + ratios[index][0] : '' + ratios[index][0] + ':' + ratios[index][1];

        return printedAspectRatio;
    }
    setNewValue(newValue) {
        var ratioDelimiter = /[:/]/; // one colon or slash
        var newOverrideAspectRatio;
        if (typeof(newValue) === 'string') {
            var numeratorAndDenominator = newValue.split(ratioDelimiter, 2);
            if (numeratorAndDenominator.length === 1) {
                newOverrideAspectRatio = parseFloat(numeratorAndDenominator[0]);
            } else if (numeratorAndDenominator.length === 2) {
                var numerator = parseFloat(numeratorAndDenominator[0]),
                    denominator = parseFloat(numeratorAndDenominator[1]);
                if (numerator && denominator) {
                    newOverrideAspectRatio = numerator / denominator;
                }
            }
        } else {
            newOverrideAspectRatio = newValue;
        }
        if (newOverrideAspectRatio) {
            this.props.imageEditor.setState({overrideAspectRatio: newOverrideAspectRatio}, this.props.imageEditor.initializeCropper);
        }
    }
    handleSelectChange() {
        this.setNewValue(this.refs.select.value);
    }
    handleEnter(e) {
        if(e.which == 13) {
            this.setNewValue(e.target.value);
        }
    }
    handleTextChange(e) {
        this.setState({textValue: e.target.value});
    }
    handleTextBlur(e) {
        this.setNewValue(e.target.value);
    }
    handleTextFocus(e) {
        e.target.select();
    }
    render() {
        return (
            <div className="textInputWithSelect" >
                <select id="aspectRatioPicker_select" ref="select" value={''} onChange={this.handleSelectChange} disabled={this.props.disabled}>
                    <option value="" />
                    <option value={2/5}>2:5</option>
                    <option value={1/2}>1:2</option>
                    <option value={9/16}>9:16</option>
                    <option value={3/5}>3:5</option>
                    <option value={AspectRatioPicker.goldenRatioConjugate}>0.618... (golden ratio conjugate)</option>
                    <option value={2/3}>2:3</option>
                    <option value={AspectRatioPicker.root2Over2}>0.707... (half of square root of 2)</option>
                    <option value={3/4}>3:4</option>
                    <option value={4/5}>4:5</option>
                    <option value={1}>1:1 (square)</option>
                    <option value={5/4}>5:4</option>
                    <option value={4/3}>4:3</option>
                    <option value={AspectRatioPicker.root2}>1.414... (square root of 2)</option>
                    <option value={3/2}>3:2</option>
                    <option value={AspectRatioPicker.goldenRatio}>1.618... (golden ratio)</option>
                    <option value={5/3}>5:3</option>
                    <option value={16/9}>16:9 (widescreen)</option>
                    <option value={2}>2:1</option>
                    <option value={5/2}>5:2</option>
                </select>
                <input ref="text" type="text" title="" value={this.state.textValue} onChange={this.handleTextChange} onBlur={this.handleTextBlur} onKeyPress={this.handleEnter} onFocus={this.handleTextFocus} disabled={this.props.disabled}/>
            </div>
        );
    }
}

AspectRatioPicker.goldenRatio= 1.618033988749895;
AspectRatioPicker.goldenRatioConjugate= .6180339887498948;
AspectRatioPicker.root2= 1.414213562373095;
AspectRatioPicker.root2Over2= 0.7071067811865475;
import { AddDynamicQuesCondition } from "./AddDynamicQuesCondition";
import { DynamicQuestionConditionsTable } from "./DynamicQuestionConditionsTable";

export const DynamicQuestionMappingContainer = ({
	currentSectionIndex,
	currentQuestionIndex,
	currentQuestion,
	dynamicQuestionLists
}) => {
	return (
		<>
			<AddDynamicQuesCondition
				currentSectionIndex={currentSectionIndex}
				currentQuestionIndex={currentQuestionIndex}
				currentQuestion={currentQuestion}
				isActive={currentQuestion.isActive}
				isDynamicQuestion={currentQuestion.isDynamicQuestion}
			/>
			{dynamicQuestionLists.length > 0 && (
				<DynamicQuestionConditionsTable
					dynamicQuestionLists={dynamicQuestionLists}
					isActive={currentQuestion.isActive}
					currentSectionIndex={currentSectionIndex}
					currentQuestionIndex={currentQuestionIndex}
					currentQuestion={currentQuestion}
					isDynamicQuestion={currentQuestion.isDynamicQuestion}
				/>
			)}
		</>
	);
};

import {CloudSourceButton} from "js/jsx/src/classes/quote/cloudSourcing.jsx";
import {FormField, FormFieldInput} from "js/jsx/src/classes/forms.jsx";
import {ActionsMenu} from "js/jsx/src/classes/menus.jsx";
import {SearchForm, SearchResults} from "js/jsx/src/classes/search.jsx";

//TODO: nonFormattedFields for search grid / per BO?
export class ProductSearch extends React.Component {
    constructor(props) {
        super(props);
        this.findAllLikeThisDefaultLinkText= 'Find\u00a0All Like\u00a0This';
        this.maxAttach= 12;
        this.state = {
            pendingAttachments: 0,
            destinationTab: quosal.util.queryString('idquotetabs'),
        };

        this.attachProduct = this.attachProduct.bind(this);
        this.getSources = this.getSources.bind(this);
        this.getSearchCriteria = this.getSearchCriteria.bind(this);
        this.executeSearch = this.executeSearch.bind(this);
        this.resetSearch = this.resetSearch.bind(this);
        this.customizeCell = this.customizeCell.bind(this);
        this.getCurrentTab = this.getCurrentTab.bind(this);
        this.getMappingSetToUse = this.getMappingSetToUse.bind(this);
        this.createAttachedProductLinkCell = this.createAttachedProductLinkCell.bind(this);
        this.createCloudSourceCell = this.createCloudSourceCell.bind(this);
        this.createAttachProductCell = this.createAttachProductCell.bind(this);
        this.destinationTabChanged = this.destinationTabChanged.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.render = this.render.bind(this);
        this.partialUpdateApiRequests = [];
    }   

    static getCurrentTab() {
        var tab = null;
        if (quosal.util.queryString('idquotetabs')) {
            tab = app.currentQuote.Tabs.where(t => t.id == quosal.util.queryString('idquotetabs')).firstOrNull();
        }
        return tab;
    }

    static getItemIdToInsertAt(targetTabId) {
        let tab = ProductSearch.getCurrentTab();
        if (tab && (tab.id === targetTabId)) {
            let insertBefore = quosal.util.queryString('insertbefore');
            return insertBefore || null;
        }
        return null;
    }

    attachProduct(row, callback, changes) {
        var targetTab = this.state.destinationTab;
        var product = row.props.row;
        var syncQuote = (function () {
            var openQuoteApi = quosal.api.quote.openQuote(quosal.util.queryString('idquotemain'));
            openQuoteApi.finished = function (msg) {
                quosal.sell.quote.updateFromApiResponse(msg);

            }.bind(this);
            openQuoteApi.call();
        });

        product.isAttaching = true;
        row.forceUpdate();
        this.setState({ pendingAttachments: this.state.pendingAttachments + 1 });

        let insertBefore = ProductSearch.getItemIdToInsertAt(targetTab);

        var attachApi = quosal.api.product.attachWithEtilizeContent(product.SourceId, product.Source, product.SourceVendorName, product.IsSpecialOrder, app.currentQuote.IdQuoteMain, targetTab, null, insertBefore);
        this.partialUpdateApiRequests.push(attachApi.opId);

        attachApi.finished = function (row, msg) {
            product.isAttaching = false;
            product.item = msg.item;

            quosal.sell.quote.update(msg.quote);
            if (quosal.util.isNewEditorEnabled()) {
                sessionStorage.setItem('productSearchTabId', targetTab);
            }

            this.partialUpdateApiRequests.splice(this.partialUpdateApiRequests.indexOf(msg.opId), 1);
            var updateQuote = this.partialUpdateApiRequests.length == 0;

            if (updateQuote) {
                syncQuote();
            }

            if (this.componentIsMounted) {
                this.setState({ pendingAttachments: this.state.pendingAttachments - 1 });
                row.forceUpdate();
            }

            if (callback)
                callback(msg.item);
        }.bind(this, row);

        attachApi.call();
    }
    getSources() {
        var sourceGroupSelector = this.refs.search.refs.criteria.refs.form.getFields().where(s=>s.componentType == 'SourceGroupSelector').firstOrNull();
        var sources = [];

        if(sourceGroupSelector) {
            sources = sourceGroupSelector.refs.input.getSelectedSources();
        }
        return sources;
    }
    getSearchCriteria() {
        var form = this.refs.search.refs.criteria.refs.form;
        var searchCriteria = form.getFormValues(true);

        if (this.refs.salesforcePriceBookField) {
            searchCriteria.PriceBook = this.refs.salesforcePriceBookField.refs.input.getValue()
        }

        var isConnectwise = String.ciEquals(quosal.settings.getValue('customerProvider'), 'Connectwise');
        if (isConnectwise) {
            searchCriteria.cwProductSearchResultLimit = quosal.util.cookie('cwProductSearchResultLimit');
        }
        return searchCriteria;
    }
    executeSearch() {
        var sources = this.getSources();
        var currentMappingSet = this.getMappingSetToUse() || {};
        var searchCriteria = this.getSearchCriteria();
        var searchApi = quosal.api.product.search(
            searchCriteria,
            sources,
            app.currentQuote.IdQuoteMain,
            quosal.util.queryString('idquotetabs'),
            currentMappingSet.DoAutoSort,
            currentMappingSet.SortByField,
            currentMappingSet.SortDescending
        );
        searchApi.finished = function(msg) {
            this.refs.search.setState({searchResults: msg.results, searching: false});
        }.bind(this);
        searchApi.call();

        this.refs.search.setState({searchResults:[], searching:true});
    }
    resetSearch() {

    }
    customizeCell(params) {
        if(params.field.FieldName == 'Thumbnail') {
            return (
                String.isNullOrEmpty(params.gridRow.props.row.ThumbnailUrl) ?
                    <div /> :
                    <img src={params.gridRow.props.row.ThumbnailUrl} className="thumbnail small" />
            );
        }
        if(params.field.FieldName == 'ItemNotesHtml') {
            return <div dangerouslySetInnerHTML={{__html: params.displayValue}}></div>;
        }
    }
    getCurrentTab() {
        if (this.currentTab === undefined) {
            this.currentTab = ProductSearch.getCurrentTab();
        }
        return this.currentTab;
    }
    getMappingSetToUse() {
        if (!quosal.validation.isPackageLevelAuthorized(app.packageLevels.premium)) {
            return {};
        }
        var tab = this.getCurrentTab();
        var currentQuoteMappingId = app.currentQuote.ProductSearchPrefillMappingSet;
        var currentTabMappingId = tab && tab.ProductSearchPrefillMappingSet;
        var currentQuoteMapping = quosal.metadata.productSearchPrefillMappings.where(m=> m.id == currentQuoteMappingId).firstOrNull();
        var currentTabMapping = quosal.metadata.productSearchPrefillMappings.where(m=> m.id == currentTabMappingId).firstOrNull();
        return currentTabMapping || currentQuoteMapping;
    }
    createAttachedProductLinkCell(row) {
        if(row.props.row.item) {
            var canAccessItemEdit = (app.currentUser.IsContentMaintainer || app.currentUser.IsAdministrator 
                || !(quosal.settings.getValue('LockStandardUsersOutOfItemEdit')));

            if (canAccessItemEdit) {
                var navigateToProductEdit = function(row) {
                    Dialog.closeAll({skipAnimation:true});
                    app.currentModule.unloadSubModule(app.currentModule.getActiveSubModule());
                    app.currentModule.loadSubModule('product.edit', {
                        container: 'quoteModule',
                        query: 'itemid=' + row.props.row.item.IdQuoteItems + '&idquotetabs=' + row.props.row.item.IdQuoteTabs
                    });
                }.bind(this, row);

                return (
                    <a onClick={navigateToProductEdit} className="link">
                        <div className="icons-action edit color" style={{cursor:'pointer'}}
                             title="Edit Product"></div>
                    </a>
                );
            } else {
                return <img src="img/svgs/v1.0/Status_Success.svg" title="Product attached" />
            }
        }
    }
    createCloudSourceCell(attachProductFunction, row) {
        var attachEvent = quosal.events.create();
        attachEvent.bind(attachProductFunction.bind(this, row));

        return <CloudSourceButton item={row.props.row} className="contentgrid" type="productsearch" attachEvent={attachEvent} buttonText="Attach" />;
    }
    createAttachProductCell(attachProductFunction, row) {
        if(row.props.row.isAttaching) {
            return <Spinner />;
        } else {
            var attachDisabled = this.state.pendingAttachments >= ProductSearch.maxAttach;
            return <a className={'link' + (attachDisabled ? ' disabled' : '')}
                      title={attachDisabled ? 'You can only attach up to ' + ProductSearch.maxAttach + ' search results at a time.' : ''}
                      onClick={attachDisabled ? null : attachProductFunction.bind(this, row, null)}>Attach</a>;
        }
    }
    destinationTabChanged(e) {
        this.setState({ destinationTab: e.target.value });
        sessionStorage.setItem("cpq_open_tab", e.target.value);
    }
    componentDidMount() {
        this.componentIsMounted = true;
        var mappingSetToUse = this.getMappingSetToUse();
        if (mappingSetToUse && mappingSetToUse.DoAutoSearch) {
            this.executeSearch();
        }
    }
    componentWillUnmount(){
        this.componentIsMounted = false;
    }
    render() {
        var customColumns = [
            {
                replaceSortableColumn: true,
                createCell: this.createAttachedProductLinkCell
            }, {
                createCell: this.createCloudSourceCell.bind(this, this.attachProduct)
            }, {
                createCell: this.createAttachProductCell.bind(this, this.attachProduct)
            }
        ];


        if (app.settings.user.ProductSearchFindAllLikeThisSearchField) {

            var findAllLikeThisLinkText = app.settings.user.ProductSearchFindAllLikeThisLinkText || ProductSearch.findAllLikeThisDefaultLinkText;

            var customColumnsForFindAllLikeThis = quosal.util.clone(customColumns);
            customColumnsForFindAllLikeThis.push({
                replaceSortableColumn: true,
                createCell: this.createAttachedProductLinkCell
            });

            var findAllLikeThisField = app.settings.user.ProductSearchFindAllLikeThisSearchField;
            var showFindAllLikeThisResults = function (row, gridLink, clickEvent) {
                var findAllLikeThisLinkText = app.settings.user.ProductSearchFindAllLikeThisLinkText || ProductSearch.findAllLikeThisDefaultLinkText;

                var findAllLikeThisCriteria = this.getSearchCriteria();;
                var findAllLikeThisSources = this.getSources();
                var findAllLikeThisKeyValue = row.props.row[findAllLikeThisField]

                var currentMappingSet = this.getMappingSetToUse() || {};
                
                var findAllLikeThisSearchApi = quosal.api.product.search(
                    findAllLikeThisCriteria, 
                    findAllLikeThisSources, 
                    app.currentQuote.IdQuoteMain, 
                    quosal.util.queryString('idquotetabs'),
                    currentMappingSet.DoAutoSort,
                    currentMappingSet.SortByField,
                    currentMappingSet.SortDescending,
                    true,
                    findAllLikeThisKeyValue
                );
                findAllLikeThisSearchApi.finished = function(msg) {
                    gridLink.setState({ isLoading: false });

                    var resultProps = quosal.util.clone(this.refs.search.refs.results.props);
                    resultProps.customizable = false;
                    resultProps.resultGridSize = '1-1';
                    resultProps.customColumns = customColumnsForFindAllLikeThis;
                    resultProps.results = msg.results;

                    var findAllLikeThisResults = <SearchResults {...resultProps} />

                    Dialog.open({
                        title: findAllLikeThisLinkText,
                        height:'80%',
                        width:'80%',
                        resizable: true,
                        draggable: true,
                        links:[Dialog.links.close],
                        message: findAllLikeThisResults
                    });
                }.bind(this);
                gridLink.setState({ isLoading: true });
                findAllLikeThisSearchApi.call();

            };

            customColumns.push({
                createCell: function(row) {
                    var findAllLikeThisKeyValue = row.props.row[findAllLikeThisField];
                    return <ProductSearchFindAllLikeThisLink keyValue={findAllLikeThisKeyValue} onClick={showFindAllLikeThisResults.bind(this, row)} linkText={findAllLikeThisLinkText} />;
                }.bind(this)
            });
        }

        var searchCriteriaProps = { numericInputsUseRanges: false };
        var formProps = {};
        var tab = this.getCurrentTab();
        var mappingSetToUse = this.getMappingSetToUse();
        if (mappingSetToUse && mappingSetToUse.Mappings && mappingSetToUse.Mappings.length) {
            var alwaysDirtyInputValues = {};
            var readonlyInputs = {};

            // FSP 3/28/17 8856790: Correct execution priority of mappings relies on mappings being sorted.
            // FSP 4/20/17 8856790: This client logic is redundant to the server side logic in BusinessLogic\Product\ProductSearch.cs
            for (var i = 0; i < mappingSetToUse.Mappings.length; i++) {
                var loopMapping = mappingSetToUse.Mappings[i];
                if (loopMapping.IsSearchInputReadonly) {
                    readonlyInputs[loopMapping.DestinationProductProperty] = true;
                }
                if (loopMapping.DestinationProductProperty && !alwaysDirtyInputValues[loopMapping.DestinationProductProperty]) {
                    if (loopMapping.SourceBo == 'QuoteMain') {
                        alwaysDirtyInputValues[loopMapping.DestinationProductProperty] = app.currentQuote[loopMapping.SourceProperty];
                    } else if (tab && loopMapping.SourceBo == 'QuoteTabs') {
                        alwaysDirtyInputValues[loopMapping.DestinationProductProperty] = tab[loopMapping.SourceProperty];
                    } else if (loopMapping.StaticSourceValue) {
                        alwaysDirtyInputValues[loopMapping.DestinationProductProperty] = loopMapping.StaticSourceValue;
                    }
                }
            }
            searchCriteriaProps.alwaysDirtyInputValues = alwaysDirtyInputValues;
            formProps.readonlyInputs = readonlyInputs;
        }

        var actions = [];
        if (app.currentUser.IsAdministrator && quosal.validation.isPackageLevelAuthorized(app.packageLevels.premium)) {
            // Entry point to edit Product Search Prefill Mappings
            var currentMappingSet = mappingSetToUse;
            var onMappingSetSave = function () {
                delete this.currentTab;
                this.forceUpdate(function () {
                    this.refs.search.refs.criteria.resetForm();
                });
            }.bind(this);
            actions.push({
                title: 'Edit Search Prefill Mappings',
                callback: ProductSearchPrefillerCustomizer.showProductSearchPrefillerCustomizer.bind(null, currentMappingSet, onMappingSetSave)
            });

            actions.push({
                title: 'Settings for Find All Like This',
                callback: ProductSearchFindAllLikeThisSettings.showProductSearchFindAllLikeThisSettings.bind(null, this)
            });
        };

        var isConnectwise = String.ciEquals(quosal.settings.getValue('customerProvider'), 'Connectwise');
        if (isConnectwise) {
            var cwProductSearchResultLimit = quosal.util.cookie('cwProductSearchResultLimit');
            if (!cwProductSearchResultLimit) {
                cwProductSearchResultLimit = '50'; // FSP 4/27/17 8761166: This default value is duplicated on the server in CWRestProducts.cs
                quosal.util.cookie('cwProductSearchResultLimit', cwProductSearchResultLimit);
            }
            var onSelectChange = function (e) {
                quosal.util.cookie('cwProductSearchResultLimit', e.target.value);
                this.forceUpdate();
            }.bind(this);

            actions.push({
                title: 'Change CRM Search Result Limit (' + cwProductSearchResultLimit + ')',
                callback: function () {
                    Dialog.open(
                        {
                            title: 'Change CRM Search Result Limit',
                            links: [Dialog.links.ok],
                            message: (<CWProductSearchResultLimitChanger onSelectChange={onSelectChange} cwProductSearchResultLimit={cwProductSearchResultLimit} />)
                        },
                        function () {
                            $('#cwProductSearchResultLimitChanger').focus(); }
                    );

                    //this.forceUpdate();
                }
            });
        }

        if (actions.length) {
            formProps.firstPanelTitleContents = <div className="toolbar" style={{verticalAlign: 'middle'}} >
                <ActionsMenu key="productSearchActions" className="toolbutton" actions={actions} /></div>
        }

        var tabChangeConfigFunction = function (isChecked, formName, newConfigurationName, callback) {
            var tabLevelFormConfig = isChecked ? newConfigurationName : '';
            var updatedValues = {
                ProductSearchFormConfiguration: tabLevelFormConfig
            };
            var updateApi = quosal.api.data.update({
                fields: updatedValues,
                queries: [{
                    table: 'QuoteTabs',
                    where: [{
                        field: 'IdQuoteTabs',
                        operator: 'Equals',
                        value: tab.IdQuoteTabs
                    }]
                }]
            }, app.currentQuote.IdQuoteMain);
            updateApi.finished = function (msg) {
                delete this.currentTab;
                quosal.sell.quote.updateFromApiResponse(msg);
                callback();
            }.bind(this);
            updateApi.call();
        }.bind(this);
        formProps.saveSpecifications = [{
            key: 'tab',
            changeConfigFunction: tabChangeConfigFunction,
            checkboxLabel: 'Use This as the Tab-Level Configuration',
            isCheckedInitialValue: function (value) { return value == tab.ProductSearchFormConfiguration; },
            addActionPreviewText: 'Set this as the tab-level form configuration for product search',
            removeActionPreviewText: 'Clear the tab-level form configuration for product search'
        }];

        // Add price book input for Salesforce Search Form
        if (quosal.settings.getValue('customerProvider') === 'SalesForce') {
            var priceBookField = {
                FieldName: 'PriceBook',
                LabelName: 'Pricebook',
                DataType: 'Enum',
                EnumType: 'SalesforcePriceBook'
            };
            var metadata = {};
            metadata.SalesforcePriceBook = quosal.settings.getValue('salesforcePriceBooks');
            if (searchCriteriaProps.alwaysDirtyInputValues) {
                priceBookField.Value = searchCriteriaProps.alwaysDirtyInputValues.PriceBook;
            }
            var priceBookFieldDisabled = false;
            if (formProps.readonlyInputs) {
                priceBookFieldDisabled = formProps.readonlyInputs.PriceBook;
            }
            var salesforcePriceBookField = <FormField ref="salesforcePriceBookField" field={priceBookField} metadata={metadata} className="extra-search-field" disabled={priceBookFieldDisabled}/>;
            searchCriteriaProps.firstPanelExtraContent = salesforcePriceBookField;
        }

        var specialColumnRemaps = { 'Source' : 'Info', 'ItemNotes':'ItemNotesHtml' };

        var formConfiguration = this.props.formConfiguration;
        if (tab.ProductSearchFormConfiguration) {
            formConfiguration = quosal.util.clone(formConfiguration);
            formConfiguration.configurationName = tab.ProductSearchFormConfiguration;
        }

        var customContentAboveResults = '';
        var tabdrops = [];
        if (app.currentQuote) {
            tabdrops.push(<option value='~tabroute~' disabled='disabled' key='mainactivetab'>Tab Routing
                Option</option>);
            var qtabs = app.currentQuote.Tabs;
            for (var i = 0; i < qtabs.length; i++) {
                if (!qtabs[i].IsProtectedTab || quosal.util.userCanModifyProtectedTab()) {
                    tabdrops.push(<option value={qtabs[i].IdQuoteTabs}
                                          key={'m' + qtabs[i].IdQuoteTabs}>{qtabs[i].TabName}</option>);
                }
            }
            customContentAboveResults = (
                <div className="formcolumn" style={{padding:0}}>
                    <label htmlFor="mainrouting" className="formlabel">Target Tab</label>
                    <div className="formselectfieldwrapper">
                        <select className="formselectfield" id="mainrouting" name="mainrouting"
                                value={this.state.destinationTab} onChange={this.destinationTabChanged}>
                            {tabdrops}
                        </select>
                    </div>
                </div>
            );
        }

        return (
            <SearchForm ref="search"
                        nonFormattedFields={['OnOrder','OnHand']}
                        resetSearch={this.resetSearch}
                        gridProps={{customizeCell: this.customizeCell}}
                        formProps={formProps}
                        customResultsColumns={customColumns}
                        formConfiguration={formConfiguration}
                        gridConfiguration={this.props.gridConfiguration}
                        executeSearch={this.executeSearch}
                        specialColumnRemaps={specialColumnRemaps}
                        searchCriteriaProps={searchCriteriaProps}
                        customContentAboveResults={customContentAboveResults}
            />
        );
    }
}

class CWProductSearchResultLimitChanger extends React.Component {
    componentDidMount() {
        this.refs.select.focus();
    }
    render() {
        return (<div className="formselectfieldwrapper">
            <select id="cwProductSearchResultLimitChanger" ref="select" className="formselectfield"
                    defaultValue={this.props.cwProductSearchResultLimit} onChange={this.props.onSelectChange}>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
            </select>
        </div>);
    }
}

class ProductSearchAttachLink extends React.Component {
    render() {
        return (
            <a className="link" onClick={this.attachProduct.bind(this, row)}>Attach</a>
        );
    }
}

class ProductSearchPrefillerCustomizer extends React.Component {
    constructor(props) {
        super(props);
        this.optionLists = {};           

        var currentMappingSet = this.props.initialMappingSet || ProductSearchPrefillerCustomizer.newMappingSet;

        this.state = ProductSearchPrefillerCustomizer.addMappingSetToNewState({}, currentMappingSet);

        this.loadFields = this.loadFields.bind(this);
        this.onMappingSetNameChanged = this.onMappingSetNameChanged.bind(this);
        this.onAutoSearchFlagChanged = this.onAutoSearchFlagChanged.bind(this);
        this.selectedMappingSetChanged = this.selectedMappingSetChanged.bind(this);
        this.addNewMapping = this.addNewMapping.bind(this);
        this.cancelChanges = this.cancelChanges.bind(this);
        this.updateMappingSetClientData = this.updateMappingSetClientData.bind(this);
        this.saveMappingSet = this.saveMappingSet.bind(this);
        this.deleteMappingSet = this.deleteMappingSet.bind(this);
        this.copyToNew = this.copyToNew.bind(this);
        this.isNew = this.isNew.bind(this);
        this.onSortDescendingChange = this.onSortDescendingChange.bind(this);
        this.onAutoSortFlagChanged = this.onAutoSortFlagChanged.bind(this);
        this.onSortFieldChange = this.onSortFieldChange.bind(this);
        this.onUpdateQuoteFlagChanged = this.onUpdateQuoteFlagChanged.bind(this);
        this.onUpdateTabFlagChanged = this.onUpdateTabFlagChanged.bind(this);
    }

    static newMappingSetState(){
        return {
            mappingSetChanged: false,
            rename: null,
            updatedAutoSearchFlag: null,
            updatedAutoSortFlag: null,
            updatedSortDescending: null,
            updatedSortField: null,
            idQuoteMain: null,
            isMappingSetOnQuote: null,
            idQuoteTabs: null,
            isMappingSetOnTab: null,
        }
    }
    
    static showProductSearchPrefillerCustomizer(currentMappingSet, onMappingSetSave) {
       

        var customizerArgs = {
            initialMappingSet: currentMappingSet,
            promptedSaveEvent: quosal.events.create(),
            onMappingSetSave: onMappingSetSave
        };

        var componentDidMount = function () {
            this.props.customizerArgs.customizer = this; // `this` will refer to the instance of ProductSearchPrefillerCustomizer that did mount
        };
        var componentWillUnmount = function () {
            this.props.customizerArgs.customizer = null; // `this` will refer to the instance of ProductSearchPrefillerCustomizer that will unmount
        };
        var customizer = <ProductSearchPrefillerCustomizer {...customizerArgs} customizerArgs={customizerArgs} componentDidMount={componentDidMount} componentWillUnmount={componentWillUnmount} />;

        var closeDialog = function () {
            if (this.customizer.state.mappingSetChanged) {
                Dialog.confirmDelete({
                    title:'Changes will be lost',
                    width: '400px',
                    links:[
                        {title: 'Save Changes', callback: function() {
                            Dialog.setIsWorking(true);
                            var saveError = this.promptedSaveEvent.call();
                            if (saveError) {
                                Dialog.close({callback: function() {
                                    Dialog.open({
                                        title: 'Error Saving Mappings',
                                        message: saveError,
                                        links: [{title:'OK', callback: Dialog.close}]
                                    });
                                }});
                            } else {
                                Dialog.closeAll();
                            }
                        }.bind(this)},
                        {title: 'Discard Changes', callback: Dialog.closeAll},
                        {title: "Cancel", callback: Dialog.close}
                    ],
                    message: 'You have unsaved changes. Are you sure you want to close the mapping set editor window?'
                });
            } else {
                Dialog.close();
            }
        }.bind(customizerArgs);
        Dialog.open({
            title:'Product Search Prefill Mappings',
            height:'80%',
            width:'80%',
            resizable: true,
            draggable: true,
            onClose: closeDialog,
            links:[{title: 'Finished', callback: closeDialog}],
            message: customizer
        })
    }

    static addMappingSetToNewState (newState, mappingSet) {
        newState.currentMappingSet = mappingSet;
        var mappingCopies = [];
        if (mappingSet.Mappings && mappingSet.Mappings.length) {
            for (var i = 0; i < mappingSet.Mappings.length; i++) {
                mappingCopies.push(quosal.util.clone(mappingSet.Mappings[i]));
            }
        }
        newState.updatedMappings = mappingCopies;
        newState.updatedAutoSearchFlag = mappingSet.DoAutoSearch;
        //
        newState.updatedAutoSortFlag = mappingSet.DoAutoSort;
        newState.updatedSortDescending = mappingSet.SortDescending;
        newState.updatedSortField = mappingSet.SortByField;
        //
        return newState;
    }

    loadFields(boName) {
        var fieldOptions = [];
        fieldOptions.push(<option key={-1} value={''}></option>);
        var params = {
            boName: boName,
            initialOptions: fieldOptions
        };
        this.optionLists[boName] = quosal.customization.fields.getBOPropertyOptionList(params);
    }

    UNSAFE_componentWillMount() {
        this.loadFields('QuoteMain');
        this.loadFields('QuoteTabs');
        this.loadFields('QuoteItems');
        this.optionLists['BoType'] = [
            <option key="0" value="">Fixed Value</option>,
            <option key="1" value="QuoteMain">Quote</option>,
            <option key="2" value="QuoteTabs">Tab</option>
        ];
        this.optionLists['sortDescending'] = [
            <option key = "0" value = "Ascending">Ascending</option>,
            <option key = "1" value = "Descending">Descending</option>
        ];

    }

    componentDidMount() {
        if (this.props.componentDidMount) {
            this.props.componentDidMount.call(this);
        }
    }

    componentWillUnmount() {
        if (this.props.componentWillUnmount) {
            this.props.componentWillUnmount.call(this);
        }
    }
    onMappingSetNameChanged(e) {
        this.setState({
            rename: e.target.value,
            mappingSetChanged: true
        });
    }

    onAutoSearchFlagChanged(e) {
        this.setState({
            updatedAutoSearchFlag: e.target.checked,
            mappingSetChanged: true
        });
    }

    selectedMappingSetChanged(e) {
        var id = e.target.value;
        var newState = ProductSearchPrefillerCustomizer.newMappingSetState();
        if (id) {
            ProductSearchPrefillerCustomizer.addMappingSetToNewState(newState, quosal.metadata.productSearchPrefillMappings.firstOrNull(m=>m.id === id));
            this.setState(newState);
        } else {
            ProductSearchPrefillerCustomizer.addMappingSetToNewState(newState, ProductSearchPrefillerCustomizer.newMappingSet);
            this.setState(newState);
        }
    }

    addNewMapping() {
        var updatedMappings = this.state.updatedMappings || [];
        //Initially, any new mapping has 'Fixed Value' selected as the Source. In the dropdown "Fixed Value" translates to an empty string value
        updatedMappings.push({
            SourceBo: '',
            id: 'newMapping' + (ProductSearchPrefillerCustomizer.newMappingId++)
        });
        this.setState({
            mappingSetChanged: true,
            updatedMappings: updatedMappings
        });
    }

    cancelChanges() {
        var newState = ProductSearchPrefillerCustomizer.newMappingSetState();

        var mappingSetToResetTo = this.isNew() ?
            ProductSearchPrefillerCustomizer.newMappingSet :
            quosal.metadata.productSearchPrefillMappings.firstOrNull(m=>m.id === this.state.currentMappingSet.IdProductSearchPrefillMappingSet);

        ProductSearchPrefillerCustomizer.addMappingSetToNewState(newState, mappingSetToResetTo);
        this.setState(newState);
    }

    updateMappingSetClientData(mappingSet, mappingsUpdated, isDelete) {
        if (mappingSet && mappingSet.IdProductSearchPrefillMappingSet) {
            if (isDelete === true) {
                quosal.metadata.enums["ProductSearchPrefillMappingSetList"].removeAll(x => x.Value == mappingSet.IdProductSearchPrefillMappingSet);
                quosal.metadata.productSearchPrefillMappings.removeAll(x => x.id == mappingSet.IdProductSearchPrefillMappingSet);
            } else {
                var foundId = false;
                for (var i = 0; i < quosal.metadata.productSearchPrefillMappings.length; i++) {
                    var iMappingSet = quosal.metadata.productSearchPrefillMappings[i];
                    if (iMappingSet.IdProductSearchPrefillMappingSet === mappingSet.IdProductSearchPrefillMappingSet) {
                        foundId = true;
                        if (mappingsUpdated) {
                            quosal.metadata.productSearchPrefillMappings[i] = mappingSet;
                        } else {
                            var previousMappings = iMappingSet.Mappings;
                            quosal.metadata.productSearchPrefillMappings[i] = mappingSet;
                            quosal.metadata.productSearchPrefillMappings[i].Mappings = previousMappings;
                        }
                        break;
                    }
                }
                if (!foundId) {
                    quosal.metadata.productSearchPrefillMappings.push(mappingSet);
                }
                foundId = false;
                var mappingEnumList = quosal.metadata.enums["ProductSearchPrefillMappingSetList"];
                for (var i = 0; i < mappingEnumList.length; i++) {
                    var iMappingEnumEntry = mappingEnumList[i];
                    if (iMappingEnumEntry.Value === mappingSet.IdProductSearchPrefillMappingSet) {
                        foundId = true;
                        iMappingEnumEntry.Label = mappingSet.Name;
                    }
                }
                if (!foundId) {
                    var newMapping = {Label: mappingSet.Name, Value: mappingSet.IdProductSearchPrefillMappingSet};
                    mappingEnumList.push(newMapping);
                }

                //Remove default from top of list
                var arrayOfFirstEnumEntry = mappingEnumList.splice(0, 1);
                //Sort the rest
                mappingEnumList.sort(function (a, b) {
                    var nameA = a.Label.toLowerCase(), nameB = b.Label.toLowerCase();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                });
                //Add default back to top of list
                quosal.metadata.enums["ProductSearchPrefillMappingSetList"] = arrayOfFirstEnumEntry.concat(mappingEnumList);
            }

            if (this.props.onMappingSetSave) {
                this.props.onMappingSetSave.call();
            }
        }
    }

    saveMappingSet() {
        this.setState({
            isSaving: true
        });

        var mappingsToSave = this.state.mappingSetChanged ? this.state.updatedMappings : null;
        var saveMappingSet = quosal.api.product.saveProductSearchPrefillMappingSet(
            this.state.currentMappingSet.IdProductSearchPrefillMappingSet,
            this.state.rename,
            mappingsToSave,
            this.state.updatedAutoSearchFlag,
            this.state.updatedAutoSortFlag,
            this.state.updatedSortDescending,
            this.state.updatedSortField,
            this.state.idQuoteMain,
            this.state.isMappingSetOnQuote,
            this.state.idQuoteTabs,
            this.state.isMappingSetOnTab
        );
        saveMappingSet.finished = function (msg) {
            if(msg.quote) {
                quosal.sell.quote.update(msg.quote);
            }
            //Update places that should be using the current mapping set
            this.updateMappingSetClientData(msg.mappingSet, !!mappingsToSave);
            var newState = ProductSearchPrefillerCustomizer.newMappingSetState();
            newState.isSaving = false;
            ProductSearchPrefillerCustomizer.addMappingSetToNewState(newState, msg.mappingSet);
            this.setState(newState);
        }.bind(this);
        saveMappingSet.call();
    }

    deleteMappingSet() {
        this.setState({
            isDeleting: true
        });

        var mappingSet = this.state.currentMappingSet;
        var id = mappingSet.IdProductSearchPrefillMappingSet;
        var deleteMappingSet = quosal.api.product.deleteProductSearchPrefillMappingSet(id);
        deleteMappingSet.finished = function (msg) {
            this.updateMappingSetClientData(mappingSet, null, true);
            var newState = ProductSearchPrefillerCustomizer.newMappingSetState();
            newState.isDeleting = false;
            ProductSearchPrefillerCustomizer.addMappingSetToNewState(newState, ProductSearchPrefillerCustomizer.newMappingSet);
            this.setState(newState);
        }.bind(this);
        deleteMappingSet.call();
    }

    copyToNew() {
        var newName = (this.state.rename || (this.state.currentMappingSet && this.state.currentMappingSet.Name)) + ' (Copy)';
        this.setState({
            mappingSetChanged: true,
            rename: newName,
            currentMappingSet: ProductSearchPrefillerCustomizer.newMappingSet,
            updatedMappings: this.state.updatedMappings
        });
    }

    isNew() {
        return !(this.state.currentMappingSet && this.state.currentMappingSet.IdProductSearchPrefillMappingSet);
    }

    onSortDescendingChange(e) {
        this.setState({
            updatedSortDescending: e.target.value == 'Descending',
            mappingSetChanged: true
        });
    }

    onAutoSortFlagChanged(e) {
        this.setState({
            updatedAutoSortFlag: e.target.checked,
            mappingSetChanged: true
        });
    }

    onSortFieldChange(e) {
        this.setState({
            updatedSortField: e.target.value,
            mappingSetChanged: true
        });
    }

    onUpdateQuoteFlagChanged(e) {
        var newState = {};
        newState.mappingSetChanged = true;
        newState.idQuoteMain = app.currentQuote.IdQuoteMain;
        newState.isMappingSetOnQuote = e.target.checked;
        this.setState(newState);
    }

    onUpdateTabFlagChanged(e) {
        var newState = {};
        newState.mappingSetChanged = true;
        newState.idQuoteTabs = e.target.value;
        newState.isMappingSetOnTab = e.target.checked;
        if (!this.state.idQuoteMain) {
            newState.idQuoteMain = app.currentQuote.IdQuoteMain;
            newState.isMappingSetOnQuote = this.refs.isMappingSetOnQuote.checked;
        }
        this.setState(newState);
    }

    render() {

        if(this.props.promptedSaveEvent) {
            this.props.promptedSaveEvent.unbind().bind(this.saveMappingSet);
        }

        var inputDisabled = this.state.isSaving || this.state.isDeleting || this.state.isLoading;

        var selectOptions = [];
        for(var i = 0; i < quosal.metadata.enums["ProductSearchPrefillMappingSetList"].length; i++) {
            var newMappingLabel = null;
            if(quosal.metadata.enums["ProductSearchPrefillMappingSetList"][i].Label == "None" && !quosal.metadata.enums["ProductSearchPrefillMappingSetList"][i].Value){
                newMappingLabel = "New Mapping Set"
            }
            var mappingSet = quosal.metadata.enums["ProductSearchPrefillMappingSetList"][i];
            selectOptions.push(<option key={mappingSet.Value} value={mappingSet.Value}>{newMappingLabel || mappingSet.Label}</option>);
        }
        var isNew = this.isNew();
        var currentName = this.state.rename || (this.state.currentMappingSet && !isNew && this.state.currentMappingSet.Name) || ''
        var idOfCurrentMappingSet = this.state.currentMappingSet && this.state.currentMappingSet.IdProductSearchPrefillMappingSet;
        var autoSearchFlag = (this.state.updatedAutoSearchFlag != null) ? this.state.updatedAutoSearchFlag : this.state.currentMappingSet.DoAutoSearch;
        var autoSortFlag = (this.state.updatedAutoSortFlag != null) ? this.state.updatedAutoSortFlag : this.state.currentMappingSet.DoAutoSort;
        var sortDescendingDropdown = <div className="formselectfieldwrapper">
            <select className="formselectfield"
                    value={this.state.updatedSortDescending != null ? (this.state.updatedSortDescending ? 'Descending' : 'Ascending'): (this.state.currentMappingSet.SortDescending? 'Ascending' : 'Descending')}
                    onChange={this.onSortDescendingChange}
            >{this.optionLists['sortDescending']}</select>
        </div>;

        var sortByField = <div className="formselectfieldwrapper">
            <select className="formselectfield" id="prefillMappingSetSortField"
                    value={this.state.updatedSortField != null ? this.state.updatedSortField : this.state.currentMappingSet.SortByField}
                    onChange={this.onSortFieldChange}
            >{this.optionLists['QuoteItems']}</select>
        </div>;

        var mappingRows = [];
        if (this.state.updatedMappings && this.state.updatedMappings.length) {
            for (var i = 0; i < this.state.updatedMappings.length; i++) {
                var updatedMapping = this.state.updatedMappings[i];
                mappingRows.push(<ProductSearchPrefillMappingRow key={updatedMapping.id} parent={this} index={i} mapping={updatedMapping} />);
            }
        }

        var currentQuote = app.currentQuote;
        var isMappingSetOnQuote = this.state.isMappingSetOnQuote;
        if (isMappingSetOnQuote == null) {
            isMappingSetOnQuote = idOfCurrentMappingSet && (currentQuote.ProductSearchPrefillMappingSet === idOfCurrentMappingSet);
        }

        var currentTab = ProductSearch.getCurrentTab();
        var isMappingSetOnTab = this.state.isMappingSetOnTab;
        if (isMappingSetOnTab == null) {
            isMappingSetOnTab = idOfCurrentMappingSet && currentTab && (currentTab.ProductSearchPrefillMappingSet === idOfCurrentMappingSet);
        }

        return (
            <div>
                <Panel title="Manage Mapping Sets">
                    <PanelContent>
                        <div className="formcolumn">
                            <div className="formfieldlabel"><label htmlFor="productSearchPrefillMappingSetSelect" className="standardformlabel">Load Mapping Set</label></div>
                            <div className="formselectfieldwrapper">
                                <select className="formselectfield" ref="selectedMappingSet" disabled={inputDisabled} id="productSearchPrefillMappingSetSelect"
                                        onChange={this.selectedMappingSetChanged} value={idOfCurrentMappingSet} >{selectOptions}</select>
                            </div>
                            <div className="formfieldlabel"><label htmlFor="GridLayoutName" className="standardformlabel">{ isNew ? 'Name' : 'Rename As' }</label></div>
                            <div className="formfield"><input type="text" ref="layoutName" name="GridLayoutName" id="GridLayoutName" disabled={inputDisabled} title="Grid Layout Name" value={currentName} onChange={this.onMappingSetNameChanged} /></div>
                        </div>

                        { this.state.currentMappingSet ?
                            <div className="formcolumn">
                                <div className="formcheckboxwrapper">
                                    <input ref="isMappingSetOnQuote" id="prefillMappingSetUpdateQuoteCheckbox" type="checkbox" checked={isMappingSetOnQuote} onChange={this.onUpdateQuoteFlagChanged} disabled={inputDisabled}/>
                                    <label htmlFor="prefillMappingSetUpdateQuoteCheckbox" className="formfieldlabel">Use This Mapping Set on Current Quote</label>
                                </div>
                                { currentTab ?
                                    <div className="formcheckboxwrapper">
                                        <input id="prefillMappingSetUpdateTabCheckbox" type="checkbox" checked={isMappingSetOnTab} onChange={this.onUpdateTabFlagChanged} disabled={inputDisabled} value={currentTab.IdQuoteTabs}/>
                                        <label htmlFor="prefillMappingSetUpdateTabCheckbox" className="formfieldlabel">Use This Mapping Set on Current Tab</label>
                                    </div>
                                    : null }
                            </div>
                            : null }
                        { this.state.currentMappingSet ?
                            <div className="formcolumn">
                                <div className="formcheckboxwrapper">
                                    <input id="prefillMappingSetAutoSortCheckbox" type="checkbox" checked={autoSortFlag} onChange={this.onAutoSortFlagChanged} disabled={inputDisabled}/>
                                    <label htmlFor="prefillMappingSetAutoSortCheckbox" className="formfieldlabel">Sort Search Results Automatically</label>
                                </div>
                                {autoSortFlag?
                                    <div>
                                        <div>
                                            <label htmlFor="prefillMappingSetSortField" className="formfieldlabel">Field to sort results on</label>
                                            {sortByField}
                                        </div>
                                        {sortDescendingDropdown}
                                    </div>
                                :null}
                            </div>
                            : null }
                        { this.state.currentMappingSet ?
                            <div className="formcolumn">
                                <div className="formcheckboxwrapper">
                                    <input id="prefillMappingSetAutoSearchCheckbox" type="checkbox" checked={autoSearchFlag} onChange={this.onAutoSearchFlagChanged} disabled={inputDisabled}/>
                                    <label htmlFor="prefillMappingSetAutoSearchCheckbox" className="formfieldlabel">Run Search Automatically on Page Load</label>
                                </div>
                            </div>
                            : null }
                        <br/>
                        <br/>
                        <div>
                            <div className="formcolumn">
                                <button disabled={!(idOfCurrentMappingSet) || inputDisabled} onClick={this.deleteMappingSet}>{this.state.isDeleting ? <Spinner /> : null} {this.state.isDeleting ? 'Deleting Mapping Set...' : 'Delete Mapping Set'}</button>
                            </div>
                            <div className="formcolumn productsearchprefillmapping" style={{width:'inherit'}}>
                                <button className="save" disabled={!this.state.mappingSetChanged || inputDisabled } onClick={this.saveMappingSet}>{this.state.isSaving ? <Spinner /> : null}{this.state.isSaving ? 'Saving Mapping Set...' : 'Save Mapping Set'}</button>
                                <button className="cancel" disabled={!this.state.mappingSetChanged || inputDisabled } style={{marginLeft:8}} onClick={this.cancelChanges}><span>Cancel Changes</span></button>
                                { isNew ? null : <button disabled={ inputDisabled } style={{marginLeft:8}} onClick={this.copyToNew}><span>Copy to New Set</span></button> }
                            </div>
                        </div>
                    </PanelContent>
                </Panel>

                { this.state.currentMappingSet ?
                    <Panel title={'Edit Mappings for ' + (currentName || 'Unnamed Mapping Set')}>
                        <PanelContent>
                            <style>
                                {'#productSearchPrefillMappingsEditGrid td.prefillMappingSurceColumn div.formselectfieldwrapper { width: 100px }'}
                            </style>
                            <table className="datagrid nosort" id="productSearchPrefillMappingsEditGrid">
                                <thead>
                                    <tr>
                                        <th>Source</th>
                                        <th>(FROM) Source Property or Fixed Value</th>
                                        <th>(TO) Product Search Input</th>
                                        <th>Input is Readonly</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {mappingRows}
                                    <tr><td colSpan={5}><div className="toolbutton icons-action add color quickEntryAddBtn"
                                                             style={{ margin: 5, cursor: 'pointer', verticalAlign:'middle' }} title="Add New Mapping"
                                                             onClick={this.addNewMapping}></div><span style={{verticalAlign:'middle', fontSize:'13px'}}>Add New Mapping</span></td></tr>
                                </tbody>
                            </table>
                        </PanelContent>
                    </Panel>
                    : null }
            </div>
        );
    }
}


ProductSearchPrefillerCustomizer.newMappingSet= {
    IdProductSearchPrefillMappingSet: '',
    Name: 'New Mapping Set'
};
ProductSearchPrefillerCustomizer.newMappingId = 0;

class ProductSearchPrefillMappingRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            booleanDestinationPropertySelected: false,
            booleanOptions: []
        };
        this.onDestinationProductPropertyChange = this.onDestinationProductPropertyChange.bind(this);
        this.onStaticSourceValueChange = this.onStaticSourceValueChange.bind(this);
        this.onSourceBoChange = this.onSourceBoChange.bind(this);
        this.onReadonlyCheckboxChange = this.onReadonlyCheckboxChange.bind(this);
        this.onSourcePropertyChange = this.onSourcePropertyChange.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.loadBooleanDropdownForFixedValue = this.loadBooleanDropdownForFixedValue.bind(this);    
    }

    componentDidMount(){
        if(this.props.mapping.DestinationProductProperty){
         this.loadBooleanDropdownForFixedValue(this.props.mapping.DestinationProductProperty);
       }
    }

    onDestinationProductPropertyChange(e) {
        this.props.mapping.DestinationProductProperty = e.target.value;
        this.loadBooleanDropdownForFixedValue(e.target.value);      
        this.props.parent.setState({
            mappingSetChanged: true
        });
    }
    onStaticSourceValueChange (e) {
        this.props.mapping.StaticSourceValue = e.target.value;
        this.props.parent.setState({
            mappingSetChanged: true
        });
    }
    onSourceBoChange (e) {
        this.props.mapping.SourceBo = e.target.value;
        this.props.mapping.SourceProperty = '';
        this.props.mapping.StaticSourceValue = '';
        this.props.parent.setState({
            mappingSetChanged: true
        });
    }
    onSourcePropertyChange (e) {
        this.props.mapping.SourceProperty = e.target.value;
        this.props.parent.setState({
            mappingSetChanged: true
        });
    }
    onReadonlyCheckboxChange (e) {
        this.props.mapping.IsSearchInputReadonly = e.target.checked;
        this.props.parent.setState({
            mappingSetChanged: true
        });
    }
    onDeleteClick () {
        this.props.parent.state.updatedMappings.splice(this.props.index, 1);
        this.props.parent.setState({
            updatedMappings: this.props.parent.state.updatedMappings,
            mappingSetChanged: true
        });
    }
    loadBooleanDropdownForFixedValue(destinationProperty){
        var fields = quosal.customization.fields['BusinessObject']['QuoteItems'];
        var allFields = fields.standardFields.concat(fields.additionalFields);
        var field = allFields.where((f)=>f.FieldName == destinationProperty).firstOrNull();
        /* If source Field is  "Fixed Value" and the destination product property being set 
            is boolean, we want to provide users with a boolean dropdown*/
        if(this.props.mapping.SourceBo == '' && field && field.DataType =='Boolean')
        {
             var enumeration = quosal.metadata.enums['Boolean'];   
             var options = FormFieldInput.getOptionListFromEnumConfiguration(enumeration, this.props.mapping.StaticSourceValue, 'Boolean');
             this.setState({
                booleanDestinationPropertySelected: true,  
                booleanOptions: options
            });          
        }
        else{
          this.setState({
                booleanDestinationPropertySelected: false,  
                booleanOptions: []
            });  
        }    
    }
    render() {
        var sourceBoSelect = <div className="formselectfieldwrapper">
            <select className="formselectfield"
                    value={this.props.mapping.SourceBo}
                    onChange={this.onSourceBoChange}
            >{this.props.parent.optionLists['BoType']}</select>
        </div>;

        var sourcePropertySelectOrFixedValueInput;
        if (this.props.mapping.SourceBo == 'QuoteMain' || this.props.mapping.SourceBo == 'QuoteTabs') {
            sourcePropertySelectOrFixedValueInput = <div className="formselectfieldwrapper">
                <select className="formselectfield"
                        value={this.props.mapping.SourceProperty}
                        onChange={this.onSourcePropertyChange}
                >{this.props.parent.optionLists[this.props.mapping.SourceBo]}</select>
            </div>;
        } else {
            if(this.state.booleanDestinationPropertySelected)
            {
                sourcePropertySelectOrFixedValueInput = <div className="formselectfieldwrapper">
                <select className="formselectfield"
                        value={this.props.mapping.StaticSourceValue}
                        onChange={this.onStaticSourceValueChange}
                >{this.state.booleanOptions}</select>
            </div>;
            }
            else{
            sourcePropertySelectOrFixedValueInput = <div className="formfield">
                <input type="text"
                       onChange={this.onStaticSourceValueChange}
                       value={this.props.mapping.StaticSourceValue}
                />
            </div>;
            }
        }

        var destinationPropertySelect = <div className="formselectfieldwrapper">
            <select className="formselectfield"
                    value={this.props.mapping.DestinationProductProperty}
                    onChange={this.onDestinationProductPropertyChange}
            >{this.props.parent.optionLists['QuoteItems']}</select>
        </div>;

        var readonlyCheckbox = <input type="checkbox" checked={this.props.mapping.IsSearchInputReadonly} onChange={this.onReadonlyCheckboxChange} />;

        var deleteButton = <div className="toolbutton"
                                onClick={this.onDeleteClick}
                                style={{backgroundImage:'url(img/svgs/v1.0/Action_Delete.svg)', marginTop: -1}}
                                title="Remove this Mapping"></div>;

        return (
            <tr>
                <td className="prefillMappingSurceColumn">{sourceBoSelect}</td>
                <td className="sourceProperty">{sourcePropertySelectOrFixedValueInput}</td>
                <td className="productSearchImput">{destinationPropertySelect}</td>
                <td>{readonlyCheckbox}</td>
                <td className="productDeleteButton">{deleteButton}</td>
            </tr>
        );
    }
}


class ProductSearchFindAllLikeThisSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            updatedSearchField: app.settings.user.ProductSearchFindAllLikeThisSearchField,
            updatedLinkText: app.settings.user.ProductSearchFindAllLikeThisLinkText,
            updatedExcludedFields: app.settings.user.ProductSearchFindAllLikeThisExcludedFields,
        };

        this.onSearchFieldChange = this.onSearchFieldChange.bind(this);
        this.onLinkTextChange = this.onLinkTextChange.bind(this);
        this.onExcludedFieldSelectChange = this.onExcludedFieldSelectChange.bind(this);
        this.searchFieldIsUpdated = this.searchFieldIsUpdated.bind(this);
        this.linkTextIsUpdated = this.linkTextIsUpdated.bind(this);
        this.excludedFieldsAreUpdated = this.excludedFieldsAreUpdated.bind(this);
        this.settingsAreChanged = this.settingsAreChanged.bind(this);
        this.saveSettings = this.saveSettings.bind(this);
    }

    static showProductSearchFindAllLikeThisSettings(productSearchPage) {
        var customizerArgs = {
            productSearchPage: productSearchPage,
            saveEvent: quosal.events.create()
        };

        var componentDidMount = function () {
            this.props.customizerArgs.customizer = this; // `this` will refer to the instance of ProductSearchFindAllLikeThisSettings that did mount
        };
        var componentWillUnmount = function () {
            this.props.customizerArgs.customizer = null; // `this` will refer to the instance of ProductSearchFindAllLikeThisSettings that will unmount
        };

        var settingsEditor = <ProductSearchFindAllLikeThisSettings {...customizerArgs} customizerArgs={customizerArgs} componentDidMount={componentDidMount} componentWillUnmount={componentWillUnmount} />;

        var closeDialog = function () {
            if (this.customizer.settingsAreChanged()) {
                Dialog.confirmDelete({
                    title:'Changes will be lost',
                    width: '400px',
                    links:[
                        {title: 'Save Changes', callback: function() {
                            var saveError = this.saveEvent.call();
                            if (saveError) {
                                Dialog.close({callback: function() {
                                    Dialog.open({
                                        title: 'Error Saving Settings for Find All Like This',
                                        message: saveError,
                                        links: [{title:'OK', callback: Dialog.close}]
                                    });
                                }});
                            } else {
                                Dialog.closeAll();
                            }
                        }.bind(this)},
                        {title: 'Discard Changes', callback: Dialog.closeAll},
                        {title: "Cancel", callback: Dialog.close}
                    ],
                    message: 'You have unsaved changes. Are you sure you want to close the settings window?'
                });
            } else {
                Dialog.close();
            }
        }.bind(customizerArgs);
        Dialog.open({
            title:'Settings for Find All Like This',
            height:'auto',
            width:'600px',
            resizable: true,
            draggable: true,
            onClose: closeDialog,
            links:[
                {title: 'Save', callback: function () { customizerArgs.saveEvent.call() } },
                {title: 'Cancel', callback: Dialog.close}
            ],
            message: settingsEditor
        });
    }

    onSearchFieldChange (e) {
        this.setState({
            updatedSearchField: e.target.value
        });
    }
    onLinkTextChange (e) {
        this.setState({
            updatedLinkText: e.target.value
        });
    }
    onExcludedFieldSelectChange (e) {
        var newValue = this.state.updatedExcludedFields;
        newValue += (newValue ? ',' : '') + e.target.value;
        this.setState({
            updatedExcludedFields: newValue
        });
    }
    searchFieldIsUpdated () {
        return (this.state.updatedSearchField != app.settings.user.ProductSearchFindAllLikeThisSearchField);
    }
    linkTextIsUpdated () {
        return (this.state.updatedLinkText != app.settings.user.ProductSearchFindAllLikeThisLinkText);
    }
    excludedFieldsAreUpdated () {
        return (this.state.updatedExcludedFields != app.settings.user.ProductSearchFindAllLikeThisExcludedFields);
    }
    settingsAreChanged () {
        return this.searchFieldIsUpdated() || this.linkTextIsUpdated() || this.excludedFieldsAreUpdated();
    }
    saveSettings () {
        var settings = [];

        var searchFieldIsUpdated = this.searchFieldIsUpdated();
        if (searchFieldIsUpdated) {
            settings.push({
                key: 'ProductSearchFindAllLikeThisSearchField',
                value: this.state.updatedSearchField,
                isUserSetting: false
            });
        }

        var linkTextIsUpdated = this.linkTextIsUpdated();
        if (linkTextIsUpdated) {
            settings.push({
                key: 'ProductSearchFindAllLikeThisLinkText',
                value: this.state.updatedLinkText,
                isUserSetting: false
            });
        }

        var excludedFieldsAreUpdated = this.excludedFieldsAreUpdated();
        if (excludedFieldsAreUpdated) {
            settings.push({
                key: 'ProductSearchFindAllLikeThisExcludedFields',
                value: this.state.updatedExcludedFields,
                isUserSetting: false
            });
        }

        if (settings.length > 0) {
            var saveApi = quosal.api.settings.saveSettings(settings);
            saveApi.finished = function (msg) {
                if (searchFieldIsUpdated) {
                    app.settings.user.ProductSearchFindAllLikeThisSearchField = this.state.updatedSearchField;
                }
                if (linkTextIsUpdated) {
                    app.settings.user.ProductSearchFindAllLikeThisLinkText = this.state.updatedLinkText;
                }
                if (excludedFieldsAreUpdated) {
                    app.settings.user.ProductSearchFindAllLikeThisExcludedFields = this.state.updatedExcludedFields;
                }
                Dialog.close();
                this.props.productSearchPage.forceUpdate();
            }.bind(this);
            Dialog.setIsWorking(true);
            saveApi.call();
        } else {
            Dialog.close();
        }
    }
    UNSAFE_componentWillMount() {
        var fields = quosal.customization.fields["BusinessObject"]["QuoteItems"].allFields;
        var fieldConfigurations = quosal.customization.fields["BusinessObject"]["QuoteItems"].fieldConfigurations;
        var fieldNameToLabel = quosal.customization.fields.getFieldNameToDropdownLabelDictionary(fields, fieldConfigurations);

        this.fieldNameToLabel = fieldNameToLabel;
        this.dropdownOptionsForSearchField = quosal.customization.fields.getBOPropertyOptionList({
            fields: fields,
            fieldConfigurations: fieldConfigurations,
            fieldNameToLabel: fieldNameToLabel,
            initialOptions: [<option key={'empty'} value={''}>{''}</option>],
            conditionToIncludeField: function (field) {
                return (field.DataType === 'String');
            }
        });
        this.dropdownOptionsForExcludedFields = quosal.customization.fields.getBOPropertyOptionList({
            fields: fields,
            fieldConfigurations: fieldConfigurations,
            fieldNameToLabel: fieldNameToLabel,
            initialOptions: [<option key={'empty'} value={''}>{'Select here to add to the list.'}</option>]
        });
    }
    componentDidMount() {
        if(this.props.saveEvent) {
            this.props.saveEvent.unbind().bind(this.saveSettings);
        }

        $(this.refs.excludedFields).sortable({
            connectWith: '.excludedField',
            stop: (function (e, ui) {
                var updatedExcludedFields = '';
                var connector = '';
                var divs = $(this.refs.excludedFields).find('div.excludedField');
                for (var i = 0; i < divs.length; i++) {
                    updatedExcludedFields += connector + $(divs[i]).attr('value');
                    connector = ',';
                }
                this.setState({
                    updatedExcludedFields: updatedExcludedFields
                });
                return false;
            }).bind(this)
        });

        if (this.props.componentDidMount) {
            this.props.componentDidMount.call(this);
        }
    }

    componentWillUnmount() {
        if (this.props.componentWillUnmount) {
            this.props.componentWillUnmount.call(this);
        }
    }
    
    render () {
        var excludedFieldsValue = this.state.updatedExcludedFields;
        var excludedFieldsArray = excludedFieldsValue ? excludedFieldsValue.split(',') : [];
        var excludedFieldsElements = [];
        for (var i = 0; i < excludedFieldsArray.length; i++) {
            excludedFieldsElements.push(
                <ProductSearchFindAllLikeThisSettingsExcludedField key={i} index={i} parent={this} fieldName={excludedFieldsArray[i]}
                                                                   label={this.fieldNameToLabel[excludedFieldsArray[i]]} />);
        }


        return (<div className="panel">
            <div className="prose">
                Set a Key Field to activate this feature. If you do, an additional column will appear in the product search results table.
                Click the link in this new column in order to see the results of a specific sub-search. This sub-search
                only returns results that have a match in the Key Field to the to the product result row you clicked on.
                The sub-search also uses all of the product search criteria you just used in the main search, although you can
                exempt specific fields from this by adding them to the list labeled "Always Show All Results for These Fields."
            </div>
            <br/>
            <br/>
            <div className="formfieldlabel"><label htmlFor="findAllLikeThisSearchField" >Key Field</label></div>
            <div className="formselectfieldwrapper" >
                <select className="formselectfield" id="findAllLikeThisSearchField" onChange={this.onSearchFieldChange} value={this.state.updatedSearchField}>{this.dropdownOptionsForSearchField}</select>
            </div>
            <div className="formfieldlabel"><label htmlFor="findAllLikeThisLinkText" >Link Text</label></div>
            <div className="formfield" >
                <input type="text" id="findAllLikeThisLinkText" onChange={this.onLinkTextChange} placeholder={ProductSearch.findAllLikeThisDefaultLinkText} value={this.state.updatedLinkText} />
            </div>
            <div className="formfieldlabel"><label htmlFor="findAllLikeThisExcludedFields" >Always Show All Results for These Fields</label></div>
            <div className="formselectfieldwrapper" >
                <select className="formselectfield" id="findAllLikeThisExcludedFields" onChange={this.onExcludedFieldSelectChange} value={''}>{this.dropdownOptionsForExcludedFields}</select>
            </div>
            <div ref="excludedFields" className="prose">{excludedFieldsElements}</div>
        </div>);
    }
}

class ProductSearchFindAllLikeThisSettingsExcludedField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        // This binding is necessary to make `this` work in the callback
        this.deleteThis = this.deleteThis.bind(this);
    }
    deleteThis() {
        var excludedFieldsArray = this.props.parent.state.updatedExcludedFields.split(',');
        excludedFieldsArray.splice(this.props.index, 1);
        var excludedFieldsUpdateValue = excludedFieldsArray.join(',');
        this.props.parent.setState({
            updatedExcludedFields: excludedFieldsUpdateValue
        });
    }
    render() {
        var deleteButtonStyle = {
            margin: '4px 10px',
            verticalAlign: 'middle'
        }
        var labelStyle = {
            verticalAlign: 'middle',
            cursor: 'move'
        }
        var extraLabel = '';
        if (this.props.index === 0) {
            extraLabel = <i>{' (Results will sort by this first field)'}</i>;
        }

        return (<div className="excludedField" value={this.props.fieldName}>
            <div className="icons-action delete color link" onClick={this.deleteThis} style={deleteButtonStyle} title="Remove from Excluded Criteria" ></div>
            <span style={labelStyle}>{this.props.label}{extraLabel}</span>
        </div>);
    }
}

class ProductSearchFindAllLikeThisLink extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        };

        this.doClick = this.doClick.bind(this);
    }

    doClick(e) {
        this.props.onClick(this, e);
    }

    render() {
        if (this.props.keyValue === '' || this.props.keyValue == null) {
            return null;
        } else if (this.state.isLoading) {
            return <Spinner />;
        } else {
            return <a className={'link'} onClick={this.doClick}>{this.props.linkText}</a>;
        }
    }
}
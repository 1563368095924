import { useCallback, useEffect, useMemo, useState } from "react";

import { columns } from "../ActionButtons";
import { getAllColumns, getHeaders, getItems, getRows } from "../Helpers";
import useDarkMode from "../WidgetToolbar/widgetToolbarHelpers";
import { useAppGlobalContext } from "../context/globalContext";
import { getCKEditorTabsIds } from "../helpers/getCKEditorTabsIds";
import "../styles/grid.css";
import useDataGridBoilerplateHelpers from "./dataGridBoilerplateHelpers";

const useDataGridBoilerplateContainer = (props) => {
	const {
		tabItem,
		expanded,
		openCustomizeGrid,
		setLoading,
		setChange,
		onRemove,
		marginModalRef,
		newRecordRef,
		priceSourceModalRef
	} = props;
	const { handleEdit, updateProductInCKEditor } =
		useDataGridBoilerplateHelpers(props);

	const [openDialog, setOpenDialog] = useState(false);
	const [gridData, setGridData] = useState([]);
	const [rowToDelete, setRowToDelete] = useState(null);
	const [gridRows, setGridRows] = useState(getRows(tabItem?.IdQuoteTabs));
	const [orderedRows, setOrderedRows] = useState(getRows(tabItem?.IdQuoteTabs));
	const [selectedRows, setSelectedRows] = useState([]);
	const [openMargins, setOpenMargins] = useState(false);
	const [editableCell, setEditableCell] = useState(null);
	const [editFieldPrevValue, setEditFieldPrevValue] = useState(null);
	const [modalData, setModalData] = useState({});
	const [anchorEl, setAnchorEl] = useState(null);
	const [updateApiRequests, setUpdateApiRequests] = useState([]);
	const [defaultDropdownValue, setDefaultDropdownValue] = useState("");

	const darkMode = useDarkMode();
	
	const ids = useMemo( ()=>
		getCKEditorTabsIds(app?.currentQuote?.HTMLContentForAutoSaving)
	, [app?.currentQuote?.HTMLContentForAutoSaving]);

	const { isDisabledQuoteActions, contentGrid, ckeditor, rowActions, buildNotesDialog } = useAppGlobalContext();

	const isStandardUser =
		!app.currentUser.IsContentMaintainer &&
		!app.currentUser.IsAdministrator &&
		!app.currentUser.IsStandardPlus;
	const isStandardUserPlus =
		!app.currentUser.IsContentMaintainer &&
		!app.currentUser.IsAdministrator &&
		app.currentUser.IsStandardPlus;

	const isStandardUserAndProtectedTab = isStandardUser && tabItem?.IsProtectedTab;
	const isStandardUserAndDisableNewProductCreation =
		isStandardUser && tabItem?.HasLimitOnNewItems;

	const disableNewProductCreationStyle =
		isStandardUserAndDisableNewProductCreation ||
			isStandardUserAndProtectedTab ||
			isDisabledQuoteActions
			? { pointerEvents: "none", opacity: 0.38 }
			: {};

	const isLockStandardUsersOutOfItemEdit = quosal.settings.getValue(
		"LockStandardUsersOutOfItemEdit"
	);
	const isStandardUserAndLockItemEdit = isStandardUser && isLockStandardUsersOutOfItemEdit;

	const handleModalClose = () => {
		updateGridRows();
		setAnchorEl(null);
		setModalData({});
	};

	const open = Boolean(anchorEl);
	const id = open ? "price-modifier-popover" : undefined;

	const priceSourceLoaded = quosal.sell.product.sourceGroups != null;

	const handlePriceSourceModalClick = (e, params) => {
		if (e) {
			const dataModal = {
				id: "price-source-popover",
				anchorEl: e.currentTarget,
				params,
				grid: contentGrid,
				type: "cloudsourcing",
				updateGridRows
			};
			priceSourceModalRef.current.showModal(true, dataModal);
		}
	};

	(() => {
		const getUpdatedRows = getRows(tabItem?.IdQuoteTabs);
		if (JSON.stringify(gridRows) !== JSON.stringify(getUpdatedRows)) {
			setGridRows(() => getUpdatedRows);
		}
	})();

	const handleInsertSectionGrouping = useCallback((sectionType) => {

		const tab = getCurrentTab();
		const tabItems = getItems(tab.IdQuoteTabs);
		let internalSelectedRowIndexes = selectedRows?.map((itemId) => tabItems.find((tabItem)=> tabItem.IdQuoteItems === itemId)?.SortOrder || 0) || [];
		let newItems = [];

		if (!internalSelectedRowIndexes.length) {
			if (sectionType === 'SubHeader') {
				internalSelectedRowIndexes.push(0);
			} else if (sectionType === 'SubFooter') {
				internalSelectedRowIndexes.push(tabItems.length);
			} else if (sectionType === 'Comment') {
				internalSelectedRowIndexes.push(tabItems.length);
			}
		}

		for (const rowIndex of internalSelectedRowIndexes) {
			let relativeIndex = rowIndex;
			if(sectionType ===  'SubFooter' || sectionType === 'Comment') {
				relativeIndex += 1;
			}

			let newItem = {
				table: 'QuoteItems',
				IdQuoteMain: app.currentQuote.IdQuoteMain,
				IdQuoteTabs: tab.IdQuoteTabs,
				id: quosal.util.generateGuid(),
				LineType: sectionType,
				IsTotalsIncluded: false,
				SortOrder: relativeIndex
			}

			if (sectionType === 'SubHeader') {
				newItem.LongDescription = 'New Section';
			} else if (sectionType === 'SubFooter') {
				newItem.LongDescription = 'Section Subtotal';
			}
			newItems.push(newItem);
		}

		var insertSectionGroupingApi = quosal.api.product.insertSectionGrouping(
			app.currentQuote.IdQuoteMain, tab.IdQuoteTabs, newItems.map(i=>i.SortOrder),  newItems.map(i=>i.id), sectionType
		);

		insertSectionGroupingApi.finished = function (msg) {
			setGridRows(getRows(tabItem?.IdQuoteTabs));
			for(const newItem of newItems) {
				const savedItem = msg.partialResponse.items[newItem.id];
				const mergedItem = {...newItem, ...savedItem};
				
				var quoteInsertIndex = app.currentQuote.Items?.findIndex(s => s.IdQuoteTabs === tab.IdQuoteTabs && s.SortOrder === mergedItem.SortOrder) || 0;
				
				tabItems.splice(mergedItem.SortOrder, 0, mergedItem);
				app.currentQuote.Items.splice(quoteInsertIndex, 0, mergedItem);
				
			}
			quosal.sell.quote.updateFromApiResponse(msg);
			setLoading(false);

		}
		setLoading(true);
		insertSectionGroupingApi.call();
	})


	const handleSelectAll = useCallback(
		(checked) => {
			if (checked) {
				const allRowIds = gridRows.map((row) => row.idQuoteItems);
				setSelectedRows(allRowIds);
			} else {
				setSelectedRows([]);
			}
		},
		[gridRows]
	);

	const handleRowSelection = useCallback(
		(rowsToDelete, toNotDelete) => {
			const newSelectedRows = Array.isArray(rowsToDelete) ? rowsToDelete : [rowsToDelete];
			setSelectedRows(() => {
				if (toNotDelete) {
					return [...selectedRows, ...newSelectedRows];
				}
				return selectedRows.filter((idRow) =>
					rowsToDelete !== idRow
				);
			});
		},
		[selectedRows]
	);

	const handleDeleteConfirmed = useCallback(
		(rowsToDelete) => {
			handleRowSelection(
				rowsToDelete.map((row) => row.idQuoteItems),
				false
			);
			setLoading(true);
			const deleteApi = quosal.api.data.delete(
				{
					table: "QuoteItems",
					where: [
						{
							field: "IdQuoteItems",
							operator: "In",
							value: rowsToDelete.map((row) => row.idQuoteItems)
						}
					]
				},
				app.currentQuote.IdQuoteMain
			);
			deleteApi.finished = function (msg) {
				quosal.sell.quote.updateFromApiResponse(msg);
				setGridRows(
					gridRows.filter((row) =>
						rowsToDelete.some((rows) => rows.idQuoteItems !== row.idQuoteItems)
					)
				);
				setOrderedRows(
					orderedRows.filter((row) =>
						rowsToDelete.some((rows) => rows.idQuoteItems !== row.idQuoteItems)
					)
				);
				setLoading(false);
				setTimeout(() => {
					if (ids !== null && ids.includes(tabItem?.IdQuoteTabs)) {
						ckeditor?.execute && ckeditor.execute("updateProduct", tabItem?.IdQuoteTabs);
					}
				}, 50);
			};
			deleteApi.call();
			onRemove(false);
		},
		[
			ckeditor,
			gridRows,
			handleRowSelection,
			ids,
			onRemove,
			orderedRows,
			setLoading,
			tabItem?.IdQuoteTabs
		]
	);

	const isDisabledGridActions = () => !selectedRows.length;

	const handleDelete = (row) => {
		onRemove(true, [row], handleDeleteConfirmed);
	};

	const handleClose = () => {
		setOpenDialog(false);
		setRowToDelete([]);
	};

	const updateFieldCallback = useCallback(
		(el) => {
			el.callback.call(contentGrid, gridData, updateProductInCKEditor);
		},
		[contentGrid, gridData, updateProductInCKEditor]
	);

	const titlesToRemove = {
		cut: "Cut",
		copy: "Copy",
		paste: "Paste",
	};

	const filteredRowActions = rowActions.filter(
		(el) =>
			el.title !== titlesToRemove.cut &&
			el.title !== titlesToRemove.copy &&
			el.title !== titlesToRemove.paste
	);

	const getCurrentTab = useCallback(() => {
		let currentTab = [];
		app.currentQuote.Tabs.map((tab) => {
			if (tab.IdQuoteTabs === tabItem?.IdQuoteTabs) {
				currentTab = tab;
			}
		});
		return currentTab;
	}, [tabItem?.IdQuoteTabs]);

	const calculateMargins = (isShown) => {
		const currentTab = getCurrentTab();
		const selectedGridRows = selectedRows;

		const idQuoteMain = currentTab.IdQuoteMain;
		const productIds = [];
		for (let i = 0; i < selectedGridRows.length; i++) {
			productIds.push(selectedGridRows[i]);
		}

		const calculateMarginsApi = quosal.api.product.calculateMargins(idQuoteMain, productIds);
		calculateMarginsApi.finished = function (msg) {
			const marginsProperties = {
				price: msg.price,
				cost: msg.cost,
				profitAmount: msg.profitAmount,
				marginPercent: msg.marginPercent,
				markupPercent: msg.markupPercent,
				suggestedPrice: msg.suggestedPrice,
				discountPercent: msg.discountPercent
			};

			const marginModalData = {
				titleText: "Product Margins",
				props: marginsProperties
			};

			marginModalRef.current.showModal(isShown, marginModalData);
		};

		calculateMarginsApi.call();
	};

	const colParams = {
		gridRows,
		handleDelete,
		handleEdit,
		handleRowSelection,
		handleSelectAll,
		selectedRows,
		handlePriceSourceModalClick,
		priceSourceLoaded,
		grid: contentGrid
	};

	const saveGroupMasterQuantity = useCallback(
		(newRow) => {
			const currentTab = getCurrentTab();
			let currentProduct = null;
			app.currentQuote.Items.map((item) => {
				if (item.IdQuoteItems === newRow.idQuoteItems) {
					currentProduct = item;
				}
			});
			if (currentTab.TabGroup && currentTab.IsGroupQuantityMaster) {
				app.currentQuote.Tabs.map((tab) => {
					if (
						tab.TabGroup === currentTab.TabGroup &&
						tab.IdQuoteTabs !== currentTab.IdQuoteTabs
					) {
						const tabItems = getItems(tab.IdQuoteTabs);
						tabItems.map((item) => {
							if (
								item.GroupingCode === currentProduct.GroupingCode ||
								item.ManufacturerPartNumber ===
									currentProduct.ManufacturerPartNumber
							) {
								item.Quantity = newRow.Quantity;
								app.currentQuote.Items.map((product) => {
									if (product.IdQuoteItems === item.IdQuoteItems) {
										product.Quantity = item.Quantity;
									}
								});
							}
						});
					}
				});
			}
		},
		[getCurrentTab]
	);

	const saveEditedField = useCallback(
		(newRow, editedCell) => {
			if (newRow[editedCell] !== editFieldPrevValue) {
				const fieldChangeUpdates = [];
				const apiRequests = updateApiRequests;
				fieldChangeUpdates.push({
					fields: { [editedCell]: newRow[editedCell] },
					queries: [
						{
							table: "QuoteItems",
							where: [
								{
									field: "IdQuoteItems",
									operator: "Equals",
									value: newRow.idQuoteItems
								}
							]
						}
					]
				});

				saveGroupMasterQuantity(newRow);
				let requiredUpdateContent = false;
				const updateApi = quosal.api.data.update(
					fieldChangeUpdates,
					app.currentQuote.IdQuoteMain,
					requiredUpdateContent
				);
				apiRequests.push(updateApi.opId);
				setUpdateApiRequests(apiRequests);

				updateApi.setFlag("fieldChanged");

				updateApi.finished = function (msg) {
					if (fieldChangeUpdates.length === 0 && msg.partialResponse) {
						quosal.sell.quote.updatePartial(msg.partialResponse);
						if (
							msg.partialResponse.partialQuote &&
							msg.partialResponse.partialQuote.ShouldGeneratePdfAfterSave
						) {
							quosal.sell.product.generatePdf(app.currentQuote.IdQuoteMain, msg.opId);
						}
					}

					if (fieldChangeUpdates.length === 0 && msg.quote) {
						const unsaved = props.quote.Items.where((s) => s.isUnsaved);
						for (let i = 0; i < unsaved.length; i++) {
							msg.quote.Items.push(unsaved[i]);
						}
						quosal.sell.quote.update(msg.quote);
					}

					apiRequests.splice(apiRequests.indexOf(msg.opId), 1);
					setUpdateApiRequests(apiRequests);
					const updateQuote = updateApiRequests.length === 0;
					if (updateQuote) {
						const forceUpdateApi = quosal.api.quote.forceUpdate(
							app.currentQuote.IdQuoteMain
						);
						forceUpdateApi.finished = function (msg2) {
							quosal.sell.quote.updateFromApiResponse(msg2);
							setGridRows(getRows(tabItem?.IdQuoteTabs));
							setTimeout(() => {
								if (ids !== null && ids.includes(tabItem?.IdQuoteTabs)) {
									ckeditor?.execute && ckeditor.execute("updateProduct", tabItem?.IdQuoteTabs);
								}
							}, 50);
						};
						forceUpdateApi.call();
					}
				};

				updateApi.call();
			}
		},
		[
			ckeditor,
			editFieldPrevValue,
			ids,
			props?.quote?.Items,
			saveGroupMasterQuantity,
			tabItem?.IdQuoteTabs,
			updateApiRequests
		]
	);

	const actionButtons = columns({
		...colParams,
		isStandardUserAndProtectedTab,
		isStandardUserAndLockItemEdit,
	});

	const handleDateChange = useCallback(
		(cellValues, date) => {
			const parsedDate = dayjs(date.$d).format("MM/DD/YYYY");
			cellValues.row[cellValues.field] = parsedDate;
			saveEditedField(cellValues.row, cellValues.field);
		},
		[saveEditedField]
	);

	const handleOptionChange = useCallback(
		(cellValues, event) => {
			const { value } = event.target;
			cellValues.row[cellValues.field] = value;
			saveEditedField(cellValues.row, cellValues.field);
		},
		[saveEditedField]
	);

	const handleModalClick = (e) => {
		if (e) {
			setAnchorEl(e.currentTarget);
		}
	};

	const gridColumns = getAllColumns(
		actionButtons,
		tabItem?.IdQuoteTabs,
		buildNotesDialog,
		handleDateChange,
		handleOptionChange,
		setChange,
		isStandardUserAndProtectedTab,
		handleModalClick,
		setModalData,
		ckeditor,
		contentGrid
	).map((item) => (item?.field === "selection" ? { ...item, width: 40 } : item));

	const validateEditedValue = useCallback(
		(newRow) => {
			const headers = getHeaders(tabItem?.IdQuoteTabs);
			const dataType = headers.filter((header) => header.FieldName === editableCell)[0]
				.DataType;
			let errorMsg = null;

			switch (editableCell) {
				case "ExtendedCost":
				case "ExtendedPrice":
				case "ExtendedSuggestedPrice":
				case "RecurringExtendedCost":
				case "RecurringExtendedPrice":
				case "RecurringExtendedSuggestedPrice":
					errorMsg = "Should not edit";
					break;
				case "QuoteItemPrice":
				case "SuggestedPrice":
				case "RecurringPrice":
				case "RecurringSuggestedPrice":
					const newValue = app.currentQuote.parseCurrency(newRow[editableCell]);
					if (newValue > quosal.data.MAX_INTEGER) {
						errorMsg = quosal.data.MAX_INTEGER;
					}
					break;
				case "CostModifier":
				case "RecurringCostModifier":
					let value = newRow[editableCell];
					const validString = value.match(quosal.costModifierRegex);

					if (!validString) {
						errorMsg = "Not valid string";
					}
					break;
				case "ImportedItemDiscountStartDate":
				case "PromotionExpirationDate":
				case "ImportedQuoteDiscountStartDate":
				case "ImportedQuoteDiscountExpirationDate":
				case "RebateExpirationDate":
				case "ModifyDate":
				case "ExternalQuoteExpiration":
					value = newRow[editableCell];
					if (!dayjs(value).isValid()) {
						errorMsg = "Not a date";
					}
					break;
				default:
					errorMsg = null;
					break;
			}

			if (dataType === "Double" && Number.isNaN(Number(newRow[editableCell]))) {
				errorMsg = "Not a number";
			}

			return errorMsg;
		},
		[editableCell, tabItem?.IdQuoteTabs]
	);

	const shouldReturnPreviousValue = useCallback(
		() => editableCell !== "CostModifier" && editableCell !== "RecurringCostModifier",
		[editableCell]
	);

	const processRowUpdate = useCallback(
		(newRow) => {
			if (editableCell !== null) {
				if (validateEditedValue(newRow) === null) {
					saveEditedField(newRow, editableCell);

					if (editableCell === "QuoteItemPrice" || editableCell === "Quantity") {
						newRow.ExtendedPrice =
							editableCell === "QuoteItemPrice"
								? app.currentQuote.parseCurrency(
									newRow.Quantity * +newRow[editableCell]
								)
								: app.currentQuote.parseCurrency(
									newRow.QuoteItemPrice * +newRow[editableCell]
								);
					}
				} else if (shouldReturnPreviousValue()) {
					newRow[editableCell] = editFieldPrevValue;
				}
			}
			return newRow;
		},
		[
			editFieldPrevValue,
			editableCell,
			saveEditedField,
			shouldReturnPreviousValue,
			validateEditedValue
		]
	);

	const updateGridRows = useCallback(() => {
		setGridRows(getRows(tabItem?.IdQuoteTabs));
	}, [tabItem?.IdQuoteTabs]);

	const getFixRowOrderUpdates = useCallback(
		(params) => {
			let offset = params.oldIndex - params.targetIndex > 0 ? -.05 : .05;
			let dropIndex = params.targetIndex;
			
			if (orderedRows[params.targetIndex].IsPackageItem ) {
				const parentRow = orderedRows[params.targetIndex].parentRow;
				const bundleSize = parentRow.childRows.length;
				//Move dropped non bundle item to the top of the package if its moving upwards
				if(offset < 0) {
					dropIndex = parentRow.SortOrder;
				} else {
					//Move dropped non bundle item to the bottom of the package if its moving downwards
					dropIndex = parentRow.SortOrder + bundleSize;
				}
				//If the dropped Item is also a packageHeader, move all of its children with it.
				if(params.row.IsPackageHeader) {
					const offsetRatio = 1 / (params.row.childRows.length + 1);
					//Account for the packageheader at the top of the bundle we are dragging in 
					if(offset < 0) {
						dropIndex += -1;
						offset = 0;
					}
					//Now move all bundleItems below the package header
					params.row.childRows.forEach((cRow, i) => {
						orderedRows.find((row) => row.idQuoteItems === cRow).SortOrder = (dropIndex + ( (i + 1) * offsetRatio) + offset);
					});
				}
				else if(params.row.IsPackageItem) {					
					//do not allow package items to leave the bounds of the bundle
					const parentOfDraggedItem = orderedRows.find((row)=>row.childRows?.includes(params.row.idQuoteItems));
					const bundleSizeOfDroppedParent = parentOfDraggedItem.childRows.length;
					dropIndex = Math.min(Math.max(dropIndex, parentOfDraggedItem.SortOrder + 1), parentOfDraggedItem.SortOrder + bundleSizeOfDroppedParent);
				}

			} else if(params.row.IsPackageHeader) {
				//when moving a package header down, and land on a different package header 
				//update offset of the moved package header by the other pagckages bundle size
				if(orderedRows[params.targetIndex].IsPackageHeader) {
					const droppedOnBundleSize = orderedRows[params.targetIndex].childRows.length;
					dropIndex += droppedOnBundleSize;
				}

				const bundleSize = params.row.childRows.length;
				//If a package header is moved up, move all items its now above down by the bundle size
				for(let i = params.targetIndex; i <= params.oldIndex + 1; i++) {
					if(orderedRows.length <= i || i < 0) continue;
					orderedRows[i].SortOrder = orderedRows[i].SortOrder + bundleSize;
				}
				const offsetRatio = 1 / (params.row.childRows.length + 1);
				//Now move all bundleItems below the package header
				params.row.childRows.forEach((cRow, i) => {
					orderedRows.find((row) => row.idQuoteItems === cRow).SortOrder = (dropIndex + ( (i + 1) * offsetRatio) + offset);
				});

			}  else if(params.row.IsPackageItem) {
				//do not allow package items to leave the bounds of the bundle
				const parentRow = params.row.parentRow;
				const bundleSize = parentRow.childRows.length;
				dropIndex = Math.min(Math.max(dropIndex, parentRow.SortOrder + 1), parentRow.SortOrder + bundleSize);
				
			}  else if (orderedRows[params.targetIndex].IsPackageHeader && offset > 0) {
				//If a drop occured below a package header
				const bundleSize = orderedRows[params.targetIndex].childRows.length;
				dropIndex += bundleSize;
			}

			orderedRows.find((row) => row.idQuoteItems === params.row.idQuoteItems).SortOrder = dropIndex + offset;
			orderedRows.sort((a, b) => a.SortOrder - b.SortOrder)
			orderedRows.forEach((row, index) => {
				row.SortOrder = index;
			});

			const rowsToUpdate = [];
			for(let i = 0; i < orderedRows.length; i++) {
				if(orderedRows[i].SortOrder !== gridRows.find((row=> row.idQuoteItems === orderedRows[i].idQuoteItems)).SortOrder) {
					rowsToUpdate.push(orderedRows[i]);
				}
			}
		
			//The row being dragged is dropeed in the same row it was dragged from
			if(rowsToUpdate.length === 0) {
				return [];
			}

			return rowsToUpdate.map((row, i) => {
					
					return {
						fields: {
							SortOrder: row.SortOrder
						},
						queries: [
							{
								table: "QuoteItems",
								where: [
									{
										field: "IdQuoteItems",
										operator: "Equals",
										value: row.idQuoteItems
									}
								]
							}
						]
					};
				})
			},
		[gridRows, orderedRows]
	);

	const handleRowOrderChange = useCallback(
		(params) => {
			const updates = getFixRowOrderUpdates(params);
			if(updates.length === 0) {
				setGridRows(getRows(tabItem?.IdQuoteTabs));
				return;
			}
			setLoading(true)
			const updateApi = quosal.api.data.update(updates, app.currentQuote.IdQuoteMain);
			updateApi.setFlag("fixRowOrder");
			updateApi.finished = function (msg) {
				setTimeout(() => {
					if (ids !== null && ids.includes(tabItem?.IdQuoteTabs)) {
						ckeditor?.execute && ckeditor.execute("updateProduct", tabItem?.IdQuoteTabs);
					}
				}, 50);
				quosal.sell.quote.updateFromApiResponse(msg);
				setGridRows(getRows(tabItem?.IdQuoteTabs));
				setLoading(false);
			};
			updateApi.call();
		},
		[ckeditor, getFixRowOrderUpdates, ids, setLoading, tabItem?.IdQuoteTabs]
	);

	const handleCellClick = useCallback(
		(param, event) => {
			event.stopPropagation();
			const headers = getHeaders(tabItem?.IdQuoteTabs);
			const header = headers.filter((h) => h.FieldName === param.field);
			if (header.length) {
				const dataType = headers.filter((h) => h.FieldName === param.field)[0].DataType;
				if (dataType === "Boolean") {
					saveEditedField(param.row, param.field);
				}
			}
		},
		[saveEditedField, tabItem?.IdQuoteTabs]
	);

	const gridStyle =
		gridRows.length === 0
				? {
					"& .MuiDataGrid-columnHeaderTitleContainerContent input": {
						accentColor: "#000",
						pointerEvents: "none",
						opacity: 0.3
					},
					"& .MuiDataGrid-footerContainer": {
						marginBottom: "-34px",
						borderTop: "0",
					},
					"& .MuiDataGrid-cell--editable" : {
					cursor: "pointer"
					},
					"& .MuiDataGrid-overlayWrapper": {
						height: "50px"
					},
			}
			: {
					".package": {
						bgcolor: darkMode ? "#191919" : "#e0e0e0",
						fontWeight: "bold"
					},
					".packageItem": {
						bgcolor: darkMode ? "#252525" : "#eaeaea"
					},
					"& .MuiDataGrid-rowReorderCell": {
						color: "#2E3F80"
					},
					"& .MuiDataGrid-cell--editing:focus-within": {
						outline: "solid #2E3F80 1px !important"
					},
					"& .MuiDataGrid-footerContainer": {
						display: "none"
					},
					"& .MuiDataGrid-cell--editable" : {
						cursor: "pointer"
					},
			};

	const addRecordStyle = {
		marginTop: "-4px",
		padding: "0 0 8px 20px",
		fontSize: "1rem",
		textTransform: "uppercase",
		cursor: "pointer",
		fontWeight: 500,
		...(darkMode ? { color: "var(--dark-mode-blue-color)" } : { color: "#2E3F80" })
	};

	const handleToDeleteRows = () => {
		const rowsToDelete = gridRows.filter((row) =>
			selectedRows.some((selRow) => selRow === row.idQuoteItems)
		);
		if (selectedRows.length) {
			onRemove(true, rowsToDelete, handleDeleteConfirmed);
		}
	};

	const groups = (() => {
		const data = quosal.util.clone(quosal.sell.product.sourceGroups);
		if (data && data.length) {
			data.sort((a, b) => {
				const textA = a.GroupName ? $.trim(a.GroupName.toUpperCase()) : "";
				const textB = b.GroupName ? $.trim(b.GroupName.toUpperCase()) : "";
				return textA < textB ? -1 : textA > textB ? 1 : 0;
			});
		}

		return data;
	})();

	for (let i = 0; i < groups.length; i++) {
		if (groups[i].IsPrimary && !defaultDropdownValue.length) {
			setDefaultDropdownValue(groups[i].IdProductSourceGroup);
		}
	}

	const openNewRecordDialog = (isShown) => {
		const currentTab = getCurrentTab();
		const tabId = tabItem?.IdQuoteTabs;
		const dataDialog = {
			currentTab,
			updateGridRows,
			tabId,
			ids,
			defaultDropdownValue
		};
		newRecordRef.current.showModal(isShown, dataDialog);
	};


	useEffect(() => {
		const insertBefore = selectedRows.length > 0 ? selectedRows[0] : undefined;
		contentGrid.handleInsertBefore(insertBefore);
		contentGrid.handleSelectedRows(selectedRows);
	}, [contentGrid, gridRows, selectedRows]);

	useEffect(() => {
		const gridProps = {
			selectedShowingRows: gridRows
				.map((el, i) => (selectedRows.includes(el.idQuoteItems) ? i : null))
				.filter((el) => el !== null),
			props: {
				rows: gridRows,
				headers: getHeaders(tabItem?.IdQuoteTabs),
				configuration: {
					gridName: "QuoteContent"
				}
			}
		};
		setGridData(gridProps);
	}, [selectedRows, gridRows, tabItem?.IdQuoteTabs]);

	return {
		openNewRecordDialog,
		handleToDeleteRows,
		setSelectedRows,
		processRowUpdate,
		handleRowOrderChange,
		handleCellClick,
		handleClose,
		handleDeleteConfirmed,
		setOpenMargins,
		getCurrentTab,
		handleModalClose,
		setEditableCell,
		setEditFieldPrevValue,
		isDisabledGridActions,
		calculateMargins,
		updateFieldCallback,
		handleInsertSectionGrouping,
		disableNewProductCreationStyle,
		gridStyle,
		addRecordStyle,
		isStandardUserAndProtectedTab,
		gridRows,
		gridColumns,
		selectedRows,
		openDialog,
		rowToDelete,
		openMargins,
		ckeditor,
		ids,
		contentGrid,
		id,
		open,
		anchorEl,
		modalData,
		filteredRowActions,
		expanded,
		openCustomizeGrid,
		isStandardUser,
		isStandardUserAndDisableNewProductCreation,
		isStandardUserPlus,
		isStandardUserAndLockItemEdit
	};
};

export default useDataGridBoilerplateContainer;

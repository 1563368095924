export const commomSelectStyles = {
	height: "90%",
	width: "90%",
	"& .MuiSelect-select": {
		display: "flex",
		alignItems: "center"
	}
};

export const handleDisableDuplicateDQCondition = (isDuplicate, callback) => {
    if (isDuplicate) {
        callback(true);
    } else {
        callback(false);
    }
};

export const thenDisplayQuestionFormatter = (sortOrder, questionText) => {
	return `${sortOrder}. ${questionText}`;
};
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import useDarkMode from "../../WidgetToolbar/widgetToolbarHelpers";

// eslint-disable-next-line react/display-name
const DeleteModalComponent = forwardRef((props, ref) => {
	const [isOpen, setIsOpen] = useState(false);
	const [modalData, setModalData] = useState({});

	const darkMode = useDarkMode();

	useImperativeHandle(ref, () => ({
		showModal(isShown, data) {
			setIsOpen(isShown);
			setModalData({ ...modalData, ...data });
		}
	}));

	const rowsData = useMemo(
		() =>
			(modalData?.rows || []).map((item, index) => (
				<span style={{ display: "block" }} key={`${index}-row`}>
					{item?.ManufacturerPartNumber}
				</span>
			)),
		[modalData?.rows]
	);
	return (
		<Dialog
			open={isOpen}
			onClose={() => setIsOpen(false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			id="CKEditor_deleteDialog"
		>
			<DialogTitle sx={{ m: 0, p: 2 }} id="alert-dialog-title">
				{modalData?.titleText}
				{!modalData?.lastItem && (
					<IconButton
						aria-label="close"
						onClick={() => setIsOpen(false)}
						sx={{
							position: "absolute",
							right: 8,
							top: 8,
							minWidth: "auto",
							color: (theme) => theme.palette.grey[500]
						}}
					>
						<CloseIcon />
					</IconButton>
				)}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{modalData?.confirmationText}
				</DialogContentText>
				<DialogContentText id="alert-dialog-data">{rowsData}</DialogContentText>
			</DialogContent>
			{modalData?.lastItem ? (
				<DialogActions sx={{ justifyContent: "center" }}>
					<Button onClick={() => setIsOpen(false)} id="secondaryButtonId">
						OK
					</Button>
				</DialogActions>
			) : (
				<DialogActions>
					<Button
						onClick={() => setIsOpen(false)}
						id="secondaryButtonId"
						sx={{
							...(darkMode
								? { color: "var(--dark-mode-blue-color) !important" }
								: { color: "#2E3F80 !important" })
						}}
					>
						Cancel
					</Button>
					<Button
						variant="contained"
						onClick={modalData.deleteCallback}
						id="primaryButtonId"
						autoFocus
						sx={{
							...(darkMode
								? { backgroundColor: "var(--dark-mode-blue-color) !important" }
								: { backgroundColor: "#2E3F80 !important" })
						}}
					>
						Delete
					</Button>
				</DialogActions>
			)}
		</Dialog>
	);
});

export default DeleteModalComponent;

import { MenuItem, Select, FormControl, FormHelperText } from '@mui/material';
import { CommonInputLabelStyled } from '../styledComponents/commonStyled';
import { ExpandMore } from '@mui/icons-material';
import { Component } from 'react';

export class CommonSelect extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { id, label, handleChange, options, value, name, disabled, required, defaultValue, error, helperText, ...otherProps } = this.props;

        return (
            <FormControl fullWidth size="small" error={error}>
                <CommonInputLabelStyled disabled={disabled} shrink={true} required={required} id={id + "-label"}>{label}</CommonInputLabelStyled>
                <Select
                    labelId={id + "-label"}
                    id={id}
                    value={value}
                    label={label}
                    name={name}
                    onChange={handleChange}
                    fullWidth
                    displayEmpty
                    notched={true}
                    disabled={disabled}
                    IconComponent={ExpandMore}
                    {...otherProps }
                >
                    {!!defaultValue &&
                        <MenuItem disabled value="">
                            {defaultValue}
                        </MenuItem>
                    }
                    {
                        options.map((option, index) => <MenuItem key={option.label + '-' + index} value={option.value} sx={{
                            display: option.label === '' && 'none',
                        }}>{option.label}</MenuItem>)
                    }
                </Select>
                {!!helperText && < FormHelperText> { helperText }</FormHelperText>}
            </FormControl>
        )
    }
}
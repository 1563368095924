import { MenuItem } from "@mui/material";
import { EditSelectStyled } from "../../styledComponents/mapTableStyled";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import { commomSelectStyles, handleDisableDuplicateDQCondition } from "./common";

export const EditInputValueCell = ({
	id,
	value,
	field,
	formatedDQuestionList,
	setIsConditionRepeated,
	currentQuestion,
    row,
	handleIsConditionRepeated
}) => {
	const apiRef = useGridApiContext();

	const handleChange = async (event) => {
		if (formatedDQuestionList?.length > 0) {
			const isDuplicate = formatedDQuestionList
				.filter((que) => que.dynamicQuestionId != id)
				.some(
					(condition) =>
						condition.answer === event.target.value &&
						condition.thenDisplay.questionId === row.thenDisplay.questionId &&
                        condition.operation === row.operation                      
				);
                handleDisableDuplicateDQCondition(isDuplicate, setIsConditionRepeated);
				handleIsConditionRepeated(isDuplicate)
		}

		await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
		apiRef.current.stopCellEditMode({ id, field });
	};

	return (
		<EditSelectStyled
			value={value}
			onChange={handleChange}
			size="small"
			sx={{ ...commomSelectStyles }}
			MenuProps={{ disableScrollLock: true }}
			data-testid="select-input-value"
		>
			{currentQuestion?.answers?.map((answer) => (
				<MenuItem
					key={`option-${answer?.answerId}-${answer?.sortOrder}`}
					value={answer.answer}
				>
					{answer.answer}
				</MenuItem>
			))}
		</EditSelectStyled>
	);
};

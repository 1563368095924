import React from "react";

import { Box, Grid } from "@mui/material";
import EditorWrapper from "../EditorWrapper";
import WidgetToolbar from "../WidgetToolbar";
import useEditorToToolbarWrapperHelper from "./editorToToolbarWrapperHelpers";

// eslint-disable-next-line import/prefer-default-export
export function EditorToToolbarWrapper({
	panelClasses,
	expandContent,
	rightContentExpanded,
	RIGHT_HEADER
}) {
	const { showInitialState, handleInitialState } = useEditorToToolbarWrapperHelper();

	return (
		<Box sx={{ display: "flex", width: "100%" }}>
			<Grid
				item
				sx={{
					width: "calc(100% - 45px)",
					display: "inline-block",
					marginRight: "14px"
				}}
			>
				<div className={panelClasses}>
					<h2>{RIGHT_HEADER}</h2>
				</div>
				<div style={{ marginTop: "15px" }} onClick={() => handleInitialState(false)}>
					<EditorWrapper
						expanded={rightContentExpanded}
						showInitialState={showInitialState}
						handleInitialState={handleInitialState}
					/>
				</div>
			</Grid>
			<Grid item sx={{ width: "45px", display: "inline-block" }}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						height: "100%"
					}}
				>
					<div className={panelClasses} style={{ marginBottom: "18px" }}>
						<div
							className={rightContentExpanded ? "collapseIcon" : "expandIcon"}
							style={{ marginLeft: "23px" }}
							onClick={expandContent}
						/>
					</div>
					<WidgetToolbar handleInitialState={handleInitialState} />
				</Box>
			</Grid>
		</Box>
	);
}

import React, { useCallback, useState } from "react";
import { Button, Menu, MenuItem, Stack, Typography } from "@mui/material";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import { useTheme } from "@mui/material/styles";
import {
	OpenInNew,
	ArrowCircleDown,
	UnpublishedOutlined,
	PictureAsPdfOutlined
} from "@mui/icons-material";
import { useQuoteContext, useUserContext, useUserSettingsContext } from "../context";
import { useDocumentPreview } from "../customHooks";

export const QuoteHeaderMenu = ({
	displayEditCustomerDetails,
	hidePdfViewButton,
	hideOpportunityDetails
}) => {
	const theme = useTheme();
	let opportunityButtonProps = null;
	const quote = useQuoteContext();
	const { IsReadOnly } = useUserContext();
	const { IsLocked } = useQuoteContext();
	const { toggleDocumentPreviewer, documentPreviewEnabled } = useDocumentPreview();
	const userSettings = useUserSettingsContext();
	const [allowOpportunity, setAllowOpportunity] = useState(
		quosal.util.crmAllowsOpportunityCheck()
	);
	const [anchorEl, setAnchorEl] = useState(null);
	if (allowOpportunity) {
		opportunityButtonProps = {};
		if (quote.IdCRMOpportunity) {
			opportunityButtonProps.className = "quote-opportunity-active";
			opportunityButtonProps.title = quote.IdCRMOpportunity + " is Attached";
		} else {
			opportunityButtonProps.className = "quote-opportunity-inactive";
			opportunityButtonProps.title = "No Opportunity Attached";
		}
	}

	const getPublishText = useCallback(
		(quoteItem) => {
			if (quoteItem.PublishMethod && quoteItem.PublishMethod.indexOf("DOCUSIGN") > -1) {
				return "Email DocuSign";
			} else if (quoteItem.PublishMethod === "DOCX") {
				return "Email DOCX";
			} else if (quoteItem.PublishMethod === "DOC") {
				return "Email DOC";
			} else if (quoteItem.PublishMethod === "RTF") {
				return "Email RTF";
			} else if (quoteItem.PublishMethod === "XLS") {
				return "Email XLS";
			} else if (quoteItem.PublishMethod === "PDF") {
				return "Email PDF";
			} else {
				return quoteItem.IsOrderPorterUploaded ? "View Order Porter" : "Not Published";
			}
		},
		[quote]
	);

	const isPublishItemClickable = useCallback(
		(quoteItem) => {
			if(quosal.sell.modules.checkIfLocked("quote.publish", quoteItem) && !app.currentQuote.IsOrderPorterUploaded) {
				return false;
			}
			
			if (
				(quoteItem.PublishMethod && quoteItem.PublishMethod.indexOf("DOCUSIGN") > -1) ||
				quoteItem.PublishMethod === "DOCX" ||
				quoteItem.PublishMethod === "DOC" ||
				quoteItem.PublishMethod === "RTF" ||
				quoteItem.PublishMethod === "XLS" ||
				quoteItem.PublishMethod === "PDF"
			) {
				return false;
			} else {
				return true;
			}
		},
		[quote]
	);

	const showOpportunityStatus = allowOpportunity && !quote.IsTemplate;
	const menus = [
		{
			id: "EDIT_CUSTOMER_DETAILS",
			name: "Edit Customer Details",
			icon: <OpenInNew />,
			isClickable: true,
			visible: !IsLocked && !IsReadOnly,
			onClick: displayEditCustomerDetails
		},
		{
			id: "OPPORTUNITY_DETAILS",
			name: "Opportunity Details",
			title: opportunityButtonProps?.title,
			icon: <ArrowCircleDown />,
			isClickable: true,
			visible: !IsReadOnly && !hideOpportunityDetails && showOpportunityStatus,
			onClick: () => {
				goToOpportunity(quote);
			}
		},
		{
			id: "NOT_PUBLISHED",
			name: getPublishText(quote),
			icon: <UnpublishedOutlined />,
			isClickable: isPublishItemClickable(quote),
			visible: true,
			onClick: () => {
				isPublishItemClickable(quote) ? publishQuote(quote) : null;
			},
			testId: "publish-quote"
		},
		{
			id: "Show_PDF",
			name: documentPreviewEnabled ? "Hide PDF" : "Show PDF",
			icon: <PictureAsPdfOutlined />,
			isClickable: true,
			visible: !hidePdfViewButton && quosal.util.shouldShowPdfPreviewOption(),
			onClick: toggleDocumentPreviewer,
			testId: "toggle-pdf"
		}
	];
	const menuBtnStyles = {
		width: "100px",
		height: "32px",
		borderRadius: "6px",
		padding: "3px",
		borderStyle: "solid",
		borderColor: theme.palette.primary.main,
		fontWeight: 600,
		lineHeight: "18px",
		"& .MuiButton-endIcon>*:nth-of-type(1)": {
			fontSize: "16px"
		}
	};
	const menuItemStyles = {
		fontWeight: "400",
		lineHeight: "16px",
		fontSize: "12px",
		height: "16px",
		letterSpacing: "0.32px",
		color: theme.palette.text.secondary
	};

	const publishQuote = (quoteData) => {
		window.open(
			`${quoteData.OrderPorterLink}&novisit=true`,
			quoteData.IsOrderPorterUploaded ? "_blank" : "_self"
		);
	};

	const goToOpportunity = useCallback(
		(quoteData) => {
			if (!quoteData || !quoteData.QuoteName) {
				quoteData = app.currentQuote;
			}
			if (quoteData.IsTemplate) {
				return;
			}
			let nextModule;
			let disallowCustomerAndOppSearch = quosal.settings.getValue("disallowCustomerSearch");
			let hasCustomer = quoteData.AccountName != "";
			if (!quoteData.IdCRMOpportunity && !hasCustomer && !disallowCustomerAndOppSearch) {
				nextModule = "quote.attachOpportunity";
			} else {
				nextModule = "crm.opportunity";
			}

			if (app.currentModule.Url === "quote.dashboard") {
				app.currentModule.loadSubModule("quote.customer", {
					container: "quoteModule",
					unloadSubModules: true,
					query: "nextModule=" + nextModule
				});
			} else {
				const href = quosal.util.url(
					"quote.dashboard",
					`submodules=quote.customer` + `,${nextModule}`,
					"idquotemain=" + quoteData.IdQuoteMain
				);
				window.open(href, "_self");
			}
		},
		[quote]
	);

	const handleMenuClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	return (
		<Stack
			direction="row"
			spacing={1}
			sx={{
				paddingLeft: "0px",
			}}
		>
			<Button
				aria-controls="quote-header-menu"
				aria-haspopup="true"
				variant="text"
				onClick={handleMenuClick}
				sx={menuBtnStyles}
				endIcon={
					<ExpandMoreSharpIcon
						sx={{
							fontSize: "16px",
							borderColor: theme.palette.primary.main
						}}
					/>
				}
			>
				<Typography
					sx={{
						fontWeight: 600,
						lineHeight: "18px",
						fontSize: "14px"
					}}
				>
					Action
				</Typography>
			</Button>
			<Menu
				id="quote-header-menu"
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right"
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right"
				}}
				keepMounted
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={() => setAnchorEl(null)}
				disableScrollLock={true}
			>
				{menus.map(({ id, name, onClick, testId, visible, title, isClickable }) =>
					visible ? (
						<MenuItem
							onClick={() => {
								setAnchorEl(null);
								onClick();
							}}
							sx={!isClickable ? { cursor: "not-allowed" } : {}}
							key={id}
							data-testid={testId}
						>
							<Typography title={title} sx={menuItemStyles}>
								{name}
							</Typography>
						</MenuItem>
					) : null
				)}
			</Menu>
		</Stack>
	);
};

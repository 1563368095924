import React, { memo } from "react";
import { List } from "@mui/material";
import { TermOptionsTab } from "js/jsx/src/classes/tab/termOptionsTab";
import DataGridBoilerplate from "../DataGridBoilerplate";
import DraggableExpandable from "../DraggableExpandable/DraggableExpandableComponent";
import DeleteModalComponent from "../modals/DeleteModal";
import useTabsWrapperContainer from "./tabsWrapperContainer";
import MarginModalComponent from "../modals/MarginModal/MarginModalComponent";
import NewRecordModalComponent from "../modals/NewRecordModal";
import PriceSourceModalComponent from "../modals/PriceSourceModal";

function TabsWrapperComponent({ expanded }) {
	const {
		contentGrid,
		duplicated,
		isEditable,
		loading,
		open,
		change,
		data,
		deleteModalRef,
		marginModalRef,
		newRecordRef,
		priceSourceModalRef,
		setData,
		updateOpenTab,
		setChange,
		setDuplicated,
		setLoading,
		handleDeleteTab,
		handleDeleteGridRow,
		handleMove,
		openCustomizeGrid
	} = useTabsWrapperContainer();

	return (
		<div>
			<List>
				{data.map((item) => {
					return (item?.IsHidden && !quosal.util.userIsAdminOrMaintainer()) ? null : (
						
					<DraggableExpandable
						key={`${item.IdQuoteTabs}-key`}
						id={item.IdQuoteTabs}
						tabItem={item}
						open={open}
						duplicated={duplicated}
						openCustomizeGrid={openCustomizeGrid}
						onRemove={handleDeleteTab}
						onMove={handleMove}
						updateOpenTab={updateOpenTab}
						setData={setData}
						setChange={() => setChange(!change)}
						setDuplicated={setDuplicated}
						expanded={expanded}
						setLoading={setLoading}
						content={
							item.TabName !== "Term Options" ? (
								<DataGridBoilerplate
									tabItem={item}
									loading={loading}
									openCustomizeGrid={openCustomizeGrid}
									expanded={expanded}
									setChange={() => setChange(!change)}
									setLoading={setLoading}
									onRemove={handleDeleteGridRow}
									marginModalRef={marginModalRef}
									newRecordRef={newRecordRef}
									priceSourceModalRef={priceSourceModalRef}
								/>
							) : (
								<TermOptionsTab
									contentGrid={contentGrid}
									editable={
										isEditable &&
										(!item.IsProtectedTab ||
											contentGrid.state.userCanModifyProtectedTab)
									}
									tab={item}
								/>
							)
						}
					/>
				)}
			)}
				
				{[
					<DraggableExpandable
						id=""
						key="add-new-section"
						updateOpenTab={updateOpenTab}
						setChange={() => setChange(!change)}
						setData={setData}
					/>
				]}
			</List>
			<DeleteModalComponent ref={deleteModalRef} />
			<MarginModalComponent ref={marginModalRef} />
			<NewRecordModalComponent ref={newRecordRef} />
			<PriceSourceModalComponent ref={priceSourceModalRef} />
		</div>
	);
}
export default memo(TabsWrapperComponent);

import { MenuItem } from "@mui/material";
import { EditSelectStyled } from "../../styledComponents/mapTableStyled";
import {
	commomSelectStyles,
	handleDisableDuplicateDQCondition,
	thenDisplayQuestionFormatter
} from "./common";
import { useGridApiContext } from "@mui/x-data-grid-pro";

export const EditThenDisplayValueCell = ({
	id,
	value,
	field,
	inputQuestionOptions,
	formatedDQuestionList,
	setIsConditionRepeated,
	row,
	handleIsConditionRepeated
}) => {
	const apiRef = useGridApiContext();

	const handleChange = async (event) => {
		if (formatedDQuestionList?.length > 0) {
			const isDuplicate = formatedDQuestionList
				.filter((que) => que.dynamicQuestionId != id)
				.some(
					(condition) =>
						condition.thenDisplay.questionId === event.target.value.questionId &&
						condition.answer === row.answer &&
						condition.operation === row.operation
				);
			handleDisableDuplicateDQCondition(isDuplicate, setIsConditionRepeated);
			handleIsConditionRepeated(isDuplicate);
		}

		await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
		apiRef.current.stopCellEditMode({ id, field });
	};

	return (
		<EditSelectStyled
			value={value}
			onChange={handleChange}
			size="small"
			id={"select-operation"}
			sx={{ ...commomSelectStyles, width: "100%" }}
			renderValue={(value) => thenDisplayQuestionFormatter(value.sortOrder, value.question)}
			MenuProps={{ disableScrollLock: true }}
			data-testid="select-question"
		>
			{inputQuestionOptions?.map((question, index) => (
				<MenuItem
					key={`option-${question?.value?.questionId}-${index}`}
					value={{
						questionId: question.value.questionId,
						question: question.value.question,
						sortOrder: question.value.sortOrder
					}}
				>
					{thenDisplayQuestionFormatter(
						question?.value?.sortOrder,
						question?.value?.question
					)}
				</MenuItem>
			))}
		</EditSelectStyled>
	);
};

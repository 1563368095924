import { Box, Stack } from "@mui/material";
import { CardContainer } from "../../common/components/CardContainer";
import { CustomerDetailItem } from "./CustomerDetailItem";
import { useQuoteContext } from "../../context";

export const CustomerDetails = ({ isCollapsed }) => {
	const { MainCust } = useQuoteContext();

	return (
		<CardContainer
			sx={{ display: "flex", flex: "1", minHeight: isCollapsed ? "54px" : "128px", height: "100%", minWidth: "356px" }}
			styleOutlineOverrides={{ display: "flex", flex: "1", padding: "8px 16px" }}
			data-testid="test-customer-details"
		>
			<Stack
				direction="column"
				sx={{ justifyContent: "center", padding: "1px", gap: "4px", width: "100%" }}
			>
				<Stack
					direction="row"
					sx={{ height: isCollapsed ? "36px" : "50px", alignItems: "center", padding: "1px" }}
				>
					<Box sx={{ marginRight: "5px", height: "38px", gap: "2px", minWidth: "100px", flex: 1, overflow: "hidden" }}>
						<CustomerDetailItem testId={'test-customer-name'} title="Customer" value={MainCust.AccountName} />
					</Box>
					<Box sx={{ height: "38px", gap: "2px", minWidth: "100px", flex: 1, overflow: "hidden" }}>
						<CustomerDetailItem testId={'test-customer-email'} title="Email" value={MainCust.EmailAddress} />
					</Box>
				</Stack>
				{(!isCollapsed && (
					<Stack direction="row" sx={{ height: "50px", padding: "1px" }}>
						<Box sx={{ flex: 1, overflow: "hidden", height: "38px", gap: "2px", minWidth: "100px" }}>
							<CustomerDetailItem
								testId={'test-customer-contact'}
								title="Contact"
								value={`${MainCust.FirstName}  ${MainCust.LastName}`}
							/>
						</Box>
						<Box sx={{ height: "38px", gap: "2px", minWidth: "100px", flex: 1, overflow: "hidden" }}>
							<CustomerDetailItem testId={'test-customer-phone'} title="Phone" value={MainCust.DayPhone} />
						</Box>
					</Stack>
				)) ||
					null}
			</Stack>
		</CardContainer>
	);
};
